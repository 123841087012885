import React, { useState, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './drop.css';
import { useTranslation } from 'react-i18next';
import { FileUpload } from './FileUpload';
import { baseStyle, focusedStyle, acceptStyle, rejectStyle } from './baseStyle';

export const useStyles = makeStyles((theme: any) => ({
  colorPrimary: {
    backgroundColor: 'transparent',
  },
  barColorPrimary: {
    background: `linear-gradient(45deg, ${theme.palette.primary.main} 20%, ${theme.palette.secondary.main} 90%)`,
  },
}));

export const VisiterDropzone = ({
  customerId,
  visitId,
  promotionId,
  createDocument,
  fileFinished,
}: {
  customerId?: number;
  visitId?: number;
  promotionId?: number;
  createDocument?: boolean;
  fileFinished?: () => void;
}) => {
  const { t } = useTranslation();
  const [activeUploads, setActiveUploads] = useState<any>([]);
  // console.log(promotionId);
  const onDrop = (acceptedFiles: any) => {
    const maxid = (_.maxBy<any>(activeUploads as any, (d) => d.id) || {}).id || 0;
    setActiveUploads([
      ...activeUploads,
      ...acceptedFiles.map((file: any, i: number) => ({
        file,
        finished: false,
        id: maxid + i + 1,
      })),
    ]);
  };

  const accept = promotionId || createDocument ? 'image/*,image/jpeg,application/pdf' : 'image/*';

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const showUploads = activeUploads.filter((d: any) => !d.finished).length > 0;

  // console.log('vars', isFocused, isDragAccept, isDragReject);

  let url = `/api/upload/${customerId}/${visitId}`;
  if (createDocument) {
    url = `/api/upload-document`;
  }
  if (promotionId) {
    url = `/api/upload-promotion/${customerId}/${promotionId}`;
  }

  return (
    <React.Fragment>
      <div style={{ margin: '0.5em 0' }}>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <Typography>{t('Drag n` Drop files here, or click to select a File.')}</Typography>
        </div>
      </div>

      <CSSTransition in={showUploads} timeout={500} classNames="my-node" unmountOnExit>
        <Typography variant="h5">{t('Upload Progress')}</Typography>
      </CSSTransition>

      {activeUploads.filter((d: any) => !d.finished).length > 0 && null}
      <TransitionGroup>
        {activeUploads
          .filter((d: any) => !d.finished)
          .map((f: any, i: number) => (
            <CSSTransition key={f.id} in={true} timeout={500} classNames="my-node" unmountOnExit>
              <FileUpload
                id={f.id}
                file={f.file}
                customerId={customerId}
                visitId={visitId}
                fileFinished={fileFinished}
                url={url}
                hide={() =>
                  setActiveUploads(
                    activeUploads.map((d: any) => {
                      if (d.id === f.id) {
                        d.finished = true;
                      }
                      return d;
                    })
                  )
                }
              />
            </CSSTransition>
          ))}
      </TransitionGroup>
    </React.Fragment>
  );
};
