import { GetCustomerQuery } from 'generated/graphql';
import { LatLng } from 'types/LatLng';

export const getCoordinatesFromAddress = async (
  obj: NonNullable<GetCustomerQuery['customers_by_pk']>
): Promise<LatLng | undefined> => {
  const addressString = `${obj.road} ${obj.number}, ${obj.postcode} ${obj.city}`;

  console.log(addressString);
  const response = await fetch(
    `https://nominatim.openstreetmap.org/search?q=${addressString}&format=json&limit=1&addressdetails=1`
  );
  const locations = await response.json();

  // console.log('result for search', locations);
  if (locations && locations.length > 0) {
    const { lat: latitude, lon: longitude } = locations[0];

    const res: LatLng = {
      latitude: Number(latitude),
      longitude: Number(longitude),
    };
    return res;
  }
  return undefined;
};
