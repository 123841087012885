import { GetImportDataQuery } from 'generated/graphql';

import { ArrayElement } from 'utils/ArrayElement';

export enum MappingType {
  Simple = 'simple',
  Foreign = 'foreign',
}
export type ImportCustomer = {
  _original: any;

  id?: string;
  name?: string;
  email?: string;
  telephone?: string;

  road?: string;
  number?: string;
  postcode?: string;
  city?: string;

  group?: ArrayElement<GetImportDataQuery['customer_groups']>;
  tags?: GetImportDataQuery['tags'];
  user?: ArrayElement<GetImportDataQuery['users']>;
};

export type Mapping = {
  type: MappingType;
  target: string | Target;
  source: string | undefined;
  data?: DataMapping[];
};

export type Stats = {
  count: number;
  duplicates?: { duplicate: any; original: any }[]; // the actual objects (duplicate holds the current record, original references the first found record)
  idMatching?: IdMatching[];
};

export type IdMatching = {
  dbRecord: any;
  newRecord: any;
};

export enum Target {
  group = 'group',
  user = 'user',
  tags = 'tags',
  supplier = 'supplier',
}

export type DataMapping = {
  originalText: string | undefined;
  mappedData:
    | ArrayElement<GetImportDataQuery['customer_groups']>
    | ArrayElement<GetImportDataQuery['tags']>
    | ArrayElement<GetImportDataQuery['users']>
    | ArrayElement<GetImportDataQuery['suppliers']>
    | null;
  count: number;
};
