// import { createMuiTheme } from '@mui/material';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@mui/material';

export const theme = createMuiTheme({
  typography: {
    fontFamily: ['Work Sans', 'Roboto'].join(','),
  },
  palette: {
    primary: { main: '#368cbf' },
    secondary: { main: '#7ebc59' },
  },
});
