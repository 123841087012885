import React from 'react';
import {
  Dialog,
  DialogTitle,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  SvgIcon,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addressObjectToString } from '../../utils/addressObjectToString';
import { shapeAddressObject } from '../../utils/shapeAddressObject';
import { AddressWithPos } from '../../types/AddressWithPos';

export const SearchAddressDialog = ({
  isOpen,
  close,
  onSelectAddress,
}: {
  isOpen: boolean;
  close: () => void;
  onSelectAddress: (AddressWithPos: AddressWithPos) => void;
}) => {
  const [searchText, setSearchText] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [matches, setMatches] = useState<(AddressWithPos & { icon: string })[]>([]);
  const { t } = useTranslation();

  const search = async () => {
    setIsLoading(true);

    // console.log(searchText);
    const response = await fetch(
      `https://nominatim.openstreetmap.org/search?q=${searchText}&format=json&limit=10&addressdetails=1`
    );
    const locations = await response.json();

    const locs: (AddressWithPos & { icon: string })[] = locations.map((l: any) => ({
      icon: l.icon,
      longitude: Number(l.lon),
      latitude: Number(l.lat),
      ...shapeAddressObject(l.address),
    }));

    setMatches(locs);

    // console.log('locations found:', locations);
    setIsLoading(false);
  };
  // const attemptStartSeach = useRef(_.debounce(async (searchText) => {}, 1000)).current;
  return (
    <Dialog open={isOpen} onClose={close}>
      <DialogTitle>{t('Search for address')}</DialogTitle>
      <form style={{ margin: '13px', display: 'inline-flex' }}>
        <TextField
          fullWidth
          value={searchText}
          label={t('Search')}
          onChange={(e) => {
            setIsLoading(false);
            setSearchText(e.target.value);
          }}
        />
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            search();
          }}
          type="submit"
        >
          <SvgIcon>
            <FontAwesomeIcon icon={faSearch} />
          </SvgIcon>
        </IconButton>
      </form>

      {isLoading && <LinearProgress />}

      <List>
        {matches &&
          matches.map((location, i) => (
            <LocationItem
              onClick={() => {
                const { icon, ...rest } = location;
                onSelectAddress(rest);
              }}
              key={i}
              location={location}
            />
          ))}
      </List>
    </Dialog>
  );
};
const LocationItem = ({
  location,
  onClick,
}: {
  location: AddressWithPos & { icon: string };
  onClick: (l: AddressWithPos) => void;
}) => (
  <ListItem
    button
    onClick={() => {
      onClick && onClick(location);
    }}
  >
    <ListItemIcon>
      <img src={location.icon} alt=""/>
    </ListItemIcon>
    {addressObjectToString(location)}
  </ListItem>
);
