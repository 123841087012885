import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import React, { useState } from 'react';
import { orderBy } from 'lodash';
import { faCheckCircle, faCircleQuestion, faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { VIcon } from 'components/VIcon';
import { BarChart, Bar, Cell, XAxis, YAxis, Tooltip as ReTooltip, ResponsiveContainer } from 'recharts';
import { DataMapping, Mapping, Target } from './MappingTypes';
import InputDialog from 'components/dialog/InputDialog';
import { AddForeignDataMutationVariables, GetImportDataDocument, useAddForeignDataMutation } from 'generated/graphql';

export const DataMappingDisplay = ({
  mapping,
  changeEntries,
}: {
  mapping: Mapping;
  changeEntries: (oldVal: string | number | null | undefined, newVal: string | number | null) => void;
}) => {
  const d = orderBy(mapping.data, (d) => d.count, 'desc');

  return (
    <div
      style={{
        width: '100%',
        padding: '1em',
        marginBottom: '1em',
        border: '1px solid lightgray',
        borderRadius: '0.2em',
      }}
    >
      <div style={{ fontSize: '1.8em' }}>{`Target: ${mapping.target}`}</div>
      <ResponsiveContainer width="100%" height={40 * (d.length || 1) + 40}>
        <BarChart data={d} layout="vertical">
          <ReTooltip />
          <Bar dataKey="count" fill="#8884d8">
            {d.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.mappedData ? '#7ebc59' : '#368cbf'} />
            ))}
          </Bar>
          <XAxis type="number" />
          <YAxis type="category" dataKey="originalText" width={200} />
        </BarChart>
      </ResponsiveContainer>

      <TableContainer component={Paper}>
        <Table size="small" style={{ padding: '2em' }}>
          <TableHead>
            <TableRow>
              <TableCell>Original</TableCell>
              <TableCell>{}</TableCell>
              <TableCell>Match</TableCell>
              {/* <TableCell>Actions</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {mapping?.data?.map((d: DataMapping, i) => (
              <SingleMappingDisplay key={i} mapping={mapping} dataMapping={d} changeEntries={changeEntries} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const SingleMappingDisplay = ({
  mapping,
  dataMapping,
  changeEntries,
}: {
  mapping: Mapping;
  dataMapping: DataMapping;
  changeEntries: (oldVal: string | number | null | undefined, newVal: string | number | null) => void;
}) => {
  const [showRename, setShowRename] = useState(false);

  const [createData] = useAddForeignDataMutation({
    refetchQueries: [{ query: GetImportDataDocument }],
  });
  return (
    <TableRow key={dataMapping.originalText}>
      {showRename && (
        <InputDialog
          title={`Renaming ${dataMapping.originalText}`}
          text=""
          isOpen={showRename}
          yesAction={(value) => {
            changeEntries(dataMapping?.originalText, value);
            setShowRename(false);
          }}
          noAction={() => setShowRename(false)}
        />
      )}
      <TableCell>{dataMapping.originalText}</TableCell>
      <TableCell>
        {dataMapping.mappedData && (
          <VIcon icon={dataMapping.originalText ? faCheckCircle : faTimesCircle} color={'#7ebc59'} />
        )}
        {!dataMapping.mappedData && (
          <div style={{ display: 'flex' }}>
            <Tooltip title="Creates this value in the database">
              <IconButton
                size="small"
                color="primary"
                onClick={async () => {
                  let pl: AddForeignDataMutationVariables = { CustomerGroups: [], Users: [], Tags: [], Customers: [] };

                  switch (mapping.target) {
                    case Target.group:
                      pl.CustomerGroups = [{ name: dataMapping.originalText }];
                      break;
                    case Target.user:
                      // create admin user
                      pl.Users = [{ login: dataMapping.originalText, role_id: 1 }];
                      break;
                    case Target.tags:
                      pl.Tags = [{ name: dataMapping.originalText }];
                      break;
                    case Target.supplier:
                      pl.Customers = [{ name: dataMapping.originalText, user_id: null }];
                      break;
                  }

                  await createData({ variables: pl });
                }}
              >
                <VIcon icon={faPlusCircle} />
              </IconButton>
            </Tooltip>
            {/* Dont allow empty mappings on user */}
            {Boolean(mapping.target !== Target.user) && (
              <IconButton
                size="small"
                color="error"
                onClick={() => changeEntries(dataMapping.originalText || null, null)}
              >
                <VIcon icon={faTimesCircle} />
              </IconButton>
            )}
            <IconButton size="small" color="warning" onClick={() => setShowRename(true)}>
              <VIcon icon={faCircleQuestion} />
            </IconButton>
          </div>
        )}
      </TableCell>
      <TableCell>{dataMapping.mappedData && JSON.stringify(dataMapping.mappedData)}</TableCell>
    </TableRow>
  );
};
