import { Dialog, DialogContent, DialogTitle, List, ListItem, ListItemText, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { GetProductsQuery } from 'generated/graphql';
import { ArrayElement } from 'utils/ArrayElement';

export const AddSupplierDialog = ({
  suppliers,
  alreadyListed,
  open,
  onClose,
  onSelect,
}: {
  suppliers: GetProductsQuery['suppliers']; // GetProducts_suppliers[];
  alreadyListed: number[];
  open: boolean;
  onClose: () => void;
  onSelect: (id: number) => void;
}) => {
  const [search, setSearch] = useState('');
  const { t } = useTranslation();

  const f = (d: ArrayElement<GetProductsQuery['suppliers']>) =>
    new RegExp(search, 'i').test((d || {}).address || '') || new RegExp(search, 'i').test((d || {}).name || '');

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('Add Supplier')}</DialogTitle>
      <DialogContent>
        <div style={{ overflow: 'hidden' }}>
          <TextField
            fullWidth
            style={{ marginTop: '0.3em' }}
            label={t('Search')}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div style={{ overflowY: 'scroll' }}>
            <List style={{ width: '300px', height: '400px' }}>
              {suppliers
                .filter((d) => !_.includes(alreadyListed, d.id))
                .filter(f)
                .map((ps) => (
                  <ListItem key={ps.id} button onClick={() => onSelect(ps.id)}>
                    <ListItemText primary={ps.name} secondary={ps.address} />
                  </ListItem>
                ))}
            </List>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
