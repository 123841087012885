import { FormControl, InputLabel } from '@mui/material';
import Select, { ActionMeta } from 'react-select';
import _ from 'lodash';
import { ReactSelectStyles } from '../../utils/ReactSelectStyles';
import CreatableSelect from 'react-select/creatable';
import YesNoDialog from 'components/dialog/YesNoDialog';
import { useState } from 'react';

export const FormikMultiSelect = ({
  formik,
  label,
  name,
  options,
  creatable,
  createOption,
  display,
  filterOptions,
  confirmTitle,
  confirmText,
}: {
  formik: any;
  label: string;
  name: string;
  options: any[];
  creatable?: boolean;
  createOption?: (name: string) => {};
  display?: (o: any) => any;
  filterOptions?: (candidate: { label: string; value: string; data: any }, input: string) => boolean;
  confirmTitle?: string;
  confirmText?: string;
}) => {
  const vals = formik.values[name];
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [action, setAction] = useState<any>(null);
  let _disp = (o: any) => o.name;
  if (display) {
    _disp = display;
  }

  let _filterOptions = undefined;
  if (filterOptions) {
    _filterOptions = filterOptions;
  }

  let _confirmDelete = true;
  let _options = options.map((o: any, i: number) => ({ value: o.id, label: _disp(o), ...o }));
  const selectValues = _options.filter((d) => _.includes(vals, d.value));

  // confirm handling in here
  const onChange = (value: any, action: ActionMeta<any>): void => {
    // console.log('change', value, action);
    if (
      _confirmDelete &&
      (action.action === 'remove-value' || action.action === 'clear' || action.action === 'pop-value')
    ) {
      // we want to save the callback - so we need the nesting here
      setAction(() => () => formik.setValues({ ...formik.values, [name]: value.map((d: any) => d.value) }));
      setConfirmOpen(true);
    } else {
      formik.setValues({ ...formik.values, [name]: value.map((d: any) => d.value) });
    }
  };
  return (
    <FormControl fullWidth style={{ marginTop: '10px' }}>
      <YesNoDialog
        title={confirmTitle || ''}
        text={confirmText || ''}
        isOpen={confirmOpen}
        yesAction={() => {
          action && action();
          setConfirmOpen(false);
        }}
        noAction={() => setConfirmOpen(false)}
      />
      <InputLabel style={{ position: 'static', transform: 'none' }}>{label}</InputLabel>
      {creatable ? (
        <CreatableSelect
          id={name}
          options={_options}
          isMulti
          styles={ReactSelectStyles}
          value={selectValues}
          onChange={onChange}
          onCreateOption={createOption}
          filterOption={_filterOptions}
        />
      ) : (
        <Select
          id={name}
          options={_options}
          isMulti
          styles={ReactSelectStyles}
          value={selectValues}
          onChange={onChange}
          filterOption={_filterOptions}
        />
      )}
    </FormControl>
  );
};
