import {
  ThemeProvider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from '@mui/material';
import React, { useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { useCustomerSelection } from 'context/CustomerSelectionContext';
import { useCustomerFilter } from 'context/CustomerFilterContext';
import { useMessages } from 'context/MessageContext';
import { GetCustomersDocument, useGetUsersQuery, useMoveCustomerToUserMutation } from 'generated/graphql';

const customTheme = (outerTheme: any) => ({
  ...outerTheme,
  palette: {
    ...outerTheme.palette,
    primary: { main: '#f44336' },
  },
});
type SelectItem = {
  value: number;
  label: string | null;
};

// const useStyles = makeStyles((theme) => ({
//   root: { overflow: 'visible' },
// }));

export const MoveCustomerToUserDialog = ({ closeAction, open }: { closeAction: () => void; open: boolean }) => {
  const { error, success } = useMessages();
  /**
   * Users
   */
  const { t } = useTranslation('translation');
  const { data: usersData, loading: usersLoading } = useGetUsersQuery(); // useQuery<GetUsers>(GetUsersQuery);

  const [selectedUserId, setSelectedUserId] = useState<number | undefined | null>(undefined);
  const { count, selectedCustomerIds, clear } = useCustomerSelection();

  /**
   * Customers for updates
   */
  const { getFilter } = useCustomerFilter();

  /**
   * Move Mutation
   */
  const [moveCustomersToNewOwner] = useMoveCustomerToUserMutation({
    refetchQueries: [{ query: GetCustomersDocument, variables: { customerFilter: getFilter() } }],
  });

  const syntheticEntry: SelectItem = { value: 0, label: 'PUBLIC' };

  return (
    <ThemeProvider theme={customTheme}>
      <Dialog fullScreen={false} open={open} onClose={closeAction} PaperProps={{ style: { overflowY: 'visible' } }}>
        <DialogTitle>{t('Move {{n}} Customers to new Owner', { n: count() })}</DialogTitle>
        <DialogContent style={{ overflowY: 'visible' }}>
          {t('Please select the new Owner for the Customers')}
          {usersLoading && <CircularProgress />}

          {!usersLoading && (
            <Select
              styles={customStyles}
              onChange={(v) => setSelectedUserId(v?.value)}
              value={(usersData?.users || [])
                .map((u): SelectItem => ({ value: u.id, label: u.login || null }))
                .find((d: SelectItem) => d.value === selectedUserId)}
              options={[
                syntheticEntry,
                ...(usersData?.users || []).map((u) => ({ value: u.id, label: u.login || null })),
              ]}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" autoFocus onClick={closeAction}>
            No
          </Button>
          <Button
            disabled={selectedUserId === undefined}
            variant="outlined"
            onClick={async () => {
              try {
                // console.log('asdf', selectedUserId);
                await moveCustomersToNewOwner({
                  variables: {
                    customerIds: selectedCustomerIds,
                    userId: selectedUserId || null,
                  },
                });
                closeAction();
                clear();
                success(t('Customers moved.'));
              } catch (e) {
                error(t('Moving customers failed - {{msg}}', { msg: JSON.stringify(e) }));
              }
            }}
            autoFocus
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export const customStyles = {
  control: (provided: any, state: any): any => ({
    ...provided,
    background: 'transparent',
    boxShadow: 'none',
    borderColor: 'gray',
    '&:hover': {
      borderColor: 'gray',
      boxShadow: 'none',
    },
  }),
  option: (provided: any, state: any): any => ({
    ...provided,
    background: state.isSelected ? 'gray' : 'white',
  }),
};
