import { CustomerFilterInfoQuery, Customers_Bool_Exp, useCustomerFilterInfoQuery } from 'generated/graphql';
import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrayElement } from 'utils/ArrayElement';
import { useUser } from './UserContext';

type CustomerGroupType = ArrayElement<CustomerFilterInfoQuery['customer_groups']> & {
  id: number;
  name?: string | null | undefined;
  active?: boolean;
};
type CustomerStateType = ArrayElement<CustomerFilterInfoQuery['customer_states']> & {
  id: number;
  state?: string | null | undefined;
  active?: boolean;
};
type CustomerCategoryType = ArrayElement<CustomerFilterInfoQuery['customer_categories']> & {
  id: number;
  name?: string | null | undefined;
  active?: boolean;
};

// type TagFilterType = CustomerFilterInfo_tags & {
//   active: boolean;
// };

type CustomerFilterContextType = {
  showAllCustomers: boolean;
  setShowAllCustomers?: Dispatch<SetStateAction<boolean>>;
  showPublicCustomers: boolean;
  setShowPublicCustomers?: Dispatch<SetStateAction<boolean>>;
  showCustomersWithoutMap: boolean;
  setShowCustomersWithoutMap?: Dispatch<SetStateAction<boolean>>;
  customerGroupFilters: CustomerGroupType[];
  setCustomerGroupFilters?: Dispatch<SetStateAction<CustomerGroupType[]>>;
  customerStateFilters: CustomerStateType[];
  setCustomerStateFilters?: Dispatch<SetStateAction<CustomerStateType[]>>;
  customerTagFilters: number[];
  setCustomerTagFilters?: Dispatch<SetStateAction<number[]>>;
  customerCategoryFilters: CustomerCategoryType[];
  setCustomerCategoryFilters?: Dispatch<SetStateAction<CustomerCategoryType[]>>;
  getFilter: () => Customers_Bool_Exp;
  tagOptions: CustomerFilterInfoQuery['tags'];
  setStateFilterById: (id: number, val: boolean) => void;
  setGroupFilterById: (id: number, val: boolean) => void;
  setCategoryFilterById: (id: number, val: boolean) => void;
  searchText: string;
  setSearchText?: Dispatch<SetStateAction<string>>;
};

const CustomerFilterContext = React.createContext<CustomerFilterContextType>({
  showAllCustomers: false,
  showPublicCustomers: false,
  showCustomersWithoutMap: false,
  customerGroupFilters: [],
  customerStateFilters: [],
  customerTagFilters: [],
  customerCategoryFilters: [],
  getFilter: () => ({}),
  tagOptions: [],
  setStateFilterById: (id: number, val: boolean) => {},
  setGroupFilterById: (id: number, val: boolean) => {},
  setCategoryFilterById: (id: number, val: boolean) => {},
  searchText: '',
});

export const useCustomerFilter = () => {
  return useContext(CustomerFilterContext);
};

export const CustomerFilterContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [showAllCustomers, setShowAllCustomers] = useState(false);
  const [showPublicCustomers, setShowPublicCustomers] = useState(false);
  const [showCustomersWithoutMap, setShowCustomersWithoutMap] = useState(false);
  const [customerGroupFilters, setCustomerGroupFilters] = useState<CustomerGroupType[]>([]);
  const [customerStateFilters, setCustomerStateFilters] = useState<CustomerStateType[]>([]);
  const [customerTagFilters, setCustomerTagFilters] = useState<number[]>([]);
  const [customerCategoryFilters, setCustomerCategoryFilters] = useState<CustomerCategoryType[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  const { t } = useTranslation();

  const setStateFilterById = useCallback(
    (id: number, val: boolean) => {
      setCustomerStateFilters(
        customerStateFilters.map((st) => {
          if (st.id === id) {
            return { ...st, active: val };
          }
          return st;
        })
      );
    },
    [customerStateFilters, setCustomerStateFilters]
  );

  const setGroupFilterById = useCallback(
    (id: number, val: boolean) => {
      setCustomerGroupFilters(
        customerGroupFilters.map((st) => {
          if (st.id === id) {
            return { ...st, active: val };
          }
          return st;
        })
      );
    },
    [customerGroupFilters, setCustomerGroupFilters]
  );

  const setCategoryFilterById = useCallback(
    (id: number, val: boolean) => {
      setCustomerCategoryFilters(
        customerCategoryFilters.map((cat) => {
          if (cat.id === id) {
            return { ...cat, active: val };
          }
          return cat;
        })
      );
    },
    [customerCategoryFilters, setCustomerCategoryFilters]
  );

  const { data } = useCustomerFilterInfoQuery();

  useEffect(() => {
    if (!data) {
      return;
    }
    const groups = (data.customer_groups || []).map((g) => ({
      ...g,
      active: true,
    }));
    const states = (data.customer_states || []).map((s) => ({
      ...s,
      active: true,
    }));
    const categories = (data.customer_categories || []).map((c) => ({
      ...c,
      active: true,
    }));

    setCustomerGroupFilters([{ id: 0, active: true, name: t('Empty') }, ...groups]);
    setCustomerStateFilters([{ id: 0, active: true, state: t('Empty') }, ...states]);
    setCustomerCategoryFilters([{ id: 0, active: true, name: t('Empty') }, ...categories]);
  }, [data, t]);

  const { id: currentUserId } = useUser();

  const getFilter = useCallback((): Customers_Bool_Exp => {
    // userid part
    const userIdPart: Customers_Bool_Exp = showAllCustomers
      ? {
          _or: [
            {
              user_id: {
                _is_null: false,
              },
            },
            {
              user_id: {
                _is_null: true,
              },
            },
          ],
        }
      : {
          _or: [
            {
              user_id: {
                _eq: currentUserId || 0,
              },
            },
            {
              user_id: {
                _is_null: true,
              },
            },
          ],
        };

    const publicCustomersPart: Customers_Bool_Exp = showPublicCustomers
      ? {}
      : {
          user_id: { _is_null: false },
        };

    //where: {_or: [{id: {_eq: 3}}, {id: {_eq: 5}}]})
    const customerGroupPart: Customers_Bool_Exp = {
      _or: [
        ...customerGroupFilters
          .filter((d) => d.active)
          .map((cgf) => {
            if (cgf.id === 0) {
              return {
                customer_group_id: {
                  _is_null: true,
                },
              };
            }
            return {
              customer_group_id: {
                _eq: cgf.id,
              },
            };
          }),

        // this object will provide fallback, if nothing is selected
        {
          customer_group_id: {
            _eq: 0,
          },
        },
      ],
    };

    const customerStatePart: Customers_Bool_Exp = {
      _or: [
        ...customerStateFilters
          .filter((d) => d.active)
          .map((csf) => {
            if (csf.id === 0) {
              return {
                customer_state_id: {
                  _is_null: true,
                },
              };
            }
            return {
              customer_state_id: {
                _eq: csf.id,
              },
            };
          }),
        // this object will provide fallback, if nothing is selected
        {
          customer_state_id: {
            _eq: 0,
          },
        },
      ],
    };

    const customerCategoryPart: Customers_Bool_Exp = {
      _or: [
        ...customerCategoryFilters
          .filter((d) => d.active)
          .map((ccf) => {
            if (ccf.id === 0) {
              return {
                customer_category_id: {
                  _is_null: true,
                },
              };
            }
            return {
              customer_category_id: {
                _eq: ccf.id,
              },
            };
          }),
        // this object will provide fallback, if nothing is selected
        {
          customer_category_id: {
            _eq: 0,
          },
        },
      ],
    };

    const showCustomersWithoutPart: Customers_Bool_Exp = showCustomersWithoutMap
      ? {
          latitude: { _is_null: true },
        }
      : {};

    // filter for the tags
    const customerTagPart: Customers_Bool_Exp =
      customerTagFilters.length > 0
        ? { tags: { tag: { _or: customerTagFilters.map((d) => ({ id: { _eq: Number(d) } })) } } }
        : { id: { _gt: 0 } };

    const res: Customers_Bool_Exp = {
      ...userIdPart,
      ...showCustomersWithoutPart,
      ...publicCustomersPart,
      _and: [customerGroupPart, customerStatePart, customerCategoryPart, customerTagPart],
    };

    return res;
  }, [
    showAllCustomers,
    showPublicCustomers,
    showCustomersWithoutMap,
    customerGroupFilters,
    customerStateFilters,
    customerCategoryFilters,
    customerTagFilters,
    currentUserId,
  ]);

  return (
    <CustomerFilterContext.Provider
      value={{
        showAllCustomers,
        setShowAllCustomers,

        showPublicCustomers,
        setShowPublicCustomers,

        showCustomersWithoutMap,
        setShowCustomersWithoutMap,

        customerGroupFilters,
        setCustomerGroupFilters,

        customerStateFilters,
        setCustomerStateFilters,

        customerCategoryFilters,
        setCustomerCategoryFilters,

        customerTagFilters,
        setCustomerTagFilters,
        tagOptions: data?.tags !== undefined && data?.tags !== null ? data?.tags : [],

        getFilter,

        setStateFilterById,
        setGroupFilterById,
        setCategoryFilterById,

        searchText,
        setSearchText,
      }}
    >
      {children}
    </CustomerFilterContext.Provider>
  );
};
