import fuzzysort from 'fuzzysort';
import { first, flatten, groupBy, identity } from 'lodash';
import { DataMapping, Mapping, Target } from './MappingTypes';
import { GetImportDataQuery } from 'generated/graphql';

/**
 * Enriches the mappings with additional statistical data - also checks the present
 * entities an the mapping
 * @param mapping
 * @param rawData
 * @param data
 * @returns
 */
export const enrichmentFn = (mapping: Mapping, rawData: any, data: GetImportDataQuery) => {
  if (mapping.target === Target.group) {
    const dd = rawData.map((d: any) => d[mapping?.source || '']?.trim()).filter(identity);
    const items = groupBy(dd, identity);
    const dm: DataMapping[] = Object.entries(items).map(([k, v]) => ({
      count: v.length,
      originalText: k,
      mappedData: first(fuzzysort.go(k || '', data?.customer_groups || [], { key: 'name' }))?.obj || null,
    }));
    return { ...mapping, data: dm };
  }

  if (mapping.target === Target.user) {
    const dd = rawData.map((d: any) => d[mapping?.source || '']?.trim()).filter(identity);
    const items = groupBy(dd, identity);
    const dm: DataMapping[] = Object.entries(items).map(([k, v]) => ({
      count: v.length,
      originalText: k,
      mappedData: first(fuzzysort.go(k || '', data?.users || [], { key: 'login' }))?.obj || null,
    }));
    return { ...mapping, data: dm };
  }

  if (mapping.target === Target.tags) {
    const items = flatten((rawData || []).map((d: any) => d[mapping?.source || '']?.split(',')))
      .map((d: any) => d?.trim())
      .filter(identity);
    const grp = groupBy(items);
    // console.log(grp);
    const dm: DataMapping[] = Object.entries(grp).map(([k, v]) => ({
      count: v.length,
      originalText: k,
      mappedData: first(fuzzysort.go(k || '', data?.tags || [], { key: 'name' }))?.obj || null,
    }));

    return { ...mapping, data: dm };
  }

  if (mapping.target === Target.supplier) {
    const dd = rawData.map((d: any) => d[mapping?.source || '']?.trim()).filter(identity);
    const items = groupBy(dd, identity);
    // console.log(items);
    const dm: DataMapping[] = Object.entries(items).map(([k, v]) => ({
      count: v.length,
      originalText: k,
      mappedData: first(fuzzysort.go(k || '', data?.suppliers || [], { key: 'name' }))?.obj || null,
    }));
    return { ...mapping, data: dm };
  }

  return mapping;
};
