import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { faDirections } from '@fortawesome/free-solid-svg-icons';
import { VIcon } from 'components/VIcon';

export const NavigateTo = ({
  latitude,
  longitude,
}: {
  latitude: number | null | undefined;
  longitude: number | null | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('addressControl.navigateTo') + ''}>
      <IconButton href={`https://www.google.com/maps/place/${latitude}+${longitude} `} target="_blank">
        <VIcon icon={faDirections} />
      </IconButton>
    </Tooltip>
  );
};
