import chroma from 'chroma-js';

export const ReactSelectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: '1.4375em',
    paddingTop: '10.5px',
    paddingBottom: '10.5px',
  }),
  option: (provided: any, state: any) => {
    return {
      ...provided,
      background: state?.data?.color || 'white',
      color: chroma(state?.data?.color || 'white').luminance() > 0.5 ? 'black' : 'white',
    };
  },
  menu: (base: any) => ({
    ...base,
    zIndex: 100,
  }),
  multiValue: (provided: any, state: any) => {
    return { ...provided, background: state?.data?.color || 'lightgray' };
  },
  multiValueLabel: (styles: any, state: any) => ({
    ...styles,
    color: chroma(state?.data?.color || 'white').luminance() > 0.5 ? 'black' : 'white',
  }),
};
