import React, { Fragment } from 'react';
import { Fab, IconButton, LinearProgress, SvgIcon, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import YesNoDialog from 'components/dialog/YesNoDialog';
import { faExternalLinkSquareAlt, faSave, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ContactList } from './ContactList';
import { useUser } from 'context/UserContext';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VisiterFab } from 'components/AddFab';
import { ShowIfMine } from './ShowIfMine';
import { Topics } from './Topics';
import { ImageGalery } from './ImageGrid';
import { FormikSelect } from './FormikSelect';
import { FormikDateTimePicker } from './transferOrders/FormikDateTimePicker';
import { FormikTextField } from './FormikTextField';
import { useMessages } from 'context/MessageContext';
import _ from 'lodash';
import { TitleDisplay } from './TitleDisplay';
import { FormikSwitch } from './FormikSwitch';
import { NavigateTo } from './NavigateTo';
import { VIcon } from 'components/VIcon';
import { Questionaire } from '../../components/questionaire/Questionaire';
import {
  GetCustomersQuery,
  GetCustomerVisitsDocument,
  GetVisitQuery,
  useDeleteVisitMutation,
  useGetCustomersQuery,
  useGetVisitQuery,
  useUpdateVisitFullMutation,
} from 'generated/graphql';

export const VisitEdit = ({
  customerId,
  visitId,
  cancel,
  isDialog,
  deleteRefetches,
}: {
  customerId?: number;
  visitId: number;
  cancel?: () => void;
  isDialog: boolean;
  deleteRefetches?: { query: any; variables: any }[];
}) => {
  const { id: userId } = useUser();

  // TODO Type!
  let f: any = [{ user_id: { _eq: userId } }, { user_id: { _is_null: true } }];
  if (customerId) {
    f = [...f, { id: { _eq: customerId } }];
  }
  const { data: customersData, loading: loadingCustomers } = useGetCustomersQuery({
    variables: {
      customerFilter: {
        _or: f,
      },
    },
  });

  const { loading, error, data } = useGetVisitQuery({
    variables: { visitId: visitId },
  });

  if (loading || loadingCustomers) {
    return <LinearProgress />;
  }
  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }

  const customers = customersData?.customers;
  if (
    customers === undefined ||
    customers === null ||
    data === null ||
    data?.visits_by_pk === null ||
    data?.visits_by_pk === undefined
  ) {
    return null;
  }

  const initialValues = {
    ...(data?.visits_by_pk || {
      id: 0,
      agreements: '',
      agreements_due: null,
      comment: '',
      customer: null,
      customer_id: null,
      date: null,
      end_date: null,
      user_id: null,
      topics: [],
      contacts: [],
      __typename: 'visits',
      took_place: false,
    }),
    answers: data.question.map((d) => ({
      question_id: d.id,
      answer: data?.visits_by_pk?.answers?.find((e) => e.question_id === d.id)?.answer || {},
    })),
  };

  return (
    <VisitEditForm
      visit={initialValues}
      customers={customers}
      isDialog={isDialog}
      cancel={cancel}
      deleteRefetches={deleteRefetches}
      questions={data.question}
    />
  );
};

export const VisitEditForm = ({
  visit,
  customers,
  isDialog,
  cancel,
  deleteRefetches,
  questions,
}: {
  visit: any;
  customers: GetCustomersQuery['customers'];
  cancel?: () => void;
  isDialog?: boolean;
  deleteRefetches?: { query: any; variables: any }[];
  questions?: GetVisitQuery['question'];
}) => {
  const { t } = useTranslation();
  const [confirm, setConfirm] = useState<boolean>(false);
  const { error, success } = useMessages();
  const [updateVisit] = useUpdateVisitFullMutation();
  const [deleteVisit] = useDeleteVisitMutation();

  const formik = useFormik({
    initialValues: visit,
    onSubmit: async (values, { setSubmitting }) => {
      console.log(values);
      // strip object
      const { contacts, customer, topics, user_id, id, __typename, answers, ...obj } = values;
      try {
        updateVisit({
          variables: {
            id,
            visit: obj,
            topics: topics.map((d: any) => ({ visit_id: id, topic_id: d?.topic?.id })),
            contacts: contacts.map((c: any) => ({ visit_id: id, contact_id: c.contact?.id })),
            answers: answers?.map((a: any) => ({ visit_id: id, question_id: a.question_id, answer: a.answer })) || [],
          },
        });
        success(t('Saved'));
        cancel && cancel();
      } catch (e) {
        error(JSON.stringify(e));
      }
    },
    enableReinitialize: true,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <YesNoDialog
        title={t('Delete Visit')}
        isOpen={confirm || false}
        noAction={() => setConfirm(false)}
        yesAction={async () => {
          try {
            await deleteVisit({
              variables: { visitId: visit.id || 0 },
              refetchQueries: [
                {
                  query: GetCustomerVisitsDocument,
                  variables: { customerId: formik.values.customer_id || 0 },
                },
                ...(deleteRefetches || []),
              ],
            });
          } catch (e) {
            console.error(e);
          }
          cancel && cancel();
        }}
        text={t('DeleteVisitWarningText')}
      />
      <Typography variant="h4">{t('Edit Visit')}</Typography>
      <Typography variant="subtitle2">
        {t('Id')}
        {': '}
        {formik.values.id}
      </Typography>
      <div style={{ display: 'flex' }}>
        <FormikSwitch formik={formik} label={t('Took Place')} name="took_place" />
        <NavigateTo latitude={visit?.customer?.latitude} longitude={visit?.customer?.longitude} />
        <IconButton href={`/customer/${visit.customer_id}`}>
          <VIcon icon={faExternalLinkSquareAlt} />
        </IconButton>
      </div>
      <FormikSelect
        formik={formik}
        label={t('Customer')}
        name="customer_id"
        options={customers}
        filterOptions={(candidate, input) => {
          if (!input) {
            return true;
          }

          return (
            candidate.data?.name?.match(new RegExp(input, 'i')) ||
            candidate.data?.address?.match(new RegExp(input, 'i'))
          );
        }}
        display={(o) => (
          <div>
            {o.name}
            <Typography variant="subtitle2">{o.address}</Typography>
          </div>
        )}
      />
      <FormikDateTimePicker formik={formik} label={t('From')} name="date" />
      <FormikDateTimePicker formik={formik} label={t('To')} name="end_date" />
      <Topics formik={formik} name="topics" />

      <ContactList
        formik={formik}
        contactAccess="contacts"
        addContact={(c) =>
          formik.setValues({
            ...formik.values,
            contacts: _.orderBy(
              [
                { __typename: 'visits_contacts', contact: c },
                ...formik.values.contacts.filter((d: any) => d?.contact?.id !== c.id),
              ],
              (d) => d?.contact?.firstname
            ),
          })
        }
        contactsWhere={{ customers: { customer_id: { _eq: visit.customer_id } } }}
      />
      <FormikTextField formik={formik} label={t('Comment')} name="comment" rows={8} />
      <FormikTextField formik={formik} label={t('Agreement')} name="agreements" rows={4} />

      <FormikDateTimePicker formik={formik} label={t('Agreements Due Date')} name="agreements_due" />
      {questions && questions.length > 0 && (
        <Fragment>
          <TitleDisplay title={t('Questionaire')} />
          <Questionaire formik={formik} questions={questions} />
        </Fragment>
      )}

      <div style={{ height: '30px' }} />
      <ImageGalery visitId={visit.id} customerId={formik.values.customer_id} />
      <div style={{ height: '60px' }} />
      <ShowIfMine objectUserId={visit.user_id}>
        <Fab
          onClick={() => setConfirm(true)}
          style={{
            margin: 0,
            top: 'auto',
            right: 'auto',
            bottom: 20,
            left: 20,
            position: isDialog ? 'absolute' : 'fixed',
            color: '#FFF',
            backgroundColor: '#f44336',
          }}
        >
          <SvgIcon>
            <FontAwesomeIcon icon={faTrash} />
          </SvgIcon>
        </Fab>
      </ShowIfMine>
      <Fab
        color="secondary"
        onClick={cancel}
        style={{
          margin: 0,
          top: 'auto',
          right: '100px',
          left: 'auto',
          bottom: 20,
          position: isDialog ? 'absolute' : 'fixed',
          color: '#FFF',
        }}
      >
        <SvgIcon>
          <FontAwesomeIcon icon={faTimes} />
        </SvgIcon>
      </Fab>
      <ShowIfMine objectUserId={visit.user_id}>
        <VisiterFab type="submit" position={isDialog ? 'absolute' : 'fixed'} icon={faSave} {...{ id: 'Save' }} />
      </ShowIfMine>
    </form>
  );
};
