// import { dateFnsLocalizer } from 'react-big-calendar';
// import parse from 'date-fns/parse';
// import startOfWeek from 'date-fns/startOfWeek';
// import getDay from 'date-fns/getDay';

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Dialog, DialogContent, Grid, LinearProgress } from '@mui/material';

import _ from 'lodash';
import { useUser } from 'context/UserContext';
import { add, parseISO } from 'date-fns';
import { Fragment, useRef, useState } from 'react'; 
import { VisitEdit } from 'pages/customer/VisitEdit';
import { CalendarUserFilter } from './CalendarUserFilter';
import FullCalendar, { DateSelectArg, EventClickArg, EventDropArg } from '@fullcalendar/react';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { EventResizeDoneArg } from '@fullcalendar/interaction';
// import { format } from 'date-fns-tz';
import {
  GetCalenderDataDocument,
  GetCalenderDataQuery, 
  useCreateVisitMutation,
  useGetCalenderDataQuery,
  useUpdateVisitMutation,
  Visits_Bool_Exp,
} from 'generated/graphql';
import { ArrayElement } from 'utils/ArrayElement';
// const DragAndDropCalendar = withDragAndDrop(Calendar as any);

// const locales = {
//   en: require('date-fns/locale/en-US'),
//   de: require('date-fns/locale/de'),
// };

// const localizer = dateFnsLocalizer({
//   format,
//   parse,
//   startOfWeek,
//   getDay,
//   locales,
// });

type EventContext = 'visit' | 'agreement';
type VisiterEvent<T> = {
  title: string;
  start?: Date;
  end?: Date;
  backgroundColor?: string;
  context: EventContext;
  object: T;
};

export type UserSelect = {
  id: number;
  color?: string;
};

const visitsToCalendarEvents = (
  visits: GetCalenderDataQuery['visits'] | undefined | null,
  selectedUsers: UserSelect[]
): VisiterEvent<ArrayElement<GetCalenderDataQuery['visits']>>[] => {
  return (visits || []).map((visit) => ({
    title: `${_.get(visit, 'customer.name')}\n ${(visit.topics || []).map((d) => d.topic?.topic).join('\n')}`,
    start: visit.date ? parseISO(visit.date) : undefined,
    end: visit.end_date ? parseISO(visit.end_date) : add(parseISO(visit.date), { hours: 1 }),
    backgroundColor: selectedUsers.find((d) => d.id === visit.user_id)?.color,
    context: 'visit' as EventContext,
    object: visit,
  }));

  // getColor(visit.userId)
};
// const styleGetter = (event: any, start: any, end: any, isSelected: any) => {
//   var style = {
//     background: event.background,
//   };
//   return {
//     style: style,
//   };
// };

export const agreementsToEvents = (
  visits: GetCalenderDataQuery['visits'] | undefined | null,
  selectedUsers: UserSelect[]
): VisiterEvent<ArrayElement<GetCalenderDataQuery['visits']>>[] => {
  return (visits || []).map((visit) => {
    // let c = selectedUsers.find((d) => d.id === visit.user_id)?.color || 'black';

    return {
      title: `${_.get(visit, 'customer.name')}\n  Agreement`,
      start: visit.agreements_due && parseISO(visit.agreements_due),
      end: visit.agreements_due && add(parseISO(visit.agreements_due), { hours: 1 }),
      backgroundColor: `red`,
      isAllDay: true,
      context: 'agreement' as EventContext,
      object: visit,
    };
  });
};

const buildUserFilter = (userIds: number[]): Visits_Bool_Exp => {
  if (userIds.length === 0) {
    return { user_id: { _eq: 0 } };
  }
  return { _or: userIds.map((uid) => ({ user_id: { _eq: uid } })) };
};

const buildFilter = (minMaxDate: { min: Date; max: Date }, selectedUsers: UserSelect[]): Visits_Bool_Exp => ({
  _and: [
    buildUserFilter(selectedUsers.map((d) => d.id)),
    {
      _or: [
        {
          date: { _gt: add(minMaxDate.min, { months: -1 }), _lt: add(minMaxDate.max, { months: 1 }) },
        },
        {
          agreements_due: {
            _gt: add(minMaxDate.min, { months: -1 }),
            _lt: add(minMaxDate.max, { months: 1 }),
          },
        },
      ],
    },
  ],
});

const VCalendar = () => {
  const { id: userId, hasRole } = useUser();
  const ref = useRef(null);
  const [updateVisit] = useUpdateVisitMutation();
  const [createVisit] = useCreateVisitMutation();

  const [selectedUsers, setSelectedUsers] = useState<UserSelect[]>([{ id: userId || 0 }]);

  const [minMaxDate, setMinMaxDate] = useState({ min: new Date(), max: new Date() });

  const [editVisitId, setEditVisitId] = useState<number | undefined>(undefined);
  const { data, loading, error } = useGetCalenderDataQuery({
    variables: { visitFilter: buildFilter(minMaxDate, selectedUsers) },
  });

  const doMoveEvent = async (args: EventDropArg | EventResizeDoneArg) => {
    console.log(args);

    //{ event, start, end, allDay }: { event: Event; start: Date; end: Date; allDay: boolean }

    const vEvent: VisiterEvent<ArrayElement<GetCalenderDataQuery['visits']>> = args.event._def.extendedProps as VisiterEvent<
      ArrayElement<GetCalenderDataQuery['visits']>
    >;

    if (vEvent.object.user_id !== userId) {
      args.revert();
      return;
    }

    const { start, end } = args.event._instance?.range || {};

    if (vEvent.context === 'visit') {
      const movedVisit = vEvent.object;
      if (!movedVisit || movedVisit.user_id !== userId) {
        return;
      }
      const updatedVisit = {
        date: start && add(start, { minutes: start.getTimezoneOffset() }),
        end_date: end && add(end, { minutes: end.getTimezoneOffset() }),
      };
      await updateVisit({
        variables: { id: movedVisit.id, visit: updatedVisit },
      });
    }
    if (vEvent.context === 'agreement') {
      const movedVisit = vEvent.object;
      if (!movedVisit || movedVisit.user_id !== userId) {
        return;
      }
      const updatedVisit = { agreements_due: start && add(start, { minutes: start.getTimezoneOffset() }) };
      await updateVisit({
        variables: { id: movedVisit.id, visit: updatedVisit },
      });
    }
  };

  const select = (arg: EventClickArg) => {
    const myEvent: VisiterEvent<ArrayElement<GetCalenderDataQuery['visits']>> = arg.event._def.extendedProps as VisiterEvent<
      ArrayElement<GetCalenderDataQuery['visits']>
    >;
    const visit = myEvent.object;
    if (!visit) {
      return;
    }
    setEditVisitId(visit.id);
  };

  const selectSlot = async (arg: DateSelectArg) => {
    if (arg.view.type === 'dayGridMonth') {
      return;
    }

    const { start, end } = arg;

    const { data } = await createVisit({
      variables: {
        visit: {
          date: start,
          end_date: end,
        },
      },
      refetchQueries: [
        {
          query: GetCalenderDataDocument,
          variables: { visitFilter: buildFilter(minMaxDate, selectedUsers) },
        },
      ],
    });
    const visit = data?.insert_visits_one;

    console.log('visit', visit);

    setEditVisitId(visit?.id || 0);
  };

  if (error) {
    console.error(error);
  }

  return (
    <Fragment>
      {Boolean(editVisitId) && editVisitId !== undefined && (
        <Dialog open={true}>
          <DialogContent>
            <div style={{ minWidth: '200px' }}>
              <VisitEdit
                isDialog={true}
                visitId={editVisitId}
                cancel={() => setEditVisitId(undefined)}
                deleteRefetches={[
                  {
                    query: GetCalenderDataDocument,
                    variables: { visitFilter: buildFilter(minMaxDate, selectedUsers) },
                  },
                ]}
              />
            </div>
          </DialogContent>
        </Dialog>
      )}
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={12} sm={3} md={2} style={{ height: 'auto' }}>
          {hasRole('manager') && (
            <CalendarUserFilter selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={hasRole('manager') ? 9 : 12}
          md={hasRole('manager') ? 10 : 12}
          style={{ height: '100%' }}
        >
          {loading && <LinearProgress />}

          <div
            style={{
              height: '100%',
            }}
          >
            <FullCalendar
              ref={ref}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              height="auto"
              locale="de"
              firstDay={1}
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay',
              }}
              droppable={true}
              editable={true}
              selectable={true}
              events={[
                ...visitsToCalendarEvents(data?.visits, selectedUsers),
                ...agreementsToEvents(data?.visits, selectedUsers),
              ]}
              dateClick={(a) => console.log('asdf', a)}
              initialView="dayGridMonth"
              eventDrop={doMoveEvent}
              eventResize={doMoveEvent}
              eventClick={select}
              select={selectSlot}
              datesSet={({ start, end }) => {
                setMinMaxDate({ min: start, max: end });
              }}
              navLinks={true}
            />
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default VCalendar;
