import {
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  SvgIcon,
  Typography,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { faCheese, faChevronCircleDown, faChevronCircleUp, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetDependingCustomersQuery } from 'generated/graphql';

export const DependingCustomers = ({ customerId }: { customerId: number }) => {
  const { data, loading, error } = useGetDependingCustomersQuery({
    variables: { customerId },
  });
  const { t } = useTranslation();
  const push = useNavigate();
  const [showCustomers, setShowCustomers] = useState(false);

  if (loading) {
    return null;
  }

  if (error) {
    console.error(error);
  }
  const customers = data?.customer_supplier;
  if (!(customers || []).length || !customers) {
    return null;
  }

  return (
    <Fragment>
      <Typography variant="h5">
        {t('Supplies')} {(customers || []).length}
      </Typography>
      <Divider />
      <FormControlLabel
        control={
          <Checkbox
            icon={<FontAwesomeIcon icon={faChevronCircleDown} />}
            checkedIcon={<FontAwesomeIcon icon={faChevronCircleUp} />}
            value={showCustomers}
            onClick={() => setShowCustomers(!showCustomers)}
          />
        }
        label={t('Show All') as string}
      />
      {showCustomers && (
        <List>
          {customers.map((d, i: number) => (
            <ListItem key={i}>
              <ListItemIcon>
                <SvgIcon>
                  <FontAwesomeIcon icon={faCheese} />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText>
                <div>
                  {t('Id')}: {d.customer.id}
                </div>
                <div>
                  {t('Name')}: {d.customer.name}
                </div>
              </ListItemText>
              <ListItemSecondaryAction>
                <IconButton onClick={() => push(`/customer/${d.customer.id}`)}>
                  <SvgIcon>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </SvgIcon>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </Fragment>
  );
};
