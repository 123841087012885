import {
  Dialog,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  SvgIcon,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TitleDisplay } from './TitleDisplay';
import { faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import { isArray } from 'lodash';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { AddContactDialog } from './AddContactDialog';
import { Contact } from './Contact';
import { Contacts_Bool_Exp } from 'generated/graphql';

export const ContactList = ({
  formik,
  contactAccess,
  addContact,
  titleOverride,
  contactsWhere,
  hideNewContactButton,
}: {
  formik: any;
  contactAccess: string;
  addContact?: (contact: any) => void;
  titleOverride?: string;
  contactsWhere?: Contacts_Bool_Exp;
  hideNewContactButton?: boolean;
}) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [contactId, setContactId] = useState<number | undefined>(undefined);

  const contacts = !formik.values[contactAccess]
    ? []
    : isArray(formik.values[contactAccess])
    ? formik.values[contactAccess]
    : [{ contact: formik.values[contactAccess] }];

  const { t } = useTranslation();

  return (
    <div>
      {contactId !== undefined && (
        <Dialog
          open={Boolean(contactId)}
          onClose={() => setContactId(undefined)}
          // PaperProps={{ style: { overflowY: 'visible' } }}
        >
          <DialogContent>
            <Contact
              contactId={contactId}
              onCancel={() => setContactId(undefined)}
              onSave={() => setContactId(undefined)}
            />
          </DialogContent>
        </Dialog>
      )}

      {showDialog && (
        <AddContactDialog
          addContact={(c) => {
            addContact && addContact(c);
            setShowDialog(false);
          }}
          open={showDialog}
          onClose={() => setShowDialog(false)}
          contactsWhere={contactsWhere}
          hideNewContactButton={hideNewContactButton}
        />
      )}

      <TitleDisplay showAdd title={titleOverride || t('Contacts') + ''} onClick={() => setShowDialog(true)} />
      <List>
        {contacts.map((it: any, i: number) => {
          return (
            <ListItem key={i} button onClick={() => setContactId(it?.contact?.id)} dense>
              <ListItemIcon>
                <SvgIcon>
                  <FontAwesomeIcon icon={faUser} />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText
                primary={`${it?.contact?.firstname} ${it?.contact?.name}`}
                secondary={`${(it?.contact?.position || {}).position || ''} ${
                  (it?.contact?.birthday && format(parseISO(it?.contact?.birthday), 'dd.MM.yyyy')) || ''
                }`}
              />
              <ListItemSecondaryAction>
                <IconButton
                  onClick={(e: React.MouseEvent) =>
                    formik.setValues({
                      ...formik.values,
                      contacts: contacts.filter((d: any) => d?.contact?.id !== it?.contact?.id),
                    })
                  }
                >
                  <FontAwesomeIcon icon={faTimes} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};
