import React from 'react';
import { Fab, SvgIcon } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const AddFab = ({ to, onClick }: { to?: any; onClick?: any }) => {
  return (
    <Fab
      style={{
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
      }}
      color="primary"
      aria-label="Add"
      to={to}
      component={Link}
    >
      <SvgIcon>
        <FontAwesomeIcon icon={faPlus} />
      </SvgIcon>
    </Fab>
  );
};

export const VisiterFab = ({
  to,
  onClick,
  position,
  icon,
  ariaLabel,
  type,
  ...props
}: {
  to?: any;
  onClick?: any;
  position?: any;
  icon?: any;
  ariaLabel?: any;
  type?: any;
}) => (
  <Fab
    onClick={onClick}
    style={{
      margin: 0,
      top: 'auto',
      right: 20,
      bottom: 20,
      left: 'auto',
      position: position || 'fixed',
    }}
    color="primary"
    type={type}
    {...props}
  >
    <SvgIcon>
      <FontAwesomeIcon icon={icon} />
    </SvgIcon>
  </Fab>
);

export default AddFab;
