import {
  Button,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import React, { Fragment, useState } from 'react';
import { FormikDateTimePicker } from './FormikDateTimePicker';
import { LinearProgressWithLabel } from 'pages/products/LinearProgressWithLabel';
import { VIcon } from 'components/VIcon';
import { useUser } from 'context/UserContext';
import { useMessages } from 'context/MessageContext';
import { ContactList } from '../ContactList';

import { VAvatar } from 'pages/products/VAvatar';
import { FormikSelect } from '../FormikSelect';
import { groupBy, orderBy } from 'lodash';
import { VisiterFab } from 'components/AddFab';
import { FormikTextField } from '../FormikTextField';
import { Box } from '@mui/system';
import {
  GetTransferOrdersDocument,
  UpdateTransferOrderMutationVariables,
  useCreateTransferOrderMutation,
  useGetTransferOrderQuery,
  useSubProductsSubscription,
  useUpdateTransferOrderMutation,
} from 'generated/graphql';

//
export const TransferOrderEdit = ({
  editTransferOrderId,
  customerId,
  suppliers,
  close,
}: {
  editTransferOrderId: number;
  customerId: number;
  suppliers?: any; //GetTransferOrders_customers_by_pk_suppliers[] | undefined;
  close: () => void;
}) => {
  return (
    <TransferOrderEditDataProvider
      editTransferOrderId={editTransferOrderId}
      customerId={customerId}
      close={close}
      suppliers={suppliers}
    />
  );
};

const TransferOrderEditDataProvider = ({
  editTransferOrderId,
  customerId,
  suppliers,
  close,
}: {
  editTransferOrderId: number;
  customerId: number;
  suppliers?: any;
  close: () => void;
}) => {
  const {
    data: productsData,
    loading,
    error,
  } = useSubProductsSubscription({
    variables: { where: { orderable: { _eq: true } } },
  });

  const { data: toData, error: toError } = useGetTransferOrderQuery({
    variables: { id: editTransferOrderId },
  });

  if (editTransferOrderId > 0 && loading) {
    return <LinearProgress />;
  }
  if (error || !productsData?.products || toError) {
    //console.error(error, toError);
    return null;
  }

  return (
    <TransferOrderEditForm
      customerId={toData?.transfer_orders_by_pk?.customer_id || customerId}
      editTransferOrder={toData?.transfer_orders_by_pk}
      defaultDate={new Date()}
      products={productsData.products}
      suppliers={suppliers}
      close={close}
    />
  );
};

export const TransferOrderEditForm = ({
  editTransferOrder,
  defaultDate,
  products,
  customerId,
  suppliers,
  close,
}: {
  editTransferOrder: any;
  defaultDate: Date;
  products: any[];
  customerId: number;
  suppliers?: any[] | undefined;
  close: () => void;
}) => {
  const { error, success } = useMessages();
  const { id: userId } = useUser();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);

  const [updateTransferOrder] = useUpdateTransferOrderMutation();
  const [createTransferOrder] = useCreateTransferOrderMutation();

  const initialValues = {
    date: editTransferOrder?.date || defaultDate,
    confirmation_date: editTransferOrder?.confirmation_date || null,
    supplier_id:
      editTransferOrder?.supplier_id || (suppliers && suppliers.length > 0 ? suppliers[0].supplier.id : null),
    items: products.map((p) => ({
      ...p,
      available_amount:
        (p.available_amount || 0) + (editTransferOrder?.items.find((d: any) => d.product_id === p.id)?.count || 0),
      amount: editTransferOrder?.items.find((d: any) => d.product_id === p.id)?.count || 0,
      amount_free: editTransferOrder?.items.find((d: any) => d.product_id === p.id)?.amount_free || 0,
    })),
    contact: editTransferOrder?.contact,
    comment: editTransferOrder?.comment,
  };

  const f = useFormik({
    initialValues,
    onSubmit: async (vals) => {
      // console.log('submit', vals);

      const gotNegatives =
        vals.items
          .map(
            (d) =>
              (-d.amount || 0) +
              (-d.amount_free || 0) +
              (f.values.items.find((e) => e.id === d.id)?.available_amount || 0)
          )
          .filter((d) => d < 0).length > 0;

      if (gotNegatives) {
        error(t('Cannot sell more than is available.'));
        return;
      }

      let id = editTransferOrder?.id;
      if (!id) {
        console.log('creating order', customerId);
        try {
          const { data } = await createTransferOrder({
            variables: { customerId, date: new Date() },
            refetchQueries: [{ query: GetTransferOrdersDocument, variables: { customerId } }],
          });
          id = data?.insert_transfer_orders_one?.id;
        } catch (e) {
          console.error(JSON.stringify(e));
        }
      }

      if (!id) {
        error(t('Could not create transfer order'));
        return;
      }
      const vars: UpdateTransferOrderMutationVariables = {
        id: id,
        transferOrder: {
          date: vals.date,
          contact_id: vals?.contact?.id,
          confirmation_date: vals?.confirmation_date,
          supplier_id: vals?.supplier_id,
          comment: vals?.comment,
        },
        items: vals.items
          .map((it) => ({
            transfer_order_id: id,
            product_id: it.id,
            count: Number(it.amount),
            amount_free: Number(it.amount_free),
          }))
          .filter((d) => d.count || d.amount_free),
        // freeItems: [],
        // FIXME
        storageItems: vals.items
          .map((it) => ({
            action: 'change',
            amount: -it.amount - it.amount_free, // the minus is IMPORTANT
            customer_id: customerId,
            products_id: it.id,
            transfer_order_id: id,
            user_id: userId,
          }))
          .filter((d) => d.amount),
      };

      // console.log(vars);
      try {
        await updateTransferOrder({
          variables: vars,
        });
        success(t('Successfuly saved!'));
        close();
      } catch (e) {
        console.error(JSON.stringify(e));
        error(t('Error while saving'));
      }
    },
    enableReinitialize: false,
  });

  const grps = orderBy(
    Object.entries(groupBy(f.values.items, (d) => d.product_group?.name || t('customer.noProductGroup'))),
    ([a]) => a
  );

  return (
    <form onSubmit={f.handleSubmit}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={(e, v) => setSelectedTab(v)}>
          <Tab label="Allgemein" />
          <Tab label="Produkte" />
        </Tabs>
      </Box>

      {selectedTab === 0 && (
        <Fragment>
          <FormikDateTimePicker formik={f} name="date" label={t('Delivery Date')} />
          <FormikDateTimePicker formik={f} name="confirmation_date" label={t('Call Date')} />

          <FormikSelect
            label={t('Supplier')}
            formik={f}
            name="supplier_id"
            options={suppliers?.map((d) => d.supplier) || []}
          />
          <ContactList
            formik={f}
            addContact={(c) => f.setValues({ ...f.values, contact: c })}
            contactAccess="contact"
            titleOverride={t('Contact')}
            contactsWhere={{ customers: { customer_id: { _eq: customerId } } }}
            hideNewContactButton={true}
          />

          <FormikTextField formik={f} name="comment" label={t('order.comment')} rows={4} />
        </Fragment>
      )}
      {selectedTab === 1 && (
        <Fragment>
          {grps.map(([group, products]) => (
            <List key={group}>
              <ListItem>
                <ListItemText primary={group} secondary={''} />
              </ListItem>
              {products.map((product, i) => {
                const isError =
                  Number(product.amount) + Number(product.amount_free) > (product.available_amount || 0) ||
                  product.amount < 0;
                return (
                  <Grid
                    container
                    key={product.id}
                    style={{ margin: '0.75em 0', border: '1px solid rgba(0,0,0,0.2)', padding: '0.5em' }}
                  >
                    <Grid item xs={12} sm={6}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <VAvatar src={product.avatar_path || undefined} />
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, margin: '0 1em' }}>
                          <Typography>{product.name}</Typography>
                          <LinearProgressWithLabel
                            label={t('Available')}
                            variant="buffer"
                            color={isError ? 'error' : undefined}
                            value={(product.available_amount || 0) - product.amount - product.amount_free}
                            valueBuffer={product.available_amount || 0}
                            max={product.available_amount || 0}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        label={t('amount')}
                        type="number"
                        fullWidth
                        color={isError ? 'error' : undefined}
                        value={product.amount || ''}
                        onChange={(e) =>
                          f.setValues({
                            ...f.values,
                            items: f.values.items.map((d, j) =>
                              d.id === product.id ? { ...d, amount: Number(e.target.value || 0) } : d
                            ),
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        label={t('transferOrdesDialog.freeAmount')}
                        type="number"
                        fullWidth
                        color={isError ? 'error' : undefined}
                        value={product.amount_free || ''}
                        onChange={(e) =>
                          f.setValues({
                            ...f.values,
                            items: f.values.items.map((d, j) =>
                              d.id === product.id ? { ...d, amount_free: Number(e.target.value || 0) } : d
                            ),
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </List>
          ))}
        </Fragment>
      )}
      <VisiterFab type="submit" position={'absolute'} icon={faSave} />
      <Button variant="contained" type="submit" startIcon={<VIcon icon={faSave} />}>
        {t('Save')}
      </Button>
    </form>
  );
};
