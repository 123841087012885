import React, { Fragment } from 'react';
import { Grid, LinearProgress, TextField, Typography } from '@mui/material';
import { Answer } from 'types/Answer';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { GetVisitQuery, useGetProductsWhereQuery } from 'generated/graphql';
import { ArrayElement } from 'utils/ArrayElement';

export const QuestionDatePerProduct = ({
  formik,
  question,
}: {
  formik: any;
  question: ArrayElement<GetVisitQuery['question']>;
}) => {
  const { data, loading } = useGetProductsWhereQuery({
    variables: {
      where: {
        customers: { customer_id: { _eq: formik?.values?.customer_id } },
      },
    },
  });

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={8}>
        <Typography>{question.text}</Typography>
      </Grid>
      {data?.products?.map((p) => (
        <Fragment key={p.id}>
          <Grid item xs={12} sm={6} style={{ margin: 'auto' }}>
            {p.name}
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                clearable
                value={
                  formik.values.answers
                    .find((d: Answer) => d.question_id === question.id)
                    ?.answer?.values?.find((d: any) => d.product_id === p.id)?.value || null
                }
                onChange={(e) =>
                  formik.setValues({
                    ...formik.values,
                    answers: [
                      ...formik.values.answers.filter((d: Answer) => d.question_id !== question.id),
                      {
                        ...formik.values.answers.find((d: Answer) => d.question_id === question.id),
                        question_id: question.id,
                        answer: {
                          values: [
                            ...(formik.values.answers
                              .find((d: Answer) => d.question_id === question.id)
                              ?.answer?.values?.filter((d: any) => d.product_id !== p.id) || []),
                            {
                              product_id: p.id,
                              value: e,
                            },
                          ],
                        },
                      },
                    ],
                  })
                }
                renderInput={(params) => <TextField {...params} />}
                inputFormat={'dd.MM.yy'}
                mask="__.__.__"
              />
            </LocalizationProvider>
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
};
