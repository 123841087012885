import { LinearProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TitleDisplay } from '../TitleDisplay';
import { useState } from 'react';
import { EditPromotionDialog } from './EditPromotionDialog';
import { PromotionItem } from './PromotionItem';
import React from 'react';
import {
  useGetPromotionsForCustomerQuery,
  GetPromotionsForCustomerDocument,
  useDeleteProductMutation,
} from 'generated/graphql';

/**
 * The promotion control
 */
export const Promotions = ({ customerId }: { customerId: number }) => {
  const { t } = useTranslation();
  const { data, loading, error, refetch } = useGetPromotionsForCustomerQuery({
    variables: { customerId: customerId },
  });
  const [deletePromotion] = useDeleteProductMutation();
  const [editPromotionId, setEditPromotionId] = useState<number>(0);

  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    return null; // JSON.stringify(error);
  }

  const promotions = data?.promotions;

  if (promotions === null || promotions === undefined) {
    return null;
  }

  const editPromotion = promotions.find((d) => d.id === editPromotionId);

  return (
    <div style={{ marginBottom: '20px' }}>
      {Boolean(editPromotionId) && (
        <EditPromotionDialog
          isOpen={Boolean(editPromotionId)}
          editPromotion={editPromotion}
          promotionTypes={data?.promotion_type || []}
          customerId={customerId}
          close={async () => {
            setEditPromotionId(0);
            await refetch();
          }}
          setEditPromotionId={setEditPromotionId}
        />
      )}
      <Typography variant="h5" />
      <TitleDisplay
        title={t('Promotions')}
        showAdd
        onClick={async () => {
          // const { data } = await createPromotion({
          //   variables: { customerId },
          //   refetchQueries: [{ query: GetPromotionsForCustomerQuery, variables: { customerId } }],
          // });
          // if (data?.insert_promotions_one !== undefined) {
          //   setEditPromotionId(data?.insert_promotions_one?.id || 0);
          // }
          setEditPromotionId(-1);
        }}
      />

      {!promotions || promotions.length === 0 ? (
        <div>{t('No Promotions')}</div>
      ) : (
        <div style={{ overflowX: 'auto' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell> </TableCell>
                <TableCell>Von</TableCell>
                <TableCell>Bis</TableCell>
                <TableCell>Typ</TableCell>
                <TableCell>Gesamtlaufzeit[yr] (Berechnet)</TableCell>
                <TableCell>HL Gesamt (Berechnet)</TableCell>
                <TableCell>Kosten Gesamt</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {promotions.map((promotion, i) => (
                <PromotionItem
                  key={`promotion_${i}`}
                  promotion={promotion}
                  openEdit={() => {
                    setEditPromotionId(promotion.id);
                  }}
                  deletePromotion={async () => {
                    await deletePromotion({
                      variables: { id: promotion?.id || 0 },
                      refetchQueries: [{ query: GetPromotionsForCustomerDocument, variables: { customerId } }],
                    });
                  }}
                />
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};
