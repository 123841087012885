import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { FormikTextField } from 'pages/customer/FormikTextField';

export const InputDialog = ({
  title,
  text,
  isOpen,
  yesAction,
  noAction,
  ...rest
}: {
  title: string;
  text: string;
  isOpen: boolean;
  yesAction: (value: string) => void;
  noAction: () => void;
  id?: any;
}) => {
  const { t } = useTranslation();
  const f = useFormik({
    initialValues: { val: '' },
    onSubmit: (vals) => {
      yesAction(vals.val);
    },
  });

  return (
    <Dialog {...rest} fullScreen={false} open={isOpen} onClose={noAction} aria-labelledby="responsive-dialog-title">
      <form onSubmit={f.handleSubmit}>
        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
          <FormikTextField formik={f} name="val" />
        </DialogContent>
        <DialogActions style={{ margin: '0 1em 0.5em 1em' }}>
          <Button variant="outlined" onClick={noAction} color="inherit">
            {t('Cancel')}
          </Button>
          <div style={{ flex: 1 }} />
          <Button type="submit" variant="contained" color="success">
            {t('OK')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default InputDialog;
