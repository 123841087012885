import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useUser } from 'context/UserContext';
import { format, parseISO } from 'date-fns/esm';
import { TitleDisplay } from 'pages/customer/TitleDisplay';
import { VIcon } from 'components/VIcon';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Fragment, useState } from 'react';
import { VDownloadLink } from 'components/VDownloadLink';
import { useFormik } from 'formik';
import { FormikSelect } from 'pages/customer/FormikSelect';
import { FormikTextField } from 'pages/customer/FormikTextField';
import {
  GetTransferOrdersForUserDocument,
  GetTransferOrdersForUserQuery,
  useGetTransferOrdersForUserQuery,
  useGetTransferOrderStatiQuery,
  useUpdateTransferOrderOnlyMutation,
} from 'generated/graphql';
import { ArrayElement } from 'utils/ArrayElement';

const TransferOrdersPage = () => {
  const { t } = useTranslation();
  const { id: userId } = useUser();
  const { data, loading, error } = useGetTransferOrdersForUserQuery({ variables: { userId: userId || 0 } });
  if (error) {
    console.error(error);
  }
  if (loading) {
    return <LinearProgress />;
  }

  return (
    <div>
      <TitleDisplay title={t('Transfer Orders')} />
      <div style={{ height: '1em' }} />

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('Created')}</TableCell>
              <TableCell>{t('Delivery date')} </TableCell>
              <TableCell>{t('Call date')} </TableCell>
              <TableCell>{t('Customer')} </TableCell>
              <TableCell> </TableCell>
              <TableCell>{t('Contact')} </TableCell>
              <TableCell>{t('Total amount')} </TableCell>
              <TableCell>{t('Status')}</TableCell>
              <TableCell>{t('Download')}</TableCell>
              <TableCell>{t('TODO')} </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.transfer_orders.map((transferOrder, i) => (
              <TransferOrderItem key={i} transferOrder={transferOrder} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />

      {/* {data?.transfer_orders && data?.transfer_orders?.length > 0 && data?.transfer_orders[0] ? (
        <Doc transferOrder={data?.transfer_orders[0]} />
      ) : null} */}
    </div>
  );
};

const TransferOrderItem = ({
  transferOrder,
}: {
  transferOrder: ArrayElement<GetTransferOrdersForUserQuery['transfer_orders']>;
}) => {
  const { t } = useTranslation();
  const formatDate = (d: string) => d && format(parseISO(d), 'HH:mm dd.MM.yy');
  const h = useNavigate();
  const [openChangeStateDialog, setOpenChangeStateDialog] = useState(false);
  return (
    <Fragment>
      {openChangeStateDialog && (
        <Dialog
          open={openChangeStateDialog}
          onClose={() => setOpenChangeStateDialog(false)}
          PaperProps={{ style: { overflowY: 'visible' } }}
        >
          <DialogTitle>{t('order.changeStatus')}</DialogTitle>
          <DialogContent style={{ overflowY: 'visible' }}>
            <TransferOrderStateDialog
              currentStateId={transferOrder.status.id}
              transferOrderId={transferOrder.id}
              close={() => setOpenChangeStateDialog(false)}
              transferOrder={transferOrder}
            />
          </DialogContent>
        </Dialog>
      )}
      <TableRow>
        <TableCell>{formatDate(transferOrder.creation_date)} </TableCell>
        <TableCell>{formatDate(transferOrder.date)} </TableCell>
        <TableCell>{formatDate(transferOrder.confirmation_date)} </TableCell>
        <TableCell>
          <b>{transferOrder.customer?.name}</b>
          <Typography variant="subtitle2">{transferOrder.customer?.address}</Typography>
        </TableCell>
        <TableCell>
          <IconButton onClick={() => h(`/customer/${transferOrder.customer?.id}`)}>
            <VIcon icon={faExternalLinkSquareAlt} />
          </IconButton>
        </TableCell>
        <TableCell>{`${transferOrder.contact?.firstname} ${transferOrder.contact?.name}`} </TableCell>
        <TableCell>{transferOrder.items_aggregate.aggregate?.sum?.count} </TableCell>
        <TableCell>{transferOrder.status.name} </TableCell>
        <TableCell>
          <VDownloadLink
            name={`order-${transferOrder.id}.pdf`}
            mime="application/pdf"
            src={`/api/get-order-document/${transferOrder.id}`}
          />
        </TableCell>
        <TableCell>to do </TableCell>
        <TableCell>
          <Button onClick={() => setOpenChangeStateDialog(true)} variant="contained">
            {t('order.changeStatus')}
          </Button>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

const TransferOrderStateDialog = ({
  transferOrderId,
  currentStateId,
  close,
  transferOrder,
}: {
  transferOrderId: number;
  currentStateId: number;
  close?: () => void;
  transferOrder: ArrayElement<GetTransferOrdersForUserQuery['transfer_orders']>;
}) => {
  const { id: userId } = useUser();
  const { t } = useTranslation();
  const { data } = useGetTransferOrderStatiQuery();
  const [updateTransferOrder] = useUpdateTransferOrderOnlyMutation();

  const f = useFormik({
    initialValues: {
      status_id: currentStateId,
      comment: transferOrder.comment,
    },
    onSubmit: async (vals) => {
      await updateTransferOrder({
        variables: { id: transferOrderId, transferOrder: vals },
        refetchQueries: [{ query: GetTransferOrdersForUserDocument, variables: { userId: userId || 0 } }],
      });
      close && close();
    },
  });

  return (
    <form onSubmit={f.handleSubmit}>
      <FormikSelect formik={f} name="status_id" label={t('order.status')} options={data?.transfer_order_status || []} />
      {(f.values.status_id === 2 || f.values.status_id === 4) && (
        <FormikTextField formik={f} name="comment" rows={10} label={t('Comment')} />
      )}
      <Button type="submit" variant="contained" fullWidth>
        {t('Save')}
      </Button>
    </form>
  );
};

export default TransferOrdersPage;
