import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Login } from './pages/Login';
import { SyncDisplay } from 'components/syncDisplay/SyncDisplay';
import { Home } from './pages/home/Home';
import { ProductsPage } from './pages/products/ProductsPage';
import { Profile } from './pages/Profile';
import { ProtectedRoute } from './utils/ProtectedRoute';
import { Visits } from './pages/visits/Visits';
import { EmailVerifyPage } from './pages/EmailVerifyPage';

import TagsPage from 'pages/tags/TagsPage';
import CustomerTree from 'pages/customers/Customers';
import CustomerPage from 'pages/customer/CustomerPage';
import VCalendar from 'pages/calendar/VCalendar';
import Settings from 'pages/settings/Settings';
import DocumentsPage from 'pages/documents/Documents';
import MapPage from 'pages/mapPage/MapPage';
import OrderOveriew from 'pages/orderOverview/OrderOveriew';
import TransferOrdersPage from 'pages/transfer-orders/TransferOrdersPage';
import ImportData from 'pages/import-data/ImportData';

// const MapPage = React.lazy(() => import('pages/mapPage/MapPage'));
// const CustomerPage = React.lazy(() => import('pages/customer/CustomerPage'));
// const VCalendar = React.lazy(() => import('pages/calendar/VCalendar'));
// const DocumentsPage = React.lazy(() => import('pages/documents/Documents'));
// const TransferOrdersPage = React.lazy(() => import('pages/transfer-orders/TransferOrdersPage'));
// const Settings = React.lazy(() => import('pages/settings/Settings'));
// const CustomerTree = React.lazy(() => import('pages/customers/Customers')); 
// const OrderOveriew = React.lazy(() => import('pages/orderOverview/OrderOveriew')); 
// const ImportData = React.lazy(() => import('pages/import-data/ImportData'));

export const ContentRouter = () => {
  return (
    <Routes>
      <Route path="/customers" element={<CustomerTree />} />
      <Route path="/customer/:id" element={<CustomerPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/visits" element={<Visits />} />
      <Route path="/email-verify/:id/:email/:token" element={<EmailVerifyPage />} />
      <Route path="/calendar" element={<VCalendar />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/documents" element={<DocumentsPage />} />
      <Route path="/map" element={<MapPage />} />
      <Route path="/order-overview" element={<OrderOveriew />} />
      <Route path="/offline-mutations" element={<SyncDisplay />} />
      <Route path="/transfer-orders" element={<TransferOrdersPage />} />
      <Route path="/products" element={<ProductsPage />} />
      <Route path="/tags" element={<TagsPage />} />
      <Route path="/import-data" element={<ImportData />} />
      <Route
        path="/profile"
        element={
          <ProtectedRoute redirect="/login">
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/"
        element={
          <ProtectedRoute redirect="/login">
            <Home />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};
