import { Avatar, AvatarProps, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';

import axios from '../../utils/CachedAxios';

export const VAvatar = ({ src, ...r }: AvatarProps) => {
  const [_src, setSrc] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const dit = async () => {
      if (!src) {
        return;
      }
      setLoading(true);
      try {
        const { data } = await axios.get(src || '', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          responseType: 'blob', // Important
        });
        setSrc(URL.createObjectURL(new Blob([data]))); //URL.createObjectURL(blob);
      } catch (eee) {
        // console.log(eee);
      }
      setLoading(false);
    };
    dit();
  }, [setSrc, src]);

  if (loading) {
    return <CircularProgress />;
  }
  return <Avatar src={_src} alt="" {...r} />;
};
