import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  ThemeProvider,
} from '@mui/material';

const customTheme = (outerTheme: any) => ({
  ...outerTheme,
  palette: {
    ...outerTheme.palette,
    primary: { main: '#f44336' },
  },
});

const YesNoDialog = ({
  title,
  text,
  isOpen,
  yesAction,
  noAction,
  okColor,
}: {
  title: string;
  text: string | JSX.Element;
  isOpen: boolean;
  yesAction: () => void;
  noAction: () => void;
  okColor?: 'inherit' | 'error' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | undefined;
}) => {
  return (
    <ThemeProvider theme={customTheme}>
      <Dialog fullScreen={false} open={isOpen} onClose={noAction} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" autoFocus onClick={noAction} color="inherit">
            No
          </Button>
          <div style={{ flex: 1 }} />
          <Button variant="contained" onClick={yesAction} autoFocus color={okColor || 'error'}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};
export default YesNoDialog;
