import { Dialog, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TagItem from './TagItem';
import { faCancel } from '@fortawesome/free-solid-svg-icons';
import { VIcon } from 'components/VIcon';
import { EditTag } from './EditTag';
import { TitleDisplay } from 'pages/customer/TitleDisplay';
import { GetTagsPageQuery } from 'generated/graphql';
import { ArrayElement } from 'utils/ArrayElement';

const TagsDisplay = ({ tags }: { tags: GetTagsPageQuery['tags'] }) => {
  const [search, setSearch] = useState('');
  const [editTagId, setEditTagId] = useState(0);
  const { t } = useTranslation();

  const f = (d: ArrayElement<GetTagsPageQuery['tags']>) => new RegExp(search, 'i').test(d.name || '');
  const tag = tags.find((t) => t.id === editTagId);
  return (
    <Fragment>
      <Dialog open={Boolean(editTagId)} PaperProps={{ style: { overflow: 'visible' } }}>
        <DialogTitle>
          <div style={{ display: 'flex' }}>
            {t('tag.editTitle')}
            <div style={{ flex: 1 }} />
            <Button startIcon={<VIcon icon={faCancel} />} variant="outlined" onClick={() => setEditTagId(0)}>
              {t('cancel')}
            </Button>
          </div>
        </DialogTitle>
        <DialogContent style={{ overflow: 'visible' }}>
          <EditTag tag={tag} close={() => setEditTagId(0)} />
        </DialogContent>
      </Dialog>
      <TitleDisplay title={t('Tags')} showAdd onClick={() => setEditTagId(-1)} />
      <TextField
        label={t('search')}
        style={{ margin: '1em 0' }}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        fullWidth
      />

      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5em' }}>
        {tags.filter(f).map((tag) => (
          <TagItem key={tag.id} tag={tag} select={() => setEditTagId(tag.id)} />
        ))}
      </div>
    </Fragment>
  );
};

export default TagsDisplay;
