import { Checkbox, FormControlLabel } from '@mui/material';

export const FormikCheckbox = ({ formik, name, label }: any) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          id={name}
          name={name}
          checked={formik.values[name] || false}
          onChange={(e) => formik.setValues({ ...formik.values, [name]: e.target.checked })}
        />
      }
      label={label}
    />
  );
};
