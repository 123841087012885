import React from 'react';
import { Popup } from 'react-map-gl';
import {
  Button,
  ButtonGroup,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  ToggleButton,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { faCheckCircle, faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { differenceInCalendarDays, parseISO } from 'date-fns';
import { VIcon } from 'components/VIcon';
import { useCustomerSelection } from 'context/CustomerSelectionContext';
import { NavigateTo } from 'pages/customer/NavigateTo';
import { GetCustomersQuery } from 'generated/graphql';
import { ArrayElement } from 'utils/ArrayElement';

export const CustomerPopup = ({
  customer,
  close,
  openCustomer,
}: {
  customer: ArrayElement<GetCustomersQuery['customers']> | undefined;
  close: () => void;
  openCustomer?: (id: number) => void;
}) => {
  const { t } = useTranslation();
  const { isSelected, toggleSelection } = useCustomerSelection();

  if (customer === undefined) {
    return null;
  }

  const dt = differenceInCalendarDays(new Date(), parseISO(customer.lastVisit.aggregate?.max?.date));

  let vt = '';
  if (!customer.lastVisit.aggregate?.max?.date) {
    vt = t('never');
  } else if (dt === 0) {
    vt = t('today');
  } else if (dt < 0) {
    vt = t('in {{dt}} days', { dt: -dt });
  } else {
    vt = t('{{dt}} days ago', { dt });
  }

  return (
    <Popup
      latitude={customer?.latitude || 0}
      longitude={customer?.longitude || 0}
      closeButton={true}
      closeOnClick={false}
      onClose={close}
      anchor="top"
    >
      <TableContainer component={Paper} style={{ border: '0px', boxShadow: 'none' }}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>{t('id')}</TableCell>
              <TableCell>{customer.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('name')}</TableCell>
              <TableCell>{customer.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('address')}</TableCell>
              <TableCell>{customer.address}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('login')}</TableCell>
              <TableCell>{customer.user?.login}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ border: 'none' }}>{t('nearest visit')}</TableCell>
              <TableCell style={{ border: 'none' }}>{vt}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Grid
        container
        style={{
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        <ButtonGroup fullWidth>
          <Tooltip title={t('Select Customer') as string}>
            <ToggleButton
              value="selected"
              selected={isSelected(customer.id)}
              onClick={() => toggleSelection(customer.id)}
              color="primary"
              size="small"
            >
              <VIcon icon={faCheckCircle} />
            </ToggleButton>
          </Tooltip>
          <NavigateTo latitude={customer.latitude} longitude={customer.longitude} />

          <Tooltip title={t('Open Customer') as string}>
            <Button
              size="small"
              onClick={() => openCustomer && openCustomer(customer.id)}
              variant="outlined"
              startIcon={<VIcon icon={faExternalLinkSquareAlt} />}
            />
          </Tooltip>
        </ButtonGroup>
      </Grid>
    </Popup>
  );
};
