import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, SvgIcon } from '@mui/material';
import { useSyncContext } from 'context/SyncContext';
import { useStyles } from 'hooks/useStyles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const GoingOnlinePrompt = () => {
  const { shouldDisplayWarning, hideWarning } = useSyncContext();
  const { t } = useTranslation();
  const c = useStyles();
  const h = useNavigate();
  return shouldDisplayWarning ? (
    <Dialog open={true}>
      <DialogTitle className={c.errorBackground}>{t('Sync Data')}</DialogTitle>
      <DialogContent className={c.errorBackground}>{t('OpenChangesToDataText')}</DialogContent>
      <DialogActions className={c.errorBackground}>
        <Button
          onClick={() => {
            hideWarning();
            h('/offline-mutations');
          }}
          variant="outlined"
          startIcon={
            <SvgIcon style={{ height: '13px' }}>
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </SvgIcon>
          }
        >
          {t('Show')}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};
