import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { VDrawer } from './VDrawer';
import { UserMenu } from './UserMenu';
import { useStyles } from '../../hooks/useStyles';
import { VIcon } from 'components/VIcon';
import { faSync } from '@fortawesome/free-solid-svg-icons';

export const VAppBar = () => {
  const c = useStyles();
  return (
    <AppBar className={c.appBar}>
      <Toolbar>
        <VDrawer />
        <Box fontSize="20pt" fontWeight="bold">
          Visiter
        </Box>
        <div style={{ flexGrow: 1 }} />
        <IconButton id="RefreshButton" color="inherit" onClick={() => window.location.reload()}>
          <VIcon icon={faSync} />
        </IconButton>
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
};
