import { useEffect, useRef, useState } from 'react';
import { useStyles } from '../../hooks/useStyles';
import { IconButton, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
  faTimes,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { Message, useMessages } from '../../context/MessageContext';
import { Severities } from 'types/Severities';

export const SingleMessage = (props: any) => {
  const message: Message = props.message;
  let messageClass, icon;
  const classes = useStyles();
  const interval = useRef<number | undefined>(undefined);
  const [progress, setProgress] = useState(100);
  const { remove, hide } = useMessages();

  const refreshReate = 200;
  useEffect(() => {
    interval.current && clearInterval(interval.current);
    interval.current = window.setInterval(() => {
      if (progress <= 0) {
        window.clearInterval(interval.current);
        hide !== undefined && hide(message.id);
        setTimeout(() => remove !== undefined && remove(message.id), 2000);
        return;
      }
      setProgress((val) => {
        // console.log(val);
        return val - refreshReate / (10 * message.timeout);
      });
    }, refreshReate);

    return () => clearInterval(interval.current);
  }, [progress, message.id, message.timeout, remove, hide]);

  switch (message.severity) {
    case Severities.info:
      messageClass = classes.infoBackground;
      icon = faInfoCircle;
      break;
    case Severities.success:
      messageClass = classes.successBackground;
      icon = faCheckCircle;
      break;
    case Severities.warning:
      messageClass = classes.warningBackground;
      icon = faExclamationCircle;
      break;
    default:
      messageClass = classes.errorBackground;
      icon = faTimesCircle;
      break;
  }

  return (
    <div id={message.text} {...props} className={`${classes.messageSpacing} ${messageClass}`}>
      <IconButton
        id="CloseMessage"
        onClick={() => remove !== undefined && remove(message.id)}
        style={{ color: 'white', position: 'absolute', top: 0, right: 0 }}
      >
        <FontAwesomeIcon size="xs" icon={faTimes} />
      </IconButton>
      <FontAwesomeIcon className={classes.messageIconPosition} size="3x" icon={icon} />
      <div className={classes.messageTimeoutIndicator} style={{ width: `${progress}%` }} />

      <div style={{ padding: '0 0 0 58px' }}>
        <Typography variant="h5" style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
          {Severities[message.severity]}
        </Typography>
        {message.text}
      </div>
    </div>
  );
};
