import chroma from 'chroma-js';
import React, { Dispatch, useCallback } from 'react';
import { SetStateAction } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import _ from 'lodash';
import { useCustomers } from 'hooks/useCustomers';

export enum ColoringMode {
  Unicolor,
  User,
  LastVisit,
}

export type MarkerColorType = {
  mode: ColoringMode;
  setMode?: Dispatch<SetStateAction<ColoringMode>>;
  coloringLevels: number[];

  setColoringLevels?: Dispatch<SetStateAction<number[]>>;
  getMarkerColor: () => Record<string, unknown>;
};

export const MarkerColorContext = React.createContext<MarkerColorType>({
  mode: ColoringMode.Unicolor,
  coloringLevels: [100, 200],
  getMarkerColor: () => ({}),
});

export const useMarkerColor = () => {
  return useContext(MarkerColorContext);
};

export const MarkerColorProvider = ({ children }: { children: React.ReactNode }) => {
  const [mode, setMode] = useState(ColoringMode.Unicolor);
  const [coloringLevels, setColoringLevels] = useState([100, 200]);

  const { data } = useCustomers();
  const customers = data?.customers || [];
  const userIds: number[] = _.keys(_.groupBy(customers, (d) => d.user_id || 0)).map((d) => Number(d));

  const getMarkerColor = useCallback(() => {
    switch (mode) {
      case ColoringMode.Unicolor:
        return {
          'icon-color': '#368cbf',
        };
      case ColoringMode.User: {
        const scale = chroma.scale(['green', '#0060aa', 'orange', 'purple']).domain([0, userIds?.length || 1]);
        const values = _.flatten(userIds?.map((d, i) => [d, scale(i).hex()]));
        return {
          'icon-color': ['step', ['get', 'userId'], 'Black', ...values],
        };
      }
      case ColoringMode.LastVisit: {
        return {
          'icon-color': [
            'interpolate',
            ['linear'],
            ['get', 'daysFromLastVisit'],
            -0.1,
            'purple',
            -0.0001,
            'green',
            coloringLevels[0],
            'yellow',
            coloringLevels[1],
            'red',
          ],
        };
      }
    }
  }, [mode, userIds, coloringLevels]);

  return (
    <MarkerColorContext.Provider value={{ mode, setMode, coloringLevels, setColoringLevels, getMarkerColor }}>
      {children}
    </MarkerColorContext.Provider>
  );
};
