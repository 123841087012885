import { MutationFunctionOptions, useMutation } from '@apollo/client';
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useMessages } from './MessageContext';
import { useTranslation } from 'react-i18next';
import React from 'react';

/**
 * To be accessed by external input
 */
type MutationInput = {
  gql: any;
  options: any;
};

/**
 * The used type
 */
export type MutationType = MutationInput & {
  id: number;
  gql: any;
  options: any;
  done: boolean;
  label?: string;
  created: Date;
};

type SyncContextType = {
  online: boolean;
  mutations: MutationType[];
  enqueueMutation: (mutation: MutationInput, label?: string) => void;
  openMutations: number;
  shouldDisplayWarning: boolean;
  hideWarning: () => void;
  trySync: () => void;
  removeMutation?: (id: number) => void;
};

const SyncContext = createContext<SyncContextType>({
  online: true,
  mutations: [],
  enqueueMutation: (mutation, label) => {},
  openMutations: 0,
  shouldDisplayWarning: false,
  hideWarning: () => {},
  trySync: () => {},
});

export const useSyncContext = () => useContext<SyncContextType>(SyncContext);

export const SyncContextProvider = ({ children }: { children: ReactNode }) => {
  const [online, setOnline] = useState(window.navigator.onLine);
  const [mutations, setMutations] = useState<MutationType[]>([]);
  const [shouldDisplayWarning, setShouldDisplayWarning] = useState(false);
  const { info, error } = useMessages();
  const { t } = useTranslation();

  const enqueueMutation = useCallback(
    (mutation: MutationInput, label?: string) => {
      setMutations((mutations: MutationType[]) => [
        ...mutations,
        { ...mutation, id: (_.maxBy(mutations, 'id')?.id || 0) + 1, done: false, label, created: new Date() },
      ]);
    },
    [setMutations]
  );

  const removeMutation = useCallback(
    (id: number) => {
      setMutations([...mutations.filter((d) => d.id !== id)]);
    },
    [setMutations, mutations]
  );

  const markMutationAsDone = useCallback(
    (id: number) =>
      setMutations((mutations) =>
        mutations.map((mutation) => {
          if (mutation.id === id) {
            return { ...mutation, done: true };
          }
          return mutation;
        })
      ),
    [setMutations]
  );

  const deleteDone = useCallback(
    () => setMutations((mutations) => [...mutations.filter((d) => !d.done)]),
    [setMutations]
  );

  const trySync = useCallback(async () => {
    if (!online) {
      info(t('CantSyncBeacuseOffline'));
      return;
    }

    for (const it of mutations.filter((d) => !d.done)) {
      try {
        // await client.mutate({ mutation: it.gql, ...it.options });
      } catch (err) {
        error(t('Error while syncing') + JSON.stringify(err));
        return;
      }
      markMutationAsDone(it.id);
    }
    deleteDone();
  }, [online, mutations, info, error, deleteDone, markMutationAsDone, t]);

  /**
   * When we switch online we need to display a warning to the user in case there
   * are unfinished syncs
   */
  const switchOnline = useCallback(async () => {
    if (mutations.filter((d) => !d.done).length > 0) {
      setShouldDisplayWarning(true);
    }
    setOnline(true);
  }, [mutations, setOnline]);

  /**
   * wire the online offline events...
   */
  useEffect(() => {
    const setOff = () => setOnline(false);

    window.addEventListener('online', switchOnline);
    window.addEventListener('offline', setOff);
    return () => {
      window.removeEventListener('online', switchOnline);
      window.removeEventListener('offline', setOff);
    };
  }, [setOnline, switchOnline]);

  return (
    <SyncContext.Provider
      value={{
        online,
        mutations,
        enqueueMutation,
        openMutations: mutations.filter((mutation) => !mutation.done).length,
        shouldDisplayWarning,
        hideWarning: () => setShouldDisplayWarning(false),
        trySync,
        removeMutation,
      }}
    >
      {children}
    </SyncContext.Provider>
  );
};

export const useSyncingMutation = (gql: any, label?: string) => {
  const { online, enqueueMutation } = useSyncContext();
  const mutation = useMutation(gql);
  const { info, error, success } = useMessages();
  const { t } = useTranslation();

  if (!online) {
    const enqueue = async (options?: MutationFunctionOptions<any, Record<string, any>> | undefined) => {
      enqueueMutation({ gql, options }, label);
      info(t('YouAreOfflineMutationWasQueued'));
    };
    return [enqueue];
  }

  const newMutation = async (props: any) => {
    try {
      await mutation[0](props);
      success(t('Successfully executed ') + label);
    } catch (ex) {
      console.error(ex);
      error(t('Could not execute ') + JSON.stringify(ex));
    }
  };

  return [newMutation];
};
