import React from 'react';

import { QuestionMultiSelect } from './QuestionMultiSelect';
import { QuestionSelect } from './QuestionSelect';
import { QuestionNumber } from './QuestionNumber';
import { QuestionDatePerProduct } from './QuestionDatePerProduct';
import { GetVisitQuery } from 'generated/graphql';
import { ArrayElement } from 'utils/ArrayElement';

export const QuestionItem = ({
  formik,
  question,
}: {
  formik: any;
  question: ArrayElement<GetVisitQuery['question']>;
}) => {
  return (
    <div style={{ marginTop: '1em' }}>
      <QuestionNumber question={question} formik={formik} />
      <QuestionSelect question={question} formik={formik} />
      <QuestionMultiSelect question={question} formik={formik} />
      {question.type === 'date_per_product' && <QuestionDatePerProduct question={question} formik={formik} />}
    </div>
  );
};
