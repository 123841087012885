import { faLock } from '@fortawesome/free-solid-svg-icons';
import { Typography, Button } from '@mui/material';
import { useFormik } from 'formik';
import { useStyles } from 'hooks/useStyles';
import { t } from 'i18next';
import React, { useState } from 'react';
import { FormikTextField } from '../customer/FormikTextField';
import { VIcon } from '../../components/VIcon';
import _ from 'lodash';
import { useMessages } from 'context/MessageContext';
import { FormikSelect } from 'pages/customer/FormikSelect';
import { ChangePasswordDialog } from './ChangePasswordDialog';
import axios from 'axios';
import { GetUserQuery, useUpdateUserMutation } from 'generated/graphql';

export const UserEdit = ({
  user,
  onClose,
  roles,
}: {
  user: NonNullable<GetUserQuery['users_by_pk']>; // GetUser_users_by_pk;
  onClose: () => void;
  roles?: GetUserQuery['role']; // GetUser_role[];
}) => {
  const [updateUser] = useUpdateUserMutation();
  const { success, error } = useMessages();
  const c = useStyles();
  const f = useFormik({
    initialValues: _.pick(user, ['login', 'firstname', 'lastname', 'role_id', 'telephone', 'email']),
    onSubmit: async (vals) => {
      try {
        let { email, ...rest } = vals;
        if (email && email.length && email !== user.email) {
          await axios.post(
            '/api/change-mail',
            { userId: user.id, newMail: email },
            {
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            }
          );
          //console.log(a);
        }

        await updateUser({ variables: { id: user.id, obj: rest } });
        success(t('Saved'));
        // onClose();
      } catch (err) {
        error(t('Error Saving'));
        console.error(err);
      }
    },
  });
  const [userId, setUserId] = useState(0);
  return (
    <React.Fragment>
      <ChangePasswordDialog userId={userId} onClose={() => setUserId(0)} />
      <form onSubmit={f.handleSubmit}>
        <Typography variant="overline">{`id: ${user.id}`}</Typography>
        <FormikTextField formik={f} name="login" label={t('login')} />
        <FormikTextField formik={f} name="email" label={t('eMail')} />
        {user.email_new && (
          <div style={{ margin: '1em 0' }}>
            <Typography>{t('userEdit.newEmailToBeConfirmed', { email: user.email_new })}</Typography>
          </div>
        )}
        <FormikTextField formik={f} name="telephone" label={t('telephone')} />

        <FormikTextField formik={f} name="firstname" label={t('firstname')} />
        <FormikTextField formik={f} name="lastname" label={t('lastname')} />
        <Button
          className={c.formButton}
          style={{ marginTop: '10px' }}
          fullWidth
          variant="outlined"
          startIcon={<VIcon icon={faLock} />}
          onClick={() => setUserId(user.id)}
        >
          {t('change password')}
        </Button>
        <FormikSelect formik={f} name="role_id" label="role" options={roles || []} />
        <div style={{ display: 'flex' }}>
          <Button style={{ marginTop: '10px' }} variant="contained" type="reset" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <div style={{ flex: '1' }} />
          <Button style={{ marginTop: '10px' }} variant="contained" type="submit">
            {t('Save')}
          </Button>
        </div>
      </form>
    </React.Fragment>
  );
};
