import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  SvgIcon,
  Dialog,
  LinearProgress,
  DialogTitle,
  DialogContent,
  Pagination,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TitleDisplay } from '../customer/TitleDisplay';
import _ from 'lodash';
import InputDialog from 'components/dialog/InputDialog';
import { UserEdit } from './UserEdit';

import { useMessages } from 'context/MessageContext';
import { GetUsersDocument, useCreateUserMutation, useGetUserQuery, useGetUsersQuery } from 'generated/graphql';
//
export const UserSettings = () => {
  const { data, loading } = useGetUsersQuery();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const users = data?.users;
  const { error } = useMessages();

  const [userId, setUserId] = useState<number>(0);
  const { data: dataUser, error: errorUser } = useGetUserQuery({
    variables: { id: userId },
  });
  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
  const [createUser] = useCreateUserMutation();

  const nUsers = 5;
  if (!users) {
    return <div>{t('No Users')}</div>;
  }

  const filterdUsers = users.filter((d) =>
    search.length === 0 ? true : d?.login?.toLowerCase().includes(search.toLowerCase())
  );

  if (errorUser) {
    console.error(errorUser);
  }

  return (
    <React.Fragment>
      {dataUser?.users_by_pk && (
        <Dialog open={Boolean(userId)} onClose={() => setUserId(0)}>
          <DialogTitle> {t('Edit User')}</DialogTitle>
          <DialogContent>
            <UserEdit user={dataUser.users_by_pk} onClose={() => setUserId(0)} roles={dataUser.role} />
          </DialogContent>
        </Dialog>
      )}
      {loading && <LinearProgress />}
      <InputDialog
        id="CreateUserDialog"
        isOpen={addUserDialogOpen}
        title={t('Login Name')}
        text={t('Please give a login name for the new user.')}
        yesAction={async (val) => {
          try {
            const { data } = await createUser({
              variables: { login: val },
              refetchQueries: [{ query: GetUsersDocument }],
            });
            data?.insert_users_one?.id && setUserId(data?.insert_users_one?.id);
            setAddUserDialogOpen(false);
          } catch (e) {
            error(t('user.errorCreating'));
          }
        }}
        noAction={() => setAddUserDialogOpen(false)}
      />
      <TitleDisplay title={`Users (${users.length})`} showAdd={true} onClick={() => setAddUserDialogOpen(true)} />
      <TextField
        fullWidth
        style={{ marginTop: '10px' }}
        value={search}
        autoComplete="off"
        onChange={(e) => {
          setPage(1);
          setSearch(e.target.value);
        }}
        label={t('Search')}
      />
      <List>
        {_.take(_.drop(filterdUsers, nUsers * (page - 1)), nUsers).map((u, i) => {
          return (
            <ListItem key={i}>
              <ListItemButton onClick={() => setUserId(u.id)}>
                <ListItemAvatar>
                  <Avatar>
                    <SvgIcon>
                      <FontAwesomeIcon icon={faUser} />
                    </SvgIcon>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`id: ${u.id} | ${u.login}`}
                  secondary={
                    <span>
                      <span> {`${u.firstname} ${u.lastname}`}</span> <br />
                      <span> {`${u.role?.name}`}</span>
                    </span>
                  }
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      <Pagination count={Math.ceil(filterdUsers.length / 5)} page={page} onChange={(e, p) => setPage(p)} />
    </React.Fragment>
  );
};
