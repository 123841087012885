import { IconButton, SvgIcon, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import YesNoDialog from 'components/dialog/YesNoDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { differenceInMonths, format, parseISO } from 'date-fns';
import React from 'react';
import { GetPromotionsForCustomerQuery } from 'generated/graphql';
import { ArrayElement } from 'utils/ArrayElement';

export const PromotionItem = ({
  promotion,
  deletePromotion,
  openEdit,
}: {
  promotion: ArrayElement<GetPromotionsForCustomerQuery['promotions']>;
  deletePromotion?: () => void;
  openEdit?: () => void;
}) => {
  const [dialog, setDialog] = useState<boolean>(false);
  const { t } = useTranslation();
  return (
    <TableRow>
      <YesNoDialog
        title={t('Delete Promotion')}
        text={t('DeletePromotionText')}
        isOpen={dialog}
        noAction={() => setDialog(false)}
        yesAction={() => {
          deletePromotion && deletePromotion();
          setDialog(false);
        }}
      />
      <TableCell>
        <div style={{ display: 'flex' }}>
          {/* <IconButton size="small" onClick={() => setDialog(true)}>
            <SvgIcon>
              <FontAwesomeIcon icon={faTrash} />
            </SvgIcon>
          </IconButton> */}
          <IconButton size="small" style={{ marginLeft: '10px' }} onClick={openEdit}>
            <SvgIcon>
              <FontAwesomeIcon icon={faPen} />
            </SvgIcon>
          </IconButton>
        </div>
      </TableCell>
      {/* <TableCell>
        {promotion.promotion_type?.name !== 'Drink Activation' && promotion.type !== 'SecondDisplay' ? (
          <div>
            <div>Hahn 1: {promotion.additional_data?.product_1}</div>
            <div>Hahn 2: {promotion.additional_data?.product_2}</div>
          </div>
        ) : (
          (promotion.products || []).map((p, i) => <div key={p.product?.id}> {p.product?.name}</div>)
        )}
      </TableCell> */}
      <TableCell>{promotion.from && format(parseISO(promotion.from), 'dd.MM.yy')}</TableCell>
      <TableCell>{promotion.to && format(parseISO(promotion.to), 'dd.MM.yy')}</TableCell>
      <TableCell>{promotion.promotion_type?.name}</TableCell>
      <TableCell>
        {(differenceInMonths(parseISO(promotion.to), parseISO(promotion.from)) / 12).toFixed(2).toString()}
      </TableCell>
      <TableCell>
        {(
          ((promotion.units_per_year || 0) *
            differenceInMonths(parseISO(promotion.to), parseISO(promotion.from) || 0)) /
          12.0
        )
          .toFixed(2)
          .toString()}
      </TableCell>
      <TableCell>{promotion.total_cost}</TableCell>
      <TableCell>
        {Object.entries(promotion.additional_data || {}).map(([k, v], i) => (
          <div key={i}>
            {k} : {v}
          </div>
        ))}
      </TableCell>
    </TableRow>
  );
};
