import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

export const TitleDisplay: React.FC<{
  title: string;
  showAdd?: boolean;
  onClick?: () => void;
  to?: string;
}> = ({ title, showAdd, onClick, to }) => {
  const additionalProps = to ? { component: Link, to } : {};
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid lightgray',
        minHeight: '48px',
      }}
    >
      <Typography variant="h5" style={{ flex: 1 }}>
        {title}
      </Typography>
      {showAdd && (
        <IconButton id="AddButton" style={{ border: '1px solid lightgray' }} onClick={onClick} {...additionalProps}>
          <FontAwesomeIcon icon={faPlus} />
        </IconButton>
      )}
    </div>
  );
};
