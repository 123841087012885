import React, { ReactNode, Suspense, useEffect, useState } from 'react';
import * as ReactDOMClient from 'react-dom/client';
import 'index.css';
import App from 'App';
import reportWebVitals from './reportWebVitals';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { createClient } from 'utils/ApolloClient';
import { UserContextProvider } from 'context/UserContext';
import { MessageContextProvider } from 'context/MessageContext';
import { theme } from './theme';
import { I18nextProvider } from 'react-i18next';
import { CustomerFilterContextProvider } from 'context/CustomerFilterContext';
import { CustomerSelectionContextProvider } from 'context/CustomerSelectionContext';
import { SyncContextProvider } from 'context/SyncContext';
import { MarkerColorProvider } from 'context/MapColorContext';
import i18n from 'i18n';
import axios from 'axios';
import { PWAProvider } from './utils/PWAProvider';

const Loading = () => <div>Loading... </div>;

const ContextAggregator = ({ children }: { children: ReactNode }) => {
  const [uri, setUri] = useState<string | null>(null);
  const [ws, setWs] = useState<string | null>(null);

  useEffect(() => {
    const callApi = async () => {
      const {
        data: { uri, ws },
      } = await axios.get('/hasura-endpoints');
      setUri(uri);
      setWs(ws);
    };
    callApi();
  }, [uri, setUri, ws, setWs]);

  if (!uri || !ws) {
    return <Loading />;
  }

  return (
    <ApolloProvider client={createClient(uri, ws)}>
      <MessageContextProvider>
        <UserContextProvider>
          <SyncContextProvider>
            <CustomerFilterContextProvider>
              <MarkerColorProvider>
                <CustomerSelectionContextProvider>{children}</CustomerSelectionContextProvider>
              </MarkerColorProvider>
            </CustomerFilterContextProvider>
          </SyncContextProvider>
        </UserContextProvider>
      </MessageContextProvider>
    </ApolloProvider>
  );
};

const container = document.getElementById('root') as any;
const root = ReactDOMClient.createRoot(container);
root.render(
  <ThemeProvider theme={theme}>
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<Loading />}>
        <PWAProvider />
        <ContextAggregator>
          <Router>
            <CssBaseline />
            <App />
          </Router>
        </ContextAggregator>
      </Suspense>
    </I18nextProvider>
  </ThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register({
//   onUpdate: () => {
//   },
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
