import { setContext } from '@apollo/client/link/context';
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, NormalizedCacheObject, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { isProduction } from './isProduction';

export const createClient = (uri: string, ws: string): ApolloClient<NormalizedCacheObject> => {
  const httpLink: ApolloLink = createHttpLink({
    uri,
  });

  const wsLink = new WebSocketLink({
    uri: ws,
    options: {
      lazy: true,
      reconnect: true,

      connectionParams: async () => {
        const token = localStorage.getItem('token');
        return {
          headers: {
            Authorization: token ? `Bearer ${token}` : '',
          },
        };
      },
    },
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink
  );

  const authLink: ApolloLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token'); // return the headers to the context so httpLink can read them

    if (token) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      };
    }
    return {
      headers,
    };
  });

  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link: ApolloLink.from([authLink, splitLink]),

    cache: new InMemoryCache({
      typePolicies: {
        Customer: {
          fields: {
            tags: {
              merge(existing = [], incoming: any[]) {
                return [...incoming];
              },
            },
          },
        },
      },
    }),
    
    defaultOptions: isProduction()
      ? undefined
      : {
          watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
          },
          query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
          },
        },
  });
  return client;
};
