import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => {
  const ContentPadding = 20;
  const MaxWidth = 1600;
  const toolbar: any = theme.mixins.toolbar || {};

  return {
    appBarSpacer: toolbar,

    root: {
      display: 'flex',
    },

    content: {
      flexGrow: 1,
      maxWidth: '1600px',
      // background: 'lightgray',
      padding: `${ContentPadding}px`,
      margin: 'auto',
      // eslint-disable-next-line
      ['@media (min-width:0px) and (orientation: landscape)']: {
        minHeight: `calc(100vh - ${toolbar['@media (min-width:0px) and (orientation: landscape)']?.minHeight}px)`,
        height: `calc(100vh - ${toolbar['@media (min-width:0px) and (orientation: landscape)']?.minHeight}px)`,
      },
      // eslint-disable-next-line
      ['@media (min-width:600px)']: {
        minHeight: `calc(100vh - ${toolbar['@media (min-width:600px)']?.minHeight}px)`,
        height: `calc(100vh - ${toolbar['@media (min-width:600px)']?.minHeight}px)`,
      },
      minHeight: `calc(100vh - ${toolbar.minHeight}px)`,
      height: `calc(100vh - ${toolbar.minHeight}px)`,
    },

    /**
     * AppBar
     */
    appBar: {
      background: `linear-gradient(45deg, ${theme.palette.secondary.main} 20%, ${theme.palette.primary.main} 90%) `, // theme.palette.secondary.main
    },

    /**
     * Map
     */
    map: {
      // eslint-disable-next-line
      ['@media (min-width:0px) and (orientation: landscape)']: {
        height: `calc(100vh - ${toolbar['@media (min-width:0px) and (orientation: landscape)']?.minHeight}px)`,
      },
      // eslint-disable-next-line
      ['@media (min-width:600px)']: {
        height: `calc(100vh - ${toolbar['@media (min-width:600px)']?.minHeight}px)`,
      },

      height: `calc(100vh - ${toolbar.minHeight}px)`,
      width: '100vw',
      maxWidth: `${MaxWidth}px`,
      margin: `-${ContentPadding}px`,
      position: 'relative',
    },

    mapControlPanel: {
      position: 'absolute',
      top: '5px',
      right: '5px',
      borderRadius: '10px',
      padding: '5px',
    },

    /** Messages Stuff */
    messageSpacing: {
      borderRadius: '5px',
      minWidth: '300px',
      maxWidth: '500px',
      minHeight: '80px',
      marginTop: '7px',
      padding: '20px',
      paddingBottom: '25px',
      color: 'White',
      position: 'relative',
    },
    messageIconPosition: {
      margin: 0,
      position: 'absolute',
      top: '50%',
      msTransform: 'translateY(-50%)',
      transform: 'translateY(-50%)',
    },
    messageTimeoutIndicator: {
      background: 'rgba(255, 255, 255, 0.4)',
      position: 'absolute',
      height: '7px',
      bottom: '0px',
      left: '0px',
      width: '80%',
    },
    errorBackground: {
      background: theme.palette.error.main,
    },
    successBackground: {
      background: theme.palette.success.main,
    },
    warningBackground: {
      background: theme.palette.warning.main,
    },
    infoBackground: {
      background: theme.palette.info.main,
    },

    errorColor: {
      color: theme.palette.error.main,
    },
    successColor: {
      color: theme.palette.success.main,
    },
    warningColor: {
      color: theme.palette.warning.main,
    },
    infoColor: {
      color: theme.palette.info.main,
    },

    loginElemement: {
      marginTop: '10px',
      width: '100%',
    },

    formButton: {
      marginTop: '10px',
      padding: '16.5px 14px',
    },
  };
});
