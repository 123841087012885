

export const checkAccess = (obj: any, keys: string[]) => {
  const kkk = Object.keys(obj);
  // console.log(kkk, keys);
  for (let k of keys) {
    const m = kkk.find((d) => d.toLowerCase() === k.toLowerCase());
    if (m) {
      return m; 
    }
  }
  return undefined;
};
