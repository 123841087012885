import { faCheckCircle, faExclamationTriangle, faSync, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  SvgIcon,
  Typography,
} from '@mui/material';
import { useSyncContext, MutationType } from 'context/SyncContext';
import { useStyles } from 'hooks/useStyles';
import { useTranslation } from 'react-i18next';

export const SyncDisplay = () => {
  const { mutations, trySync } = useSyncContext();
  const { t } = useTranslation();
  return (
    <div>
      <Typography variant="h5">
        {t('Sync')}
        <IconButton onClick={trySync}>
          <SvgIcon>
            <FontAwesomeIcon icon={faSync} />
          </SvgIcon>
        </IconButton>
      </Typography>

      <List>
        {mutations.map((d, i) => (
          <SyncItem key={i} sync={d} />
        ))}
      </List>
    </div>
  );
};

const SyncItem = ({ sync }: { sync: MutationType }) => {
  const c = useStyles();
  const { removeMutation } = useSyncContext();
  return (
    <ListItem>
      <ListItemIcon>
        <SvgIcon>
          <FontAwesomeIcon
            className={sync.done ? c.successColor : c.errorColor}
            icon={sync.done ? faCheckCircle : faExclamationTriangle}
           />
        </SvgIcon>
      </ListItemIcon>
      <ListItemText>
        <div>{`Sync-ID: ${sync.id}`}</div>
        <div>{`Label: ${sync.label}`}</div>
      </ListItemText>
      <ListItemSecondaryAction>
        <IconButton edge="end" onClick={() => removeMutation !== undefined && removeMutation(sync.id)}>
          <SvgIcon>
            <FontAwesomeIcon icon={faTimes} />
          </SvgIcon>
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
