import { AddressObject } from "types/AddressObject";


export const shapeAddressObject = (obj: any): AddressObject => ({
  number: obj.house_number,
  road: obj.road || obj.pedestrian,
  city: obj.city || obj.county || obj.town || obj.village || obj.hamlet || obj.suburb,
  postcode: obj.postcode,
  country_code: obj.country_code,
});
