import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';
// import axios from 'axios';
// import { useInterval } from './useInterval';
// import v from 'version.json';

export const PWAProvider = ({ children }: any) => {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
    console.log('ON UPDATE WAS CALLED ', registration.waiting);
  };

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    window.location.reload();
  };

  useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
  }, []);

  return (
    <div>
      {showReload && (
        <Dialog open={true}>
          <DialogTitle>Update Available</DialogTitle>
          <DialogContent>
            <Button fullWidth variant="contained" color="success" onClick={reloadPage}>
              Install Update
            </Button>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};
