import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { VPDF } from '../../components/VPDF';
import { VImage } from '../../components/VImage';
import { GetDocumentsQuery } from 'generated/graphql';
import { ArrayElement } from 'utils/ArrayElement';

// GetDocuments_document_uploads
export const PreviewComponent = ({
  upload,
  style,
}: {
  upload: ArrayElement<ArrayElement<GetDocumentsQuery['document']>['uploads']>;

  style?: any;
}) => {
  const { t } = useTranslation();
  let inner = <Typography>{t('document.noPreview')}</Typography>;
  if (upload.type?.includes('application/pdf')) {
    inner = <VPDF src={upload.path || ''} />;
  }
  if (upload.type?.includes('image')) {
    inner = <VImage src={upload.path || ''} style={{ width: '100%' }} />;
  }
  return <div style={{ width: '200px', height: '200px', margin: 'auto', overflow: 'hidden', ...style }}>{inner}</div>;
};
