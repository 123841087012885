import { useFetch } from 'hooks/useFetch';

// var jwt = require('jsonwebtoken');

export const Home = () => {
  // const { id: userId } = useUser();
  // const { data: embedding } = useQuery<GetEmbedding>(GetEmbeddingQuery);

  const { data: embedding } = useFetch('/api/embedding');

  // const { data, loading, error } = useQuery<GetHomeStatistics, GetHomeStatisticsVariables>(GetHomeStatsQuery, {
  //   variables: {
  //     userId: userId || 0,
  //     d0: startOfWeek(addWeeks(new Date(), -1), { weekStartsOn: 1 }),
  //     d1: startOfWeek(addWeeks(new Date(), 0), { weekStartsOn: 1 }),
  //     d2: startOfWeek(addWeeks(new Date(), 1), { weekStartsOn: 1 }),
  //     d3: startOfWeek(addWeeks(new Date(), 2), { weekStartsOn: 1 }),
  //   },
  // });

  // console.log(embedding);
  // if (error) {
  //   console.error(error);
  // }

  return (
    <div>
      {/* {loading && <LinearProgress />} */}
      {Array.isArray(embedding) &&
        embedding?.map((em: any) => (
          <iframe
            key={em.id}
            title="Dashboard"
            src={em.iframeUrl}
            width={em.width}
            height={em.height}
            frameBorder="0"
          />
        ))}

      {/* <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell> </TableCell>
              <TableCell>Wk {getWeek(addWeeks(new Date(), -1), { weekStartsOn: 1 })}</TableCell>
              <TableCell>Wk {getWeek(addWeeks(new Date(), 0), { weekStartsOn: 1 })}</TableCell>
              <TableCell>Wk {getWeek(addWeeks(new Date(), 1), { weekStartsOn: 1 })}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{t('Scheduled Meetings')}</TableCell>
              <TableCell>{data?.lastWeekVisits.aggregate?.count}</TableCell>
              <TableCell>{data?.thisWeekVisits.aggregate?.count}</TableCell>
              <TableCell>{data?.nextWeekVisits.aggregate?.count}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('Finished Meetings')}</TableCell>
              <TableCell>{data?.lastWeekVisitsTookPlace.aggregate?.count}</TableCell>
              <TableCell>{data?.lastWeekVisitsTookPlace.aggregate?.count}</TableCell>
              <TableCell>{data?.lastWeekVisitsTookPlace.aggregate?.count}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('Listed Orders')}</TableCell>
              <TableCell>{data?.lastWeekTransferOrders.aggregate?.count}</TableCell>
              <TableCell>{data?.thisWeekTransferOrders.aggregate?.count}</TableCell>
              <TableCell>{data?.nextWeekTransferOrders.aggregate?.count}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer> */}
    </div>
  );
};
