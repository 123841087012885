import React from 'react';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { isArray } from 'lodash';
import { Answer } from 'types/Answer';
import { ArrayElement } from 'utils/ArrayElement';
import { GetVisitQuery } from 'generated/graphql';

export const QuestionMultiSelect = ({
  formik,
  question,
}: {
  formik: any;
  question: ArrayElement<GetVisitQuery['question']>;
}) => {
  if (question.type !== 'multiselect') {
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={8} style={{ margin: 'auto' }}>
        <Typography>{question.text}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        {question.options.map((o, i) => {
          const me = formik.values.answers.find((d: Answer) => d.question_id === question.id);
          return (
            <FormControlLabel
              key={i}
              checked={(me.answer.values || []).indexOf(o.id) >= 0}
              control={
                <Checkbox
                  onChange={(e) => {
                    let newValues = me.answer.values || [];
                    if (!isArray(newValues)) {
                      newValues = [];
                    }
                    newValues = newValues.filter((a: number) => a !== o.id);

                    if (e.target.checked) {
                      newValues = [...newValues, o.id];
                    }

                    formik.setValues({
                      ...formik.values,
                      answers: [
                        ...formik.values.answers.filter((d: Answer) => d.question_id !== question.id),
                        { question_id: question.id, answer: { values: newValues } },
                      ],
                    });
                  }}
                />
              }
              label={o.text || ''}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};
