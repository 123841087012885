import { Button, Checkbox, LinearProgress, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import _ from 'lodash';
import { useUser } from 'context/UserContext';
import { Fragment, useEffect } from 'react';
import chroma from 'chroma-js';
import { UserSelect } from './VCalendar';
import { useTranslation } from 'react-i18next';
import { faMedal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetUsersQuery } from 'generated/graphql';

export const CalendarUserFilter = ({
  selectedUsers,
  setSelectedUsers,
}: {
  selectedUsers: UserSelect[];
  setSelectedUsers: (arg: UserSelect[]) => void;
}) => {
  const { data, loading } = useGetUsersQuery();
  const { id: myId } = useUser();
  const { t } = useTranslation();
  const s = chroma.scale(['green', '#000060aa', 'orange', 'purple']).domain([0, data?.users?.length || 1]);

  /**
   * Setting initial color for user
   * */
  useEffect(() => {
    if (data?.users === undefined || data?.users === null) {
      return;
    }
    let idx = _.sortBy(data.users, (user) => user.id).findIndex((d) => d.id === myId);
    setSelectedUsers([{ id: myId || 0, color: s(idx).hex() }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelectedUsers, myId, data?.users?.length]);

  if (loading) {
    return <LinearProgress />;
  }
  if (data?.users === undefined || data?.users === null) {
    return null;
  }

  return (
    <Fragment>
      <Button
        onClick={() => setSelectedUsers([{ id: myId || 0, color: s(0).hex() }])}
        startIcon={<FontAwesomeIcon icon={faMedal} />}
      >
        {t('Only Me')}
      </Button>
      <Button
        onClick={() => setSelectedUsers((data?.users || []).map((u, idx) => ({ id: u.id || 0, color: s(idx).hex() })))}
        startIcon={<FontAwesomeIcon icon={faMedal} />}
      >
        {t('Everyone')}
      </Button>
      <List style={{ paddingRight: '20px' }}>
        {_.sortBy(data.users, (user) => user.id).map((user, idx) => {
          const uidx = _.findIndex(selectedUsers, (d) => d.id === user.id);

          return (
            <ListItem key={idx} style={{ background: uidx >= 0 ? s(idx).hex() : 'transparent' }}>
              <ListItemIcon>
                <Checkbox
                  onChange={() => {
                    if (selectedUsers.find((d) => d.id === user.id)) {
                      // uncheck
                      setSelectedUsers(selectedUsers.filter((d) => d.id !== user.id));
                    } else {
                      setSelectedUsers([...selectedUsers, { id: user.id, color: s(idx).hex() }]);
                    }
                  }}
                  checked={Boolean(selectedUsers.find((d) => d.id === user.id))}
                />
              </ListItemIcon>
              <ListItemText>
                {user.id} - {user.login}
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
    </Fragment>
  );
};
