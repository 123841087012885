import React, { Fragment } from 'react';
import { QuestionItem } from './QuestionItem';
import { GetVisitQuery } from 'generated/graphql';

export const Questionaire = ({ formik, questions }: { formik: any; questions: GetVisitQuery['question'] }) => {
  return (
    <Fragment>
      {questions.map((q, i) => (
        <QuestionItem key={i} formik={formik} question={q} />
      ))}
    </Fragment>
  );
};
