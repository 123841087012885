import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faBars,
  faBoxes,
  faCalendar,
  faCashRegister,
  faCogs,
  faDollarSign,
  faFile,
  faFileImport,
  faHandshake,
  faHome,
  faMapMarked,
  faShoppingCart,
  faTags,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Divider, 
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon, 
  SwipeableDrawer, 
  Typography,
} from '@mui/material';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import v from 'version.json';
import { useStyles } from 'hooks/useStyles';
import { VIcon } from 'components/VIcon';

const SD:any = SwipeableDrawer 

export const VDrawer = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { hasRole } = useUser();
  const c = useStyles();

  return (
    <div>
      <IconButton id="MainMenu" color="inherit" onClick={() => setOpen(!open)}>
        <SvgIcon>
          <FontAwesomeIcon icon={faBars} />
        </SvgIcon>
      </IconButton>
      <SD open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
        <List
          subheader={
            <div className={c.appBar} style={{ padding: '0.5em 0.5em', color: 'white' }}>
              <div style={{ display: 'flex' }}>
                <IconButton onClick={() => setOpen(false)}>
                  <VIcon icon={faBars} color="white" />
                </IconButton>
                <Typography style={{ fontSize: '1.7em', fontWeight: 'bold' }}>Visiter</Typography>
              </div>
              <Typography style={{ marginLeft: '0.6em' }}>{v.version}</Typography>
            </div>
          }
        >
          <NavItem href="/" icon={faHome} text={t('Home')} />
          {hasRole && hasRole('user') && (
            <Fragment>
              {/* <NavItem href="/dashboard" icon={faChartLine} text={t('Dashboard')} /> */}
              <NavItem href="/customers" icon={faDollarSign} text={t('Customers')} />
              <NavItem href="/visits" icon={faHandshake} text={t('Visits')} />
              {/* <NavItem href="/contacts" icon={faUsers} text={t('Contacts')}></NavItem> */}
              <NavItem href="/calendar" icon={faCalendar} text={t('Calendar')} />
              <NavItem href="/map" icon={faMapMarked} text={t('Map')} />
              <NavItem href="/transfer-orders" icon={faShoppingCart} text={t('Transfer Orders')} />
              <NavItem href="/documents" icon={faFile} text={t('Documents')} />
            </Fragment>
          )}
          <Divider />
          {hasRole && hasRole('manager') && <NavItem href="/tags" icon={faTags} text={t('Tags')} />}
          {hasRole && hasRole('manager') && <NavItem href="/settings" icon={faCogs} text={t('Settings')} />}
          {hasRole && hasRole('manager') && <NavItem href="/products" icon={faBoxes} text={t('Products')} />}
          {hasRole && hasRole('manager') && (
            <NavItem href="/order-overview" icon={faCashRegister} text={t('navigation.orderOverview') + ' (β)'} />
          )}
          {hasRole && hasRole('manager') && (
            <NavItem href="/import-data" icon={faFileImport} text={t('Import Data') + ' (β)'} />
          )}
        </List>
      </SD>
    </div>
  );
};

const NavItem = ({ icon, href, text }: { icon: IconProp; href: string; text: string }) => {
  const h = useNavigate();
  return (
    <ListItem button onClick={() => h(href)}>
      <ListItemIcon>
        <SvgIcon>
          <FontAwesomeIcon icon={icon} />
        </SvgIcon>
      </ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </ListItem>
  );
};
