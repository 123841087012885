import { IconButton, InputAdornment, SvgIcon, TextField, TextFieldProps } from '@mui/material';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { useCallback, useEffect, useState } from 'react';
// import { debounce } from 'lodash';

export const FormikTextField = ({
  formik,
  name,
  label,
  icon,
  href,
  onAction,
  rows,
  extra,
}: {
  formik: any;
  name: string;
  label?: string;
  icon?: IconProp;
  href?: string;
  onAction?: (event: React.MouseEvent) => void;
  rows?: number;
  extra?: TextFieldProps;
}) => {
  // const [text, setText] = useState(formik.values[name] || '');

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // const debouncedValueChange = useCallback(
  //   debounce((text, vals) => {
  //     console.log('set', text, vals[name]);

  //     // dont set if original value is null and new is '' - if we dont do this, the save warning comes up.
  //     if ((vals[name] === undefined || vals[name] === null) && text === '') {
  //       return;
  //     }
  //     console.log('setting value', name, text);
  //     formik.setValues({ ...vals, [name]: text });
  //   }, 200),
  //   [debounce, name]
  // );

  // useEffect(() => {
  //   if (text !== formik.values[name]) {
  //     debouncedValueChange(text, formik.values);
  //   }
  // }, [debouncedValueChange, text, formik, name]);

  return (
    <TextField
      id={name}
      {...extra}
      style={{ marginTop: '10px' }}
      fullWidth
      name={name}
      label={label}
      value={formik.values[name] || ''}
      onChange={formik.handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      rows={rows}
      multiline={Boolean(rows)}
      InputProps={
        icon
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton href={href || ''} onClick={onAction}>
                    <SvgIcon>
                      <FontAwesomeIcon icon={icon} />
                    </SvgIcon>
                  </IconButton>
                </InputAdornment>
              ),
            }
          : undefined
      }
    />
  );
};
