import {
  faCheck,
  faCheckSquare,
  faMinusSquare,
  faPlusSquare,
  faSave,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  SvgIcon,
  Tab,
  Tabs,
} from '@mui/material';

import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import { useFormik } from 'formik';
import { FormikCheckbox } from 'pages/customer/FormikCheckbox';
import { FormikTextField } from 'pages/customer/FormikTextField';
import _ from 'lodash';
import { ApolloQueryResult } from '@apollo/client';

import { TitleDisplay } from 'pages/customer/TitleDisplay';
import { useUser } from 'context/UserContext';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { parseISO } from 'date-fns/esm';
import YesNoDialog from 'components/dialog/YesNoDialog';

import { format } from 'date-fns';
import { FormikSelect } from 'pages/customer/FormikSelect';
import { FormikMultiSelect } from 'pages/customer/FormikMultiSelect';
import * as Yup from 'yup';
import { useMessages } from 'context/MessageContext';
import { VIcon } from 'components/VIcon';
import { AddSupplierDialog } from './AddSupplierDialog';
import { VAvatar } from './VAvatar';
import { VDownloadLink } from 'components/VDownloadLink';
import { Box } from '@mui/system';
import {
  Exact,
  GetProductsDocument,
  GetProductsQuery,
  GetProductsWhereQuery,
  InputMaybe,
  Products_Bool_Exp,
  useCreateProductStorageMutation,
  useCreateStyleMutation,
  useDeleteProductMutation,
  useUpdateProductWithStyleMutation,
} from 'generated/graphql';
import { ArrayElement } from 'utils/ArrayElement';

export const ProductEdit = ({
  product,
  productTypes,
  productStyles,
  productGroups,
  availableSuppliers,
  refetch,
  close,
}: {
  product: ArrayElement<GetProductsQuery['products']>;
  productTypes: GetProductsQuery['product_type'];
  productStyles: GetProductsQuery['product_style'];
  productGroups: GetProductsQuery['product_group'];
  availableSuppliers: GetProductsQuery['suppliers'];
  refetch: (
    variables?:
      | Partial<
          Exact<{
            where?: InputMaybe<Products_Bool_Exp> | undefined;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<GetProductsWhereQuery>>;
  close?: () => void;
}) => {
  const { t } = useTranslation();
  const [showAddSupplier, setShowAddSupplier] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [preview, setPreview] = useState<any>(null);
  const [uploadStarted, setUploadStarted] = useState(false);
  const inpRef = useRef<any>(null);
  const [changesPresent, setChangesPresent] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const { error, success } = useMessages();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [createStyle] = useCreateStyleMutation();

  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
  };

  const [addStorageDialogOpen, setAddStorageDialogOpen] = useState(false);
  const initialValues = useMemo(
    () => ({
      ...product,
      product_style_ids: product.products_product_styles.map((d) => d.product_style_id),
      supplier_ids: product.suppliers.map((d) => d.supplier_id),
    }),
    [product]
  );

  const [updateProduct] = useUpdateProductWithStyleMutation();
  const [deleteProduct] = useDeleteProductMutation({
    refetchQueries: [{ query: GetProductsDocument }],
  });

  const avatarChangeHanlder = (event: any) => {
    setFile(event.target.files[0]);
    if (event.target.files[0]) {
      setPreview(URL.createObjectURL(event.target.files[0]));
    }
  };

  const ProductSchema = Yup.object().shape({
    name: Yup.string().nullable(),
    description: Yup.string().nullable(),
    listable: Yup.boolean().nullable(),
    orderable: Yup.boolean().nullable(),
    item_number: Yup.string().nullable(),
    count_per_unit: Yup.number().positive().integer().nullable().typeError('you must specify an integer'),
    volume_per_item: Yup.number().positive().nullable().typeError('you must specify a number'),
    is_multi_use: Yup.boolean().nullable(),
    unit_ean: Yup.string().nullable(),
    item_ean: Yup.string().nullable(),
    subunit_ean: Yup.string().nullable(),
    units_per_delivery: Yup.number().positive().integer().nullable().typeError('you must specify an integer'),
    layers_per_delivery: Yup.number().positive().integer().nullable().typeError('you must specify an integer'),
    units_per_layer: Yup.number().positive().integer().nullable().typeError('you must specify an integer'),
    weight_per_item: Yup.number().positive().nullable().typeError('you must specify a number'),
    delivery_length: Yup.number().positive().nullable().typeError('you must specify a number'),
    delivery_width: Yup.number().positive().nullable().typeError('you must specify a number'),
    ingredients: Yup.string().nullable(),
    allergenes: Yup.string().nullable(),
    volume_percent: Yup.number().positive().nullable().typeError('you must specify a number'),
    plato_degree: Yup.number().positive().nullable().typeError('you must specify a number'),
    durability_months: Yup.number().positive().nullable().typeError('you must specify a number'),
    deposit_total: Yup.number().positive().nullable().typeError('you must specify a number'),
    deposit_unit: Yup.number().positive().nullable().typeError('you must specify a number'),
    deposit_item: Yup.number().positive().nullable().typeError('you must specify a number'),
    netto: Yup.number().positive().nullable().typeError('you must specify a number'),
  });

  const f = useFormik({
    initialValues,
    onSubmit: async (vals, { setSubmitting }) => {
      try {
        const newvals = _.pick(vals, [
          'name',
          'description',
          'listable',
          'orderable',
          'item_number',
          'count_per_unit',
          'volume_per_item',
          'product_type_id',
          'is_multi_use',
          'unit_ean',
          'item_ean',
          'subunit_ean',
          'units_per_delivery',
          'layers_per_delivery',
          'units_per_layer',
          'weight_per_item',
          'delivery_length',
          'delivery_width',
          'ingredients',
          'allergenes',
          'volume_percent',
          'plato_degree',
          'durability_months',
          'deposit_total',
          'deposit_unit',
          'deposit_item',
          'netto',
          'single_item_type_id',
          'colorizer',
          'consumption_tax_product_code',
          'customs_tariff_number',
          'delivery_weight',
          'got_subunit',
          'subunit_type_id',
          'product_group_id',
        ]);

        const styles = vals.product_style_ids.map((d) => ({ product_id: product.id, product_style_id: d }));
        const suppliers = vals.supplier_ids.map((d) => ({ product_id: product.id, supplier_id: d }));

        await updateProduct({
          variables: {
            id: product.id,
            obj: _.mapValues(newvals, (v) => (v === '' ? null : v)) as any,
            styles,
            suppliers,
          },
        });
        success(t('saved'));
        console.log('saved product');
      } catch (err) {
        console.error(err);
        error(t('error while saving'));
      }
    },
    enableReinitialize: true,
    validationSchema: ProductSchema,
  });

  const storage = product.storage;

  useEffect(() => {
    setChangesPresent(!_.isEqual(initialValues, f.values));
  }, [initialValues, f.values]);

  const startUpload = useCallback(async () => {
    if (!file || uploadStarted) {
      return;
    }
    setUploadStarted(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', 'test');
    try {
      setUploadStarted(true);
      await Axios.post(`/api/upload-product-avater/${product?.id}`, formData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        // onUploadProgress: (progressEvent) => {
        //   // let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        //   // setProgress(progress);
        // },
      });
      await refetch();
      cancelUpload();
    } catch (error) {
      console.log('error while upload', error);
    }
  }, [file, uploadStarted, setUploadStarted, product?.id, refetch]);

  const cancelUpload = () => {
    setFile(null);
    setPreview(null);
    setUploadStarted(false);
  };

  const columns = [
    { id: 'id', label: 'id' },
    { id: 'date', label: t('time') },
    { id: 'action', label: t('action') },
    { id: 'amount', label: t('amount') },
    { id: 'user', label: t('user') },
  ];

  return (
    <React.Fragment>
      <AddSupplierDialog
        suppliers={availableSuppliers}
        alreadyListed={f.values.supplier_ids}
        open={showAddSupplier}
        onClose={() => setShowAddSupplier(false)}
        onSelect={(id) =>
          f.setValues({ ...f.values, supplier_ids: [...f.values.supplier_ids.filter((d) => d !== id), id] })
        }
      />
      <YesNoDialog
        isOpen={deleteDialogOpen}
        title={t('DeleteProductTitle')}
        text={t('DeleteProductText')}
        yesAction={async () => {
          close && close();
          await deleteProduct({ variables: { id: product.id } });
          refetch();
        }}
        noAction={() => setDeleteDialogOpen(false)}
      />
      <Dialog open={addStorageDialogOpen} onClose={() => setAddStorageDialogOpen(false)}>
        <DialogTitle>{t('Add Storage')}</DialogTitle>
        <DialogContent>
          <ProductStorageEdit product={product} close={() => setAddStorageDialogOpen(false)} />
        </DialogContent>
      </Dialog>
      <form onSubmit={f.handleSubmit}>
        {f.isSubmitting && (
          <div style={{ position: 'absolute', right: '1em', top: '0' }}>
            <CircularProgress />
          </div>
        )}
        <div style={{ position: 'absolute', right: '1em', top: '1em' }}>
          <Button onClick={() => close && close()}>{t('Cancel')}</Button>
          <Button type="submit" variant={changesPresent ? 'contained' : 'outlined'}>
            {t('Save')}
          </Button>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <div style={{ display: 'flex' }}>
              <VAvatar
                sx={{ width: 100, height: 100 }}
                onClick={() => inpRef.current.click()}
                alt={product.name || ''}
                src={preview || product.avatar_path || undefined}
              />
              <input
                type="file"
                ref={inpRef}
                accept="image/png, image/jpeg"
                style={{ visibility: 'hidden', width: '0px', height: '0px' }}
                name="file"
                onChange={avatarChangeHanlder}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {file && !uploadStarted && (
                  <React.Fragment>
                    <IconButton onClick={startUpload} style={{ margin: 'auto' }}>
                      <SvgIcon>
                        <FontAwesomeIcon icon={faCheck} />
                      </SvgIcon>
                    </IconButton>
                    <IconButton onClick={cancelUpload} style={{ margin: 'auto' }}>
                      <SvgIcon>
                        <FontAwesomeIcon icon={faTimes} />
                      </SvgIcon>
                    </IconButton>
                  </React.Fragment>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormikTextField label={t('Name')} formik={f} name={'name'} />
            <FormikSelect label={t('product.group')} formik={f} name="product_group_id" options={productGroups} />
            <FormikCheckbox label={t('Listable')} formik={f} name={'listable'} />
            <FormikCheckbox label={t('Orderable')} formik={f} name={'orderable'} />
            <VDownloadLink
              src={`/api/get-product-pass/${product.id}/0`}
              name={`${product.name}.pdf`}
              mime="application/pdf"
              label={t('Article Pass')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormikTextField label={t('product.Description')} formik={f} name={'description'} rows={4} />
          </Grid>
        </Grid>

        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="basic tabs example">
              <Tab label={t('product.General')} />
              <Tab label={t('product.Suppliers')} />
              <Tab label={t('product.Storage')} />
            </Tabs>
          </Box>
        </Box>
        {selectedTab === 1 && (
          <Fragment>
            <TitleDisplay
              title={t('product.Suppliers') as string}
              showAdd={true}
              onClick={() => setShowAddSupplier(true)}
            />
            <List>
              {availableSuppliers
                .filter((d) => _.includes(f.values.supplier_ids, d.id))
                .map((ps) => (
                  <ListItem key={ps.id}>
                    <ListItemText primary={ps.name} secondary={ps.address} />
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() =>
                          f.setValues({
                            ...f.values,
                            supplier_ids: [...f.values.supplier_ids.filter((d) => d !== ps.id)],
                          })
                        }
                      >
                        <VIcon icon={faTimes} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
            </List>
          </Fragment>
        )}
        {selectedTab === 0 && (
          <Fragment>
            <TitleDisplay title={t('product.Item')} />

            <Grid container columnSpacing={{ xs: 1 }}>
              <Grid item xs={6}>
                {/* <FormikTextField label={t('product.volume_per_item')} formik={f} name={'volume_per_item'} /> */}
                <FormikTextField label={t('product.item_ean')} formik={f} name={'item_ean'} />
                <div style={{ height: '1.0em' }} />
                <FormikTextField label={t('product.deposit_item')} formik={f} name={'deposit_item'} />
                <FormikCheckbox label={t('product.is_multi_use')} formik={f} name={'is_multi_use'} />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField label={t('product.weight_per_item')} formik={f} name={'weight_per_item'} />
                <FormikSelect
                  formik={f}
                  label={t('product.single_item_type')}
                  name="single_item_type_id"
                  options={productTypes || []}
                />
              </Grid>
            </Grid>

            <TitleDisplay title={t('product.Unit') as string} />

            <Grid container columnSpacing={{ xs: 1 }}>
              <Grid item xs={6}>
                <FormikTextField label={t('product.item_number')} formik={f} name={'item_number'} />
                <div style={{ height: '1.0em' }} />
                <FormikTextField label={t('product.unit_ean')} formik={f} name={'unit_ean'} />
                <FormikTextField label={t('product.deposit_unit')} formik={f} name={'deposit_unit'} />
                <FormikCheckbox formik={f} name={'got_subunit'} label={t('product.got_subunit')} />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField label={t('product.items_per_unit')} formik={f} name={'count_per_unit'} />
                <FormikSelect
                  formik={f}
                  label={t('product.product_type')}
                  name="product_type_id"
                  options={productTypes || []}
                />
                <FormikTextField label={t('product.deposit_total')} formik={f} name={'deposit_total'} />
                <FormikTextField label={t('product.netto')} formik={f} name={'netto'} />
              </Grid>
            </Grid>

            {f.values.got_subunit && (
              <Fragment>
                <TitleDisplay title={t('Subunit') as string} />

                <Grid container columnSpacing={{ xs: 1 }}>
                  <Grid item xs={6}>
                    <div style={{ height: '1.0em' }} />
                    <FormikTextField label={t('product.subunit_ean')} formik={f} name={'subunit_ean'} />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikSelect
                      formik={f}
                      label={t('product.product_type')}
                      name="subunit_type_id"
                      options={productTypes || []}
                    />
                  </Grid>
                </Grid>
              </Fragment>
            )}

            <TitleDisplay title={t('Delivery') as string} />

            <Grid container columnSpacing={{ xs: 1 }}>
              <Grid item xs={6}>
                <FormikTextField label={t('product.units_per_delivery')} formik={f} name={'units_per_delivery'} />
                <FormikTextField label={t('product.layers_per_delivery')} formik={f} name={'layers_per_delivery'} />
                <FormikTextField label={t('product.units_per_layer')} formik={f} name={'units_per_layer'} />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField label={t('product.delivery_length')} formik={f} name={'delivery_length'} />
                <FormikTextField label={t('product.delivery_width')} formik={f} name={'delivery_width'} />
                <FormikTextField label={t('product.delivery_weight')} formik={f} name={'delivery_weight'} />
              </Grid>
            </Grid>

            <TitleDisplay title={t('product.General') as string} />
            <FormikMultiSelect
              formik={f}
              label={t('product.style')}
              name="product_style_ids"
              creatable={true}
              options={productStyles.map((d) => ({ value: d.id, label: d.name || '' })) || []}
              createOption={async (name: string) => {
                // console.log('asdf', name);
                try {
                  const { data } = await createStyle({
                    variables: { styleName: name },
                  });
                  console.log(data);
                  refetch();
                  f.setValues({
                    ...f.values,
                    product_style_ids: [...f.values.product_style_ids, data?.insert_product_style_one?.id || 0],
                  });
                } catch (error) {
                  console.log(error);
                }
                // return { tag: data?.insert_tags_one };
              }}
            />

            <FormikTextField label={t('product.ingredients')} formik={f} name={'ingredients'} rows={4} />
            <FormikTextField label={t('product.allergenes')} formik={f} name={'allergenes'} rows={2} />

            <Grid container columnSpacing={{ xs: 1 }}>
              <Grid item xs={6}>
                <FormikTextField label={t('product.volume_percent')} formik={f} name={'volume_percent'} />
                <FormikTextField label={t('product.plato_degree')} formik={f} name={'plato_degree'} />
                <FormikTextField label={t('product.durability_months')} formik={f} name={'durability_months'} />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField
                  label={t('product.consumption_tax_product_code')}
                  formik={f}
                  name={'consumption_tax_product_code'}
                />
                <FormikTextField label={t('product.customs_tariff_number')} formik={f} name={'customs_tariff_number'} />
                <FormikCheckbox label={t('product.colorizer')} formik={f} name={'colorizer'} />
              </Grid>
            </Grid>
          </Fragment>
        )}
        {selectedTab === 2 && (
          <Fragment>
            <TitleDisplay title={t('product.Storage')} showAdd={true} onClick={() => setAddStorageDialogOpen(true)} />
            <div>
              {t('Available amount')}: {product.available_amount || 0}
            </div>
            {storage.length > 0 && (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={(column as any).align || 'left'}
                          style={{ minWidth: (column as any).minWidth || undefined }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.orderBy(storage, ['id'], ['desc']).map((row, i) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                          <TableCell>{row.id}</TableCell>
                          <TableCell>
                            {format(parseISO(row.date), 'dd.MM.yyyy')}
                            <br />
                            {format(parseISO(row.date), 'HH:mm')}
                          </TableCell>
                          <TableCell>
                            {row.action === 'change' && row.amount > 0 && (
                              <SvgIcon style={{ color: 'green' }}>
                                <FontAwesomeIcon icon={faPlusSquare} />
                              </SvgIcon>
                            )}
                            {row.action === 'change' && row.amount < 0 && (
                              <SvgIcon style={{ color: 'red' }}>
                                <FontAwesomeIcon icon={faMinusSquare} />
                              </SvgIcon>
                            )}

                            {row.action === 'check' && (
                              <SvgIcon style={{ color: 'gray' }}>
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </SvgIcon>
                            )}
                          </TableCell>
                          <TableCell>{row.amount}</TableCell>
                          <TableCell>{row.user.login}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Fragment>
        )}
      </form>

      <Button
        style={{ marginTop: '1em' }}
        color="error"
        variant="outlined"
        startIcon={
          <SvgIcon>
            <FontAwesomeIcon icon={faTrash} />
          </SvgIcon>
        }
        onClick={() => setDeleteDialogOpen(true)}
      >
        {t('Delete')}
      </Button>
    </React.Fragment>
  );
};

const ProductStorageEdit = ({
  product,
  close,
}: {
  product: ArrayElement<GetProductsQuery['products']>;
  close?: () => void;
}) => {
  const [createProductStorage] = useCreateProductStorageMutation({
    refetchQueries: [{ query: GetProductsDocument, variables: { where: { id: { _eq: product.id } } } }],
  });
  const { t } = useTranslation();
  const { id } = useUser();

  const f = useFormik({
    initialValues: {
      action: 'change',
      amount: 0,
    },
    onSubmit: async (vals) => {
      try {
        await createProductStorage({ variables: { obj: { ...vals, user_id: id, products_id: product.id } } });
        close && close();
      } catch (error) {
        console.error(error);
      }
    },
  });

  return (
    <form onSubmit={f.handleSubmit} autoComplete="off">
      <FormikTextField extra={{ type: 'number' }} formik={f} name="amount" label={t('amount')} />

      <Button
        variant="outlined"
        color="inherit"
        startIcon={
          <SvgIcon>
            <FontAwesomeIcon icon={faTimes} />
          </SvgIcon>
        }
        onClick={close}
      >
        {t('Close')}
      </Button>
      <Button
        type="submit"
        variant="outlined"
        startIcon={
          <SvgIcon>
            <FontAwesomeIcon icon={faSave} />
          </SvgIcon>
        }
      >
        {t('Save')}
      </Button>
    </form>
  );
};
