import React, { Fragment, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
// import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FormikProductSelector } from './FormikProductSelector';
import { pick } from 'lodash';
import { FormikDatePicker } from '../transferOrders/FormikDatePicker';
import { FormikSelect } from '../FormikSelect';
import { FormikTextField } from '../FormikTextField';
import YesNoDialog from 'components/dialog/YesNoDialog';
import { VisiterDropzone } from '../../../components/dropzone/VisiterDropzone';
import { PreviewComponent } from 'pages/documents/PreviewComponent';
import { format, parseISO } from 'date-fns';
import { humanFileSize } from 'pages/documents/humanFileSize';
import { VDownloadLink } from 'components/VDownloadLink';
import { VIcon } from 'components/VIcon';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useMessages } from 'context/MessageContext';
import {
  GetPromotionsForCustomerDocument,
  GetPromotionsForCustomerQuery,
  useCreatePromotionMutation,
  useDeletePromotionMutation,
  useGetUploadsQuery,
  useUpdatePromotionMutation,
} from 'generated/graphql';
import { ArrayElement } from 'utils/ArrayElement';

export const EditPromotionDialog = ({
  isOpen,
  close,
  customerId,
  editPromotion,
  promotionTypes,
  setEditPromotionId,
}: {
  isOpen: boolean;
  close: () => void;
  customerId: number;
  editPromotion?: ArrayElement<GetPromotionsForCustomerQuery['promotions']>;
  promotionTypes: GetPromotionsForCustomerQuery['promotion_type'];
  setEditPromotionId: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [confirmUploadDelete, setConfirmUploadDelete] = useState(0);
  const { t } = useTranslation();
  const { error, success } = useMessages();
  const [autoClose, setAutoClose] = useState(true);
  const {
    data,
    error: FetchError,
    loading,
    refetch,
  } = useGetUploadsQuery({
    variables: { where: { promotion_id: { _eq: editPromotion?.id || 0 } } },
  });

  if (FetchError) {
    console.error(FetchError);
  }
  const [updatePromotion] = useUpdatePromotionMutation();
  const [deletePromotion] = useDeletePromotionMutation();
  const [createPromotion] = useCreatePromotionMutation();

  const f = useFormik({
    initialValues: {
      ...pick(editPromotion || {}, ['from', 'to', 'promotion_type_id', 'total_cost', 'comment']),
      productIds: editPromotion?.products.map((d) => d?.product?.id),
      additional_data: editPromotion?.additional_data || {},
    },
    onSubmit: async (vals: any) => {
      try {
        const { productIds, ...plainPromotion } = vals;
        // console.log(plainPromotion);

        let id = editPromotion?.id;
        // console.log(id);

        if (!id) {
          const { data } = await createPromotion({
            variables: { customerId },
            refetchQueries: [{ query: GetPromotionsForCustomerDocument, variables: { customerId } }],
          });
          id = data?.insert_promotions_one?.id;
        }

        const vvv = {
          ...plainPromotion,
          total_cost: plainPromotion.total_cost ? Number(plainPromotion.total_cost) : undefined,
        };

        console.log('vv', vvv);

        await updatePromotion({
          variables: {
            id: id || 0,
            promotion: vvv,
            products: productIds?.map((pid: any) => ({ promotion_id: editPromotion?.id, product_id: pid || 0 })) || [],
          },
        });

        setEditPromotionId(id || 0);

        if (autoClose) {
          success(t('promotions.successfullySaved'));
          close();
        } else {
          setAutoClose(true);
        }
      } catch (e) {
        console.error(e);
        error(t('error'));
      }
    },
    enableReinitialize: true,
  });

  const currentType = promotionTypes?.find((d) => d.id === (f.values as any).promotion_type_id);
  const additional_attributes: object = currentType?.additional_attributes;

  return (
    <Fragment>
      <YesNoDialog
        title={t('promotion.deleteUploadTitle')}
        text={t('promotion.deleteUploadWarning')}
        isOpen={Boolean(confirmUploadDelete)}
        noAction={() => setConfirmUploadDelete(0)}
        yesAction={async () => {
          await axios.delete(`/api/delete-upload/${confirmUploadDelete}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          });
          refetch();
          setConfirmUploadDelete(0);
        }}
      />

      <YesNoDialog
        title={t('Delete Promotion')}
        text={t('DeletePromotionText')}
        isOpen={confirmDelete}
        noAction={() => setConfirmDelete(false)}
        yesAction={async () => {
          try {
            await deletePromotion({
              variables: { promotionId: editPromotion?.id || 0 },
              refetchQueries: [{ query: GetPromotionsForCustomerDocument, variables: { customerId } }],
            });
            close();
            setConfirmDelete(false);
          } catch (e) {
            error(t(JSON.stringify(e)));
          }
        }}
      />
      <Dialog open={isOpen} onClose={close}>
        <DialogTitle>
          {t('Edit Promotion')} | {editPromotion?.id}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={f.handleSubmit}>
            <div>
              <FormikDatePicker formik={f} label={t('From')} name="from" />
              <FormikDatePicker formik={f} label={t('To')} name="to" />
            </div>

            <FormikSelect formik={f} name="promotion_type_id" options={promotionTypes} label={t('promotion.type')} />

            {Boolean(additional_attributes) &&
              Object.entries(additional_attributes).map(([k, v]) => (
                <TextField
                  fullWidth
                  key={k}
                  value={f.values.additional_data[k] || ''}
                  label={v.label || ''}
                  style={{ marginTop: '10px' }}
                  onChange={(e) =>
                    f.setValues({ ...f.values, additional_data: { ...f.values.additional_data, [k]: e.target.value } })
                  }
                />
              ))}

            {Boolean(currentType?.product_connection) && <FormikProductSelector formik={f} name="productIds" />}

            <FormikTextField formik={f} label="Total Cost" name="total_cost" extra={{ type: 'number' }} />
            <FormikTextField formik={f} label="Comment" name="comment" rows={4} />

            {loading && <LinearProgress />}
            <Grid container spacing={2} style={{ marginTop: '0.5em' }}>
              {data?.uploads.map((upl) => (
                <Grid item xs={6} key={upl.id}>
                  <div
                    style={{
                      border: '1px lightgray solid',
                      height: '100%',
                      overflow: 'hidden',
                      borderRadius: '0.25em',
                      padding: '1em',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {upl && <PreviewComponent upload={upl} />}
                    <Divider style={{ margin: '0.4em 0' }} />
                    <div style={{ display: 'flex', gap: '20px' }}>
                      {upl && <Box fontSize={10}>{upl.type}</Box>}
                      {upl && <Box fontSize={10}>{humanFileSize(upl.size || 0)}</Box>}
                      {upl && <Box fontSize={10}>{format(parseISO(upl.mtime), 'HH:mm')}</Box>}
                      {upl && <Box fontSize={10}>{format(parseISO(upl.mtime), 'dd.MM.yyyy')}</Box>}
                    </div>
                    <div style={{ display: 'flex' }}>
                      <IconButton onClick={() => setConfirmUploadDelete(upl.id)} color="error">
                        <VIcon icon={faTimesCircle} />
                      </IconButton>

                      <div style={{ flex: 1 }} />
                      <VDownloadLink src={upl?.path || ''} name={'promotion'} mime={upl?.type || ''} />
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>

            {!editPromotion && (
              <Button variant="outlined" fullWidth type="submit" onClick={() => setAutoClose(false)}>
                {t('promotion.enableUpload')}
              </Button>
            )}
            {editPromotion && (
              <VisiterDropzone promotionId={editPromotion.id || 0} customerId={customerId} fileFinished={refetch} />
            )}
            <div style={{ display: 'flex', margin: '20px 0 10px 0' }}>
              <Button onClick={close} variant="outlined">
                Cancel
              </Button>
              {editPromotion?.id && (
                <Button onClick={() => setConfirmDelete(true)} variant="outlined" color="error">
                  Delete
                </Button>
              )}
              <div style={{ flex: 1 }} />
              <Button variant="outlined" color="success" type="submit">
                Save
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
