import { AddressWithPos } from "types/AddressWithPos";
import { LatLng } from 'types/LatLng';
import { shapeAddressObject } from './shapeAddressObject';

export const getAddressFromPosition = async (latLng: LatLng): Promise<AddressWithPos> => {
  const { longitude, latitude } = latLng;
  const response = await fetch(
    `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`
  );
  const location = await response.json();
  console.log(location);
  // const addr_string = `${location.address.road || ''}${location.address.pedestrian || ''} ${
  //   location.address.house_number || ''
  // }, ${location.address.postcode || ''} ${location.address.city || ''}`;
  return { latitude, longitude, ...shapeAddressObject(location.address || {}) };
};
