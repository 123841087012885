import React from 'react';
import { Grid, Typography } from '@mui/material';
import Select from 'react-select';
import { ReactSelectStyles } from 'utils/ReactSelectStyles';
import { Answer } from 'types/Answer';
import { ArrayElement } from 'utils/ArrayElement';
import { GetVisitQuery } from 'generated/graphql';

export const QuestionSelect = ({ formik, question }: { formik: any; question: ArrayElement<GetVisitQuery['question']>; }) => {
  if (question.type !== 'select') {
    return null;
  }

  const fval = formik.values.answers.find((d: Answer) => d.question_id === question.id).answer.value;

  return (
    <Grid container>
      <Grid item xs={12} sm={8} style={{ margin: 'auto' }}>
        <Typography>{question.text}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Select
          value={question.options.map((o, i) => ({ value: o.id, label: o.text })).find((d) => d.value === fval)}
          options={question.options.map((o, i) => ({ value: o.id, label: o.text }))}
          onChange={(e) => formik.setValues({
            ...formik.values,
            answers: [
              ...formik.values.answers.filter((d: Answer) => d.question_id !== question.id),
              { question_id: question.id, answer: { value: e?.value } },
            ],
          })}
          styles={ReactSelectStyles} />
      </Grid>
    </Grid>
  );
};
