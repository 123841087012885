import { useGetAppSettingsQuery } from 'generated/graphql';

type AppSettings = {
  emailCustomerSheetReceiver?: string;
  emailCustomerSheetReceiverName?: string;
  emailCustomerSheetText?: string;
  orderEmailEnabled?: string;
  orderEmailBody?: string;
};

export const useAppSettings = () => {
  const { data } = useGetAppSettingsQuery();

  let res = {};
  if (data) {
    let a = data.app_settings;
    let b = a.map((d) => [d.name, d.name?.toLowerCase()?.endsWith('_id') ? Number(d.value) : d.value]);
    res = Object.fromEntries(b);
  }
  return res as AppSettings;
};
