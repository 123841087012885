import CustomerTree from '../customers/Customers';
import { CustomerDataProvider } from './Customer';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useParams } from 'react-router';

const CustomerPage = () => {
  const { id } = useParams<{ id: string }>();

  const theme = useTheme();
  const displaySidebar = useMediaQuery(theme.breakpoints.up('md'));

  if (displaySidebar) {
    return (
      <Grid container style={{ minHeight: '100%' }}>
        <Grid
          item
          xs={3}
          style={{
            position: 'relative',
            paddingRight: '20px',
            borderRight: '1px solid lightgray',
          }}
        >
          <CustomerTree />
        </Grid>
        <Grid item xs={9} style={{ height: '100%', paddingLeft: '20px' }}>
          <CustomerDataProvider id={Number(id)} />
        </Grid>
      </Grid>
    );
  }

  return <CustomerDataProvider id={Number(id)} />;
};
export default CustomerPage;
