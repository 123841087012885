import { identity } from 'lodash';
import React, { Fragment } from 'react';
import { DataMappingDisplay } from './DataMappingDisplay';
import { Mapping, Target } from './MappingTypes';

export const DataMappingsDisplay = ({
  mappings,
  entries,
  changeEntries,
}: {
  mappings: Mapping[];
  entries: any[];
  changeEntries: React.Dispatch<any>;
}) => {
  if (!mappings.some((d) => d.data)) {
    return null;
  }

  return (
    <Fragment>
      {mappings
        .filter((d) => d.data) // show only mappings where data is present
        .map((mapping) => {
          const changeEntriesForMapping = (
            oldVal: string | number | null | undefined,
            newVal: string | number | null
          ) => {
            console.log('chaning', oldVal, newVal);
            // state setter
            changeEntries(
              entries.map((entry) => {
                if (entry[mapping.source || ''] === oldVal) {
                  return { ...entry, [mapping.source || '']: newVal };
                }
                if (mapping.target === Target.tags) {
                  const lll = entry[mapping.source || ''] // get entry from source
                    ?.split(',') // deal with comma
                    .map((d: string) => {
                      const it = d.trim();
                      if (it === oldVal) {
                        console.log('mapping', d, oldVal);
                        return newVal; // replace old value with new, if found old value
                      }
                      return it;
                    })
                    .filter(identity)
                    .join(','); // deal with nulls and such

                  return { ...entry, [mapping.source || '']: lll };
                }

                return entry;
              })
            );
          };

          return <DataMappingDisplay key={mapping.target} mapping={mapping} changeEntries={changeEntriesForMapping} />;
        })}
    </Fragment>
  );
};
