import { Slide } from '@mui/material';
import { useMessages } from '../../context/MessageContext';
import { SingleMessage } from './SingleMessage';

import React from 'react';
export const MessageDisplay = () => {
  const { messages } = useMessages();
  return (
    <div
      style={{
        position: 'fixed',
        bottom: '20px',
        left: '20px',
        zIndex: 9999,
      }}
    >
      {messages !== undefined &&
        messages.map((message) => (
          <Slide
            key={message.id}
            direction="right"
            in={Boolean(message.show)}
            mountOnEnter
            unmountOnExit
          >
            <div>
              <SingleMessage key={message.id} message={message} />
            </div>
          </Slide>
        ))}
    </div>
  );
};
