import React from 'react';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';

export const FormikSwitch = ({ formik, name, label }: { formik: any; name: string; label: string }) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            id={name}
            checked={formik.values[name]}
            onChange={(v) => formik.setValues({ ...formik.values, [name]: v.target.checked })}
          />
        }
        label={label}
      />
    </FormGroup>
  );
};
