import { Dialog, DialogContent, Divider, LinearProgress, List, ListItem, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TitleDisplay } from './TitleDisplay';
import _ from 'lodash';
import { Fragment, useState } from 'react';
import { addHours, format, parseISO } from 'date-fns';
import { VisitEdit } from './VisitEdit';
import { useModalFullscreen } from 'utils/useModalFullscreen';
import {
  GetCustomerVisitsDocument,
  GetCustomerVisitsQuery,
  useCreateVisitMutation,
  useGetCustomerVisitsQuery,
} from 'generated/graphql';

export const CustomerVisits = ({ customerId }: { customerId: number }) => {
  return <CustomerVisitsData customerId={customerId} />;
};

const CustomerVisitsData = ({ customerId }: { customerId: number }) => {
  const { data, loading, error } = useGetCustomerVisitsQuery({
    variables: { customerId },
  });

  if (loading || !data?.visits) {
    return <LinearProgress />;
  }
  if (error) {
    console.error(error);
  }

  return <CustomerVisitsEdit customerId={customerId} visits={data?.visits} />;
};

export const CustomerVisitsEdit = ({
  customerId,
  visits,
}: {
  customerId: number;
  visits: GetCustomerVisitsQuery['visits'];
}) => {
  const { t } = useTranslation();
  const matches = useModalFullscreen();
  const [createVisit] = useCreateVisitMutation();
  const [editVisitId, setEditVisitId] = useState<number | undefined>(undefined);

  return (
    <Fragment>
      {Boolean(editVisitId) && editVisitId !== undefined && (
        <Dialog open={true} fullScreen={matches}>
          <DialogContent>
            <div style={{ minWidth: '200px' }}>
              <VisitEdit
                customerId={customerId}
                isDialog={true}
                visitId={editVisitId}
                cancel={() => setEditVisitId(undefined)}
              />
            </div>
          </DialogContent>
        </Dialog>
      )}

      <TitleDisplay
        title={t('Visits')}
        showAdd
        onClick={async () => {
          const { data } = await createVisit({
            variables: { visit: { customer_id: customerId, date: new Date(), end_date: addHours(new Date(), 1) } },
            refetchQueries: [{ query: GetCustomerVisitsDocument, variables: { customerId: customerId } }],
          });
          if (data?.insert_visits_one === null) {
            return;
          }

          setEditVisitId(data?.insert_visits_one?.id);
        }}
      />
      <List>
        {
          // last 5 visits
          visits &&
            _.orderBy(visits, 'date', 'desc').map((visit) => {
              const date = visit?.date !== null && format(parseISO(visit.date), 'dd.MM.yy');
              return (
                <Fragment key={`visit_${visit.id}`}>
                  <ListItem button onClick={() => setEditVisitId(visit.id)}>
                    <ListItemText
                      secondary={`id: ${visit.id} | ${visit.user?.login} | date: ${date}`}
                      primary={
                        <div>
                          {visit.topics && (
                            <div>
                              <b> {t('Topics')}</b>
                            </div>
                          )}
                          {visit.topics.map((d, i) => (
                            <div key={i}>{d.topic?.topic}</div>
                          ))}
                          {visit.comment && (
                            <div>
                              <b> {t('Comment')}</b>
                            </div>
                          )}
                          {visit.comment}
                          {visit.agreements && (
                            <div>
                              <b>{t('Agreement')}</b>
                            </div>
                          )}
                          {visit.agreements}
                        </div>
                      }
                    />
                  </ListItem>
                  <Divider />
                </Fragment>
              );
            })
        }
      </List>
    </Fragment>
  );
};
