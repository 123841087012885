import { Button, Step, StepContent, StepLabel, Stepper, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useState } from 'react';
import React from 'react';
import { WizardNavs } from '../WizardNavs';
import { VPDF } from 'components/VPDF';
import { VIcon } from 'components/VIcon';
import { useMessages } from 'context/MessageContext';

export const SendSingleDocumentWizard = ({
  receiverName,
  receiverEMail,
  subject,
  body,
  ressourceURL,
  sendMail,
  close,
  refetch,
}: {
  receiverName: string;
  receiverEMail: string;
  subject: string;
  body: string;
  ressourceURL: string;
  sendMail: (subject: string, body: string, email: string) => void;
  close: () => void;
  refetch: () => void;
}) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [_subject, setSubject] = useState(subject);
  const [_body, setBody] = useState(body);
  const { error, success } = useMessages();
  const maxSteps = 2;
  return (
    <Fragment>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel error={!receiverEMail} onClick={() => setActiveStep(0)}>
            {t('email.reviewRecipient')}
          </StepLabel>
          <StepContent>
            <TextField fullWidth label={t('name')} value={receiverName} disabled={true} />
            <TextField
              fullWidth
              label={t('email.email')}
              value={receiverEMail || ''}
              disabled={true}
              style={{ marginTop: '0.5em' }}
            />
            <WizardNavs
              onNext={() => setActiveStep(activeStep + 1)}
              onPrevious={() => setActiveStep(activeStep - 1)}
              activeStep={activeStep}
              maxStep={maxSteps}
            />
          </StepContent>
        </Step>
        <Step>
          <StepLabel onClick={() => setActiveStep(1)}>{t('email.pdfReview')}</StepLabel>
          <StepContent>
            <div style={{ width: '100%', height: '500px', overflow: 'auto' }}>
              <VPDF src={ressourceURL} width={750} />
            </div>
            <WizardNavs
              onNext={() => setActiveStep(activeStep + 1)}
              onPrevious={() => setActiveStep(activeStep - 1)}
              activeStep={activeStep}
              maxStep={maxSteps}
            />
          </StepContent>
        </Step>
        <Step>
          <StepLabel onClick={() => setActiveStep(2)}>{t('email.messageEdit')}</StepLabel>
          <StepContent>
            <TextField
              label={t('email.subject')}
              fullWidth
              value={_subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            <TextField
              style={{ marginTop: '0.5em' }}
              label={t('email.body')}
              fullWidth
              value={_body}
              onChange={(e) => setBody(e.target.value)}
              multiline
              rows={8}
            />

            <WizardNavs
              onNext={() => setActiveStep(activeStep + 1)}
              onPrevious={() => setActiveStep(activeStep - 1)}
              activeStep={activeStep}
              maxStep={maxSteps}
            />
          </StepContent>
        </Step>
      </Stepper>

      <div style={{ display: 'flex', marginTop: '1em' }}>
        <Button onClick={() => close()} variant="contained">
          {t('cancel')}
        </Button>
        <div style={{ flex: 1 }} />
        <Button
          startIcon={<VIcon icon={faEnvelope} />}
          variant="contained"
          color="success"
          onClick={async () => {
            try {
              await sendMail(_subject, _body, receiverEMail);
              success(t('saved'));
              refetch();
              close();
            } catch (e) {
              error(t('error while saving'));
            }
          }}
        >
          {t('email.send')}
        </Button>
      </div>
    </Fragment>
  );
};
