import { InputLabel } from '@mui/material';
import Select from 'react-select';
import { ReactSelectStyles } from 'utils/ReactSelectStyles';

export const FormikSelect = ({
  formik,
  label,
  name,
  options,
  display,
  filterOptions,
}: {
  formik: any;
  label: string;
  name: string;
  options: any[];
  display?: (o: any) => any;
  filterOptions?: (candidate: { label: string; value: string; data: any }, input: string) => boolean;
}) => {
  const fval = formik.values[name];

  let _disp = (o: any) => o.name;
  if (display) {
    _disp = display;
  }

  let _filterOptions = undefined;
  if (filterOptions) {
    _filterOptions = filterOptions;
  }

  let _options = options.map((o: any, i: number) => ({ value: o.id, label: _disp(o), ...o }));

  return (
    <div>
      <InputLabel>{label}</InputLabel>
      <Select
        id={name}
        value={_options.find((d: any) => d.value === fval)}
        options={_options}
        onChange={(e) =>
          formik.setValues({
            ...formik.values,
            [name]: e?.value,
          })
        }
        isSearchable
        isClearable
        styles={ReactSelectStyles}
        filterOption={_filterOptions}
      />
    </div>
  );
};
