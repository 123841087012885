import { LinearProgress, Typography, LinearProgressProps } from '@mui/material';
import React from 'react';
import { Box } from '@mui/system';
import _ from 'lodash';

type LinearProgressWithLabelProps = {
  label?: string;
  decimals?: number;
  unit?: string;
  max?: number;
  component?: React.ElementType;
} & LinearProgressProps;
export const LinearProgressWithLabel = (props: LinearProgressWithLabelProps) => {
  let v = ((props.value || 0) / (props.max || 100)) * 100 || 0;
  let b = !props.valueBuffer ? undefined : ((props.valueBuffer || 0) / (props.max || 100)) * 100 || 0;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }} component={props.component || 'div'}>
      {props.label && (
        <Box sx={{ marginRight: '0.5em' }} component={props.component || 'div'}>
          <Typography component={'span'} variant="body2" color="text.secondary">
            {props.label}
          </Typography>
        </Box>
      )}
      <Box sx={{ width: '100%', mr: 1 }} component={props.component || 'div'}>
        <LinearProgress
          variant={props.variant || 'determinate'}
          {...props}
          value={_.min([v, b])}
          valueBuffer={_.max([v, b])}
        />
      </Box>
      <Box sx={{ minWidth: 35 }} component={props.component || 'div'}>
        <Typography component={'span'} variant="body2" color="text.secondary">{`${_.round(props.value || 0, 2)}${
          props.unit || ''
        }`}</Typography>
      </Box>
    </Box>
  );
};
