import { Grid, Paper } from '@mui/material';

import React from 'react';
import { AppImage } from './AppImage';
import { FixAddresses } from './FixAddresses';
import { MailSettings } from './MailSettings';
import { UserSettings } from './UserSettings';

const Settings = () => {
  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Paper style={{ padding: '1em' }}>
            <UserSettings />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper style={{ padding: '1em' }}>
            <FixAddresses />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper style={{ padding: '1em' }}>
            <MailSettings />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper style={{ padding: '1em' }}>
            <AppImage />
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Settings;
