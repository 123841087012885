import React, { useCallback, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useCurrentUserQuery } from 'generated/graphql';

type UserContextType = {
  authenticate: (login: string, password: string) => Promise<boolean>;
  logout: () => void;
  hasRole: (role: string) => boolean;
  loading: boolean;
  role: string | null | undefined;
  login: string | null | undefined;
  id: number | null | undefined;
  roles: (string | null)[] | null | undefined;
};

export const UserContext = React.createContext<UserContextType>({
  authenticate: async (login: string, password: string) => false,
  logout: () => {},
  hasRole: (role: string) => false,
  loading: false,
  role: 'anonymous',
  login: null,
  id: 0,
  roles: ['anonymous'],
});

export const useUser = () => {
  return useContext(UserContext);
};

// const RoleHierarchy = [
//   {
//     name: 'manager',
//     inheritsFrom: ['user'],
//   },
//   { name: 'user' },
// ];

export const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = useState(true);
  const { data, loading: loadingQuery, refetch, error } = useCurrentUserQuery(); //  useQuery<CurrentUser>(GetCurrentUserQuery);
  // console.log('data', data);
  const [uid, setUid] = useState<number | null>(null);
  const [defaultRole, setDefaultRole] = useState<string | null>(null);
  const [allowedRoles, setAllowedRoles] = useState<string[]>([]);
  const [login, setLogin] = useState<string | null>(null);

  if (error) {
    //console.log(error);
  }

  /**
   * Update the value store
   */
  useEffect(() => {
    setDefaultRole(data?.currentuser[0]?.role?.name || 'anonymous');
    setAllowedRoles([data?.currentuser[0]?.role?.name || 'anonymous']);
    setUid(data?.currentuser[0]?.id || 0);
    setLogin(data?.currentuser[0]?.login || 'anonymous');
    setLoading(loadingQuery);
  }, [data?.currentuser, setDefaultRole, setAllowedRoles, setUid, setLogin, loadingQuery]);

  const authenticate = async (login: string, password: string): Promise<boolean> => {
    setLoading(true);
    try {
      localStorage.removeItem('token');
      // console.log('asdfasdf', t);
      const { data } = await axios.post('/api/actions/login', { login, password });
      // console.log('res from login', data);
      const { token, allowedRoles, uid, defaultRole, uname } = data;
      setLogin(uname);
      setAllowedRoles(allowedRoles);
      setUid(uid);
      setDefaultRole(defaultRole);
      if (token) {
        localStorage.setItem('token', token);
      }
      refetch();
      setLoading(false);
      return true;
    } catch (error) {
      console.log(error);
      setLoading(false);
      return false;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUid(null);
    setDefaultRole(null);
    setAllowedRoles([]);
    setLogin(null);
    refetch();
  };

  const hasRole = useCallback(
    (role) => {
      // console.log('', defaultRole, role);
      if (!data) {
        return false;
      }
      if (defaultRole === role) {
        return true;
      }
      if (defaultRole === 'manager' && role === 'user') {
        return true;
      }
      return false;
    },
    [data, defaultRole]
  );

  return (
    <UserContext.Provider
      value={{
        authenticate,
        logout,
        loading: loading || loadingQuery,
        role: defaultRole,
        login: login,
        id: uid,
        roles: allowedRoles,
        hasRole,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
