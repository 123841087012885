import './App.css';

import { useStyles } from './hooks/useStyles';
import { MessageDisplay } from './components/messaging/MessageDisplay';
import { VAppBar } from './components/navigation/VAppBar';
import { ContentRouter } from './ContentRouter';
import { GoingOnlinePrompt } from 'components/syncDisplay/GoingOnlinePrompt';

function App() {
  const c = useStyles();
  return (
    <div className={c.root}>
      <VAppBar />
      <div style={{ width: '100%' }}>
        <div className={c.appBarSpacer} />
        <div className={c.content}>
          <ContentRouter />
        </div>
        <GoingOnlinePrompt />
      </div>
      <MessageDisplay />
    </div>
  );
}

export default App;
