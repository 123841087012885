import { Checkbox, LinearProgress, List, ListItem, ListItemText } from '@mui/material';

import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { TitleDisplay } from './TitleDisplay';
import { Fragment, useState } from 'react';

import { CustomerFormType } from './Customer';
import { VAvatar } from 'pages/products/VAvatar';
import { groupBy, orderBy } from 'lodash';
import { GetCustomerQuery, useGetListableProductsQuery } from 'generated/graphql';

export const ProductList = ({
  formik,
  customer,
}: {
  formik: FormikProps<CustomerFormType>;
  customer: GetCustomerQuery['customers_by_pk'];
}) => {
  const [isConfiguring, setIsConfiguring] = useState(false);
  const { t } = useTranslation();
  const { data, loading, error } = useGetListableProductsQuery();

  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    console.error(error);
    return null; // JSON.stringify(error);
  }

  const allProducts = data?.products;
  const selectedProducts = formik.values.products;

  // console.log(selectedProducts)

  if (allProducts === undefined || allProducts === null) {
    return null;
  }
  const filter = (c: any) => (isConfiguring ? true : (selectedProducts || []).find((d) => d?.product?.id === c.id));

  const grps = orderBy(
    Object.entries(groupBy(allProducts.filter(filter), (d) => d.product_group?.name || t('customer.noProductGroup'))),
    ([a]) => a
  );

  return (
    <Fragment>
      <TitleDisplay title={t('Product Listings')} showAdd={true} onClick={() => setIsConfiguring(!isConfiguring)} />

      {grps.map(([group, products]) => (
        <List key={group}>
          <ListItem>
            <ListItemText primary={group} secondary={''} />
          </ListItem>
          <List>
            {products.map((pg, i) => {
              const checked = (selectedProducts || []).find((d) => d?.product?.id === pg.id) != null;
              return (
                <ListItem
                  key={i + '_' + pg.name}
                  role={undefined}
                  dense
                  button={isConfiguring as any}
                  onClick={() =>
                    isConfiguring && checked
                      ? formik.setValues({
                          ...formik.values,
                          products: selectedProducts.filter((d) => d?.product?.id !== pg.id),
                        })
                      : formik.setValues({
                          ...formik.values,
                          products: [...selectedProducts, { __typename: 'customers_products', product: pg }],
                        })
                  }
                >
                  {isConfiguring && (
                    <Checkbox style={{ padding: '0px' }} checked={checked} tabIndex={-1} disableRipple />
                  )}
                  <VAvatar style={{ margin: '0 0.5em' }} alt={pg.name || ''} src={pg.avatar_path || undefined} />
                  <ListItemText primary={`${pg.name}`} />
                </ListItem>
              );
            })}
          </List>
        </List>
      ))}
    </Fragment>
  );
};
