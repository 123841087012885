import { Badge, Divider, IconButton, Menu, MenuItem, SvgIcon } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faSignInAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { useMessages } from '../../context/MessageContext';
import { useUser } from '../../context/UserContext';
import { Fragment, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSyncContext } from 'context/SyncContext';
import ReactCountryFlag from 'react-country-flag';

import i18next from 'i18next';
import _ from 'lodash';

/**
 * converts the locale string to the flag library compatible string
 * @param l locale string
 * @returns
 */
const lng = (l: string) => {
  switch (l) {
    case 'en':
      return 'gb';
    default:
      return l;
  }
};

export const UserMenu = () => {
  const { info } = useMessages();
  const { logout, id } = useUser();
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const h = useNavigate();
  const { openMutations, online } = useSyncContext();

  if (!id) {
    return (
      <IconButton
        id="SignInButton"
        color="inherit"
        onClick={() => {
          h('/login');
        }}
      >
        <SvgIcon>
          <FontAwesomeIcon icon={faSignInAlt} />
        </SvgIcon>
      </IconButton>
    );
  }
  const onlineIcon: any = <FontAwesomeIcon icon={faBolt} />;

  return (
    <Fragment>
      <IconButton
        id="UserMenu"
        ref={ref}
        color="inherit"
        onClick={() => {
          setOpen(true);
        }}
      >
        <Badge
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          badgeContent={!online && onlineIcon}
        >
          <Badge badgeContent={openMutations} color="error">
            <SvgIcon>
              <FontAwesomeIcon icon={faUser} />
            </SvgIcon>
          </Badge>
        </Badge>
      </IconButton>
      <Menu anchorEl={ref.current} keepMounted open={open} onClose={() => setOpen(false)}>
        <MenuItem
          onClick={() => {
            h('/profile');
            setOpen(false);
          }}
        >
          Profile
        </MenuItem>
        <Divider />
        {_.sortBy(i18next?.languages)?.map((d) => (
          <MenuItem
            key={d}
            onClick={() => i18next.changeLanguage(d)}
            style={{ backgroundColor: i18next.resolvedLanguage === d ? '#7ebc59' : 'transparent' }}
          >
            <ReactCountryFlag countryCode={lng(d)} svg style={{ marginRight: '0.25em' }} />
            {d}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem
          onClick={() => {
            setOpen(false);
            info !== undefined && info('logout');
            logout !== undefined && logout();
            h('/');
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </Fragment>
  );
};
