import { useParams } from 'react-router-dom';
import { useFetch } from 'hooks/useFetch';
import { Alert, LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useUser } from 'context/UserContext';

export const EmailVerifyPage = () => {
  const { t } = useTranslation();
  const { id: userId } = useUser();
  let { id, email, token } = useParams<any>();

  if (userId && Number(id) !== userId)
    return (
      <div>
        <Alert severity="error">{t('email-verify.differentUserFirstSignInMessage')}</Alert>
      </div>
    );

  return <EmailConfirmComponent id={id} email={email} token={token} />;
};

const EmailConfirmComponent = ({ id, email, token }: any) => {
  const { t } = useTranslation();
  const { data, loading } = useFetch(`/api/email-verify/${id}/${email}/${token}`);

  return (
    <div>
      {loading && <LinearProgress />}
      {!loading && data?.success ? (
        <Alert severity="success">{t('email-verify.Success')}</Alert>
      ) : (
        <Alert severity="error">{t('email-verify.noSuccess')}</Alert>
      )}
    </div>
  );
};
