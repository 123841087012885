import { LayerProps } from 'react-map-gl';

export const createLayerStyle = (getMarkerColor?: () => Record<string, unknown>): LayerProps => {
  return {
    id: 'point',
    type: 'symbol',
    layout: { 'icon-image': 'map-pin', 'icon-size': 1, 'icon-allow-overlap': true, 'icon-anchor': 'bottom' },
    paint: {
      'icon-opacity': 0.85,
      // 'icon-color': '#368cbf',
      // 'icon-color': ['interpolate', ['linear'], ['get', 'temp'], 0, 'blue', 900, 'blue', 1000, 'yellow', 2400, 'red'],
      // fallback if no function defined
      ...(getMarkerColor ? getMarkerColor() : { 'icon-color': '#368cbf' }),
    },
  };
};
