import { Dialog, DialogContent, DialogTitle, Divider, IconButton, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { faCheckCircle, faEnvelope, faPenSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useState } from 'react';
import { parseISO } from 'date-fns/esm/fp';
import { differenceInCalendarDays, format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import YesNoDialog from 'components/dialog/YesNoDialog';
import { VIcon } from 'components/VIcon';
import { SendSingleDocumentWizard } from './SendSingleDocumentWizard';
import axios from 'axios';
import { useAppSettings } from 'hooks/useAppSettings';
import { GetTransferOrdersQuery } from 'generated/graphql';
import { ArrayElement } from 'utils/ArrayElement';

export const TransferOrderDisplay = ({
  transferOrder: order,
  onEdit,
  deleteTransferOrder,
  refetch,
}: {
  transferOrder: ArrayElement<GetTransferOrdersQuery['transfer_orders']>; // GetTransferOrders_transfer_orders;
  onEdit?: () => void;
  deleteTransferOrder?: () => Promise<void>;
  refetch: () => void;
}) => {
  const [confirm, setConfirm] = useState<boolean>(false);
  const { t } = useTranslation();
  const [openSendMailWizard, setOpenSendMailWizard] = useState(false);
  const { orderEmailBody, orderEmailEnabled } = useAppSettings();

  if (!order) {
    return <div>no data</div>;
  }

  const s = order.id + ' - Auftrag - ' + order.customer?.name || '';
  return (
    <Fragment>
      {
        <Dialog open={openSendMailWizard} onClose={() => setOpenSendMailWizard(false)} maxWidth="lg" fullWidth>
          <DialogTitle>{t('order.sendMail')}</DialogTitle>
          <DialogContent>
            <SendSingleDocumentWizard
              subject={s}
              body={orderEmailBody || ''}
              receiverEMail={order.supplier?.email || ''}
              receiverName={order.supplier?.name || ''}
              refetch={refetch}
              sendMail={async (subject, body, email) =>
                await axios.post(
                  '/api/order-send',
                  { subject, body, email, orderId: order.id },
                  { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
                )
              }
              ressourceURL={`/api/get-order-document/${order.id}`}
              close={() => setOpenSendMailWizard(false)}
            />
          </DialogContent>
        </Dialog>
      }
      <YesNoDialog
        isOpen={confirm}
        noAction={() => setConfirm(false)}
        yesAction={async () => {
          setConfirm(false);
          deleteTransferOrder && (await deleteTransferOrder());
        }}
        title={t('Confirm Delete')}
        text={t('ConfirmDeleteTransferOrderText')}
      />
      <Fragment>
        {order.date ? format(parseISO(order.date), 'dd.MM.yyyy') : t('No Date')}

        {(order.date ? differenceInCalendarDays(new Date(), parseISO(order.date)) < 10 : true) ? (
          <Fragment>
            <IconButton onClick={onEdit}>
              <SvgIcon>
                <FontAwesomeIcon icon={faPenSquare} />
              </SvgIcon>
            </IconButton>

            <IconButton
              onClick={() => {
                setConfirm(true);
              }}
            >
              <SvgIcon>
                <FontAwesomeIcon icon={faTrash} />
              </SvgIcon>
            </IconButton>
            {orderEmailEnabled === 'true' && (
              <IconButton
                onClick={() => {
                  setOpenSendMailWizard(true);
                }}
              >
                <SvgIcon>
                  <FontAwesomeIcon icon={faEnvelope} />
                </SvgIcon>
              </IconButton>
            )}
            {order.email_sents?.length > 0 && (
              <Typography>
                <VIcon icon={faCheckCircle} color="green" /> {t('order.emailSent')}
              </Typography>
            )}
          </Fragment>
        ) : (
          <Typography variant="overline"> {t('Too old to edit')}</Typography>
        )}
      </Fragment>
      <Typography>{t('Items')}</Typography>
      <ul>
        {order.items
          .filter((d) => d.count)
          .map((it, i) => (
            <li key={i}>
              {it.count} x {it?.product?.name}
            </li>
          ))}
      </ul>
      <Typography>{t('Free Items')}</Typography>
      <ul>
        {order.items
          .filter((d) => d.amount_free)
          .map((it, i) => (
            <li key={i}>
              {it.amount_free} x {it?.product?.name}
            </li>
          ))}
      </ul>
      <Divider />
    </Fragment>
  );
};
