import {
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { useUser } from 'context/UserContext';
 
import { TitleDisplay } from 'pages/customer/TitleDisplay';
import { VisitEdit } from 'pages/customer/VisitEdit';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useGetVisitsQuery, useGetUsersQuery } from 'generated/graphql';

//

export const Visits = () => {
  const { id: userId, hasRole } = useUser();
  const { t } = useTranslation();
  const [editVisitId, setEditVisitId] = useState(0);
  const [selectedUserId, setSelectedUserId] = useState(userId || 0);
  const [showEmptyDates, setShowEmptyDates] = useState(true);

  useEffect(() => {
    setSelectedUserId(userId || 0);
  }, [userId, setSelectedUserId]);

  const { data: usersData, loading: usersLoading } = useGetUsersQuery();

  const { data, error, loading } = useGetVisitsQuery({
    variables: { userId: selectedUserId },
  });

  if (error) {
    console.error(error);
  }

  const opts = usersData?.users?.map((d) => ({ value: d.id, label: d.login })) || [];

  return (
    <div>
      <TitleDisplay title={t('Visits') + ` (${t('empty dates first')})`} />
      {(loading || usersLoading) && <LinearProgress />}

      {Boolean(editVisitId) && (
        <Dialog open={true}>
          <DialogContent>
            <div style={{ minWidth: '200px' }}>
              <VisitEdit isDialog={true} visitId={editVisitId} cancel={() => setEditVisitId(0)} />
            </div>
          </DialogContent>
        </Dialog>
      )}

      {hasRole('manager') && (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ flex: 1, minWidth: '200px' }}>
            <Select
              options={opts}
              value={opts.find((d) => d.value === selectedUserId)}
              onChange={(n) => setSelectedUserId(n?.value || 0)}
            />
          </div>
          <FormControlLabel
            style={{ paddingLeft: '1em' }}
            control={<Checkbox checked={showEmptyDates} onChange={() => setShowEmptyDates(!showEmptyDates)} />}
            label={t('visits.showEmptyDates')}
          />
        </div>
      )}

      {/* {!loading && JSON.stringify(data)} */}
      {!loading && (
        <List>
          {data?.visits
            .filter((d) => showEmptyDates || d.date)
            .map((visit, i) => (
              <ListItem key={i} role="button" onClick={() => setEditVisitId(visit.id)}>
                <ListItemText
                  primary={
                    <Fragment>
                      <Typography variant="overline" style={{ paddingRight: '1em' }}>
                        id: {visit.id}
                      </Typography>{' '}
                      {visit.date || t('no Date')}{' '}
                    </Fragment>
                  }
                  secondary={visit.customer?.name}
                />
              </ListItem>
            ))}
        </List>
      )}
    </div>
  );
};
