import React from 'react';
import { TextField } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';

export const FormikDateTimePicker = ({ formik, name, label }: { formik: any; name: string; label: string }) => {
  return (
    <div style={{ marginTop: '10px' }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          label={label}
          inputFormat="dd.MM.yyyy HH:mm"
          value={formik.values[name]}
          onChange={(v) => formik.setValues({ ...formik.values, [name]: v })}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </LocalizationProvider>
    </div>
  );
};
