import React from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { Answer } from 'types/Answer';

import { GetVisitQuery } from 'generated/graphql';
import { ArrayElement } from 'utils/ArrayElement';
export const QuestionNumber = ({
  formik,
  question,
}: {
  formik: any;
  question: ArrayElement<GetVisitQuery['question']>;
}) => {
  if (question.type !== 'number') {
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={8} style={{ margin: 'auto' }}>
        <Typography>{question.text}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          type="number"
          value={formik.values.answers.find((d: Answer) => d.question_id === question.id).answer.value}
          onChange={(e) =>
            formik.setValues({
              ...formik.values,
              answers: [
                ...formik.values.answers.filter((d: Answer) => d.question_id !== question.id),
                { question_id: question.id, answer: { value: e.target.value } },
              ],
            })
          }
        />
      </Grid>
    </Grid>
  );
};
