import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SvgIcon } from '@mui/material';
import React from 'react';

export const VIcon = ({
  icon,
  color,
  size,
  ...rest
}: {
  icon: IconProp;
  color?: string;
  size?: SizeProp;
  rest?: any;
}) => (
  <SvgIcon style={{ color: color }}>
    <FontAwesomeIcon icon={icon} size={size} />
  </SvgIcon>
);
