import {
  faBan,
  faBug,
  faCheckCircle,
  faInfoCircle,
  faPause,
  faPlay,
  faTimesCircle,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
  LinearProgress,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TitleDisplay } from '../customer/TitleDisplay';
import { VIcon } from '../../components/VIcon';
import Axios from 'axios';
import _ from 'lodash';
import { format, parseISO } from 'date-fns/esm';
import { LoadingButton } from '@mui/lab';
import { LinearProgressWithLabel } from 'pages/products/LinearProgressWithLabel';
import { useGetCustomersWithoutAddressQuery, useSubMessageSubscription } from 'generated/graphql';

export const FixAddresses = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { data, loading, error } = useGetCustomersWithoutAddressQuery();
  const { t } = useTranslation();
  const { data: jobData, error: subMessageError } = useSubMessageSubscription({
    variables: { job_id: 1 },
  });

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    console.error(error);
    return null;
  }
  if (subMessageError) {
    console.error(subMessageError);
    return null;
  }
  if (!jobData) {
    return null;
  }

  const childState = jobData.job_by_pk?.status;

  return (
    <div>
      <TitleDisplay title={t('Fix Addresses')} />
      <div>{data?.fixable.aggregate?.count} fixable customers</div>
      <LoadingButton
        loading={_.includes(['running', 'paused'], childState)}
        loadingPosition="start"
        variant="outlined"
        startIcon={<VIcon icon={faWrench} />}
        onClick={() =>
          Axios.post(
            `/api/fix-addresses`,
            {},
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
          )
        }
      >
        {t('try fix')}
      </LoadingButton>
      {_.includes(['running', 'paused'], childState) && (
        <Button
          variant="outlined"
          startIcon={<VIcon icon={faBan} />}
          onClick={() =>
            Axios.post(
              `/api/fix-addresses/kill`,
              {},
              { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
            )
          }
        >
          {t('abort')}
        </Button>
      )}
      {_.includes(['running', 'paused'], childState) && (
        <Button
          variant="outlined"
          startIcon={<VIcon icon={childState === 'paused' ? faPlay : faPause} />}
          onClick={() =>
            Axios.post(
              `/api/fix-addresses/${childState === 'paused' ? 'unpause' : 'pause'}`,
              {},
              { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
            )
          }
        >
          {t('pause')}
        </Button>
      )}
      <div>
        {/* <LinearProgress style={{ margin: '10px 0' }} variant="determinate" value={jobData?.job_by_pk?.progress || 0} /> */}
        <LinearProgressWithLabel
          label="Progress"
          unit="%"
          style={{ margin: '10px 0' }}
          value={jobData?.job_by_pk?.progress || 0}
        />
        {/* {jobData?.job_by_pk?.progress} progress */}
      </div>

      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={jobData?.job_by_pk?.messages?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => {
          setPage(newPage);
        }}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(+event.target.value);
          setPage(0);
        }}
      />
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width="1">{t('time')}</TableCell>
              <TableCell width="1" />
              <TableCell>{t('message')} </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobData?.job_by_pk?.messages
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((m, i: number) => (
                <TableRow key={i}>
                  <TableCell style={{ fontFamily: 'monospace' }}>
                    {format(parseISO(m.date), 'HH:mm:ss')}
                    <br />
                    {format(parseISO(m.date), 'dd.MM.yy')}
                  </TableCell>
                  <TableCell>
                    <StatusIcon statusId={m.status_id} />
                  </TableCell>
                  <TableCell>{m.message}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const StatusIcon = ({ statusId }: { statusId: number }) => {
  switch (statusId) {
    case 1:
      return <VIcon color="#edb50b" icon={faInfoCircle} />;
    case 2:
      return <VIcon color="#0b68ed" icon={faBug} />;
    case 3:
      return <VIcon color="#12a529" icon={faCheckCircle} />;
    case 4:
      return <VIcon color="#ca0f0f" icon={faTimesCircle} />;
    default:
      return <VIcon color="#edb50b" icon={faInfoCircle} />;
  }
};
