import { Checkbox, LinearProgress, List, ListItem, ListItemText, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { VAvatar } from 'pages/products/VAvatar';
import { groupBy, includes, orderBy } from 'lodash';
import { useGetListableProductsQuery } from 'generated/graphql';

export const FormikProductSelector = ({ formik, name }: { formik: any; name: string }) => {
  const { data, loading, error } = useGetListableProductsQuery();

  const { t } = useTranslation();

  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    return null; //  JSON.stringify(error);
  }

  const allProducts = data?.products;
  const productIds = formik.values[name] as number[];
  // console.log('products', products);
  if (allProducts === null || allProducts === undefined) {
    return null;
  }

  const grps = orderBy(
    Object.entries(
      groupBy(
        allProducts.filter((d) => d),
        (d) => d.product_group?.name || t('customer.noProductGroup')
      )
    ),
    ([a]) => a
  );

  return (
    <div>
      <Typography variant="h5">{t('Products')}</Typography>

      {grps.map(([group, products]) => (
        <List key={group}>
          <ListItem>
            <ListItemText primary={group} secondary={''} />
          </ListItem>
          <List>
            {products.map((pg, i) => {
              const checked = includes(productIds, pg.id);
              return (
                <ListItem
                  key={pg.id}
                  role={undefined}
                  dense
                  button
                  onClick={() =>
                    checked
                      ? formik.setValues({ ...formik.values, productIds: productIds.filter((d) => d !== pg.id) })
                      : formik.setValues({
                          ...formik.values,
                          productIds: [...productIds.filter((d) => d !== pg.id), pg.id],
                        })
                  }
                >
                  <VAvatar src={pg.avatar_path || undefined} />
                  <Checkbox style={{ padding: '0px' }} checked={checked} tabIndex={-1} disableRipple />
                  <ListItemText primary={`${pg.name}`} />
                </ListItem>
              );
            })}
          </List>
        </List>
      ))}
    </div>
  );
};
