import { Divider, Button } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { pick } from 'lodash';
import { FormikTextField } from 'pages/customer/FormikTextField';
import { SketchPicker } from 'react-color';
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { VIcon } from 'components/VIcon';
import YesNoDialog from 'components/dialog/YesNoDialog';
import {
  GetTagsPageDocument,
  GetTagsPageQuery,
  useCreateTagMutation,
  useDeleteTagMutation,
  useUpdateTagMutation,
} from 'generated/graphql';
import { ArrayElement } from 'utils/ArrayElement';

export const EditTag = ({
  tag,
  close,
}: {
  tag?: ArrayElement<GetTagsPageQuery['tags']>;
  //GetTagsPage_tags;
  close?: () => void;
}) => {
  const { t } = useTranslation();
  const [showPicker, setShowPicker] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [update] = useUpdateTagMutation();
  const [create] = useCreateTagMutation({
    refetchQueries: [{ query: GetTagsPageDocument }],
  });
  const [deleteTag] = useDeleteTagMutation({
    refetchQueries: [{ query: GetTagsPageDocument }],
  });

  const f = useFormik({
    initialValues: { ...pick(tag, ['name', 'color']) },
    onSubmit: async (vals) => {
      try {
        if (!tag) {
          await create({ variables: { obj: vals } });
        } else {
          await update({ variables: { id: tag?.id || 0, obj: vals } });
        }
        close && close();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const popover = {
    position: 'absolute',
    zIndex: '2',
  };
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  };

  return (
    <form onSubmit={f.handleSubmit}>
      <YesNoDialog
        isOpen={confirmDelete}
        title={t('tag.deleteTagWarningTitle')}
        text={t('tag.deleteTagWarningText')}
        noAction={() => setConfirmDelete(false)}
        yesAction={async () => {
          deleteTag({ variables: { id: tag?.id || 0 } });
          setConfirmDelete(false);
          close && close();
        }}
      />
      <FormikTextField name="name" label={t('name')} formik={f} />
      <div style={{ display: 'flex', height: '3em', marginTop: '1em' }}>
        <div style={{ alignSelf: 'center', marginRight: '1em' }}>{t('Color')}</div>
        <Button
          style={{ background: f.values.color || 'white', color: 'inerit' }}
          variant="outlined"
          fullWidth
          onClick={() => setShowPicker(true)}
        >
          {t('clickToSelectColor')}
        </Button>
      </div>

      {showPicker ? (
        <div style={popover as any}>
          <div style={cover as any} onClick={() => setShowPicker(false)} />
          <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <SketchPicker
              color={f.values.color || 'white'}
              onChange={(c) => f.setValues({ ...f.values, color: c.hex })}
              presetColors={[
                '#f44336',
                '#e91e63',
                '#9c27b0',
                '#673ab7',
                '#3f51b5',
                '#2196f3',
                '#03a9f4',
                '#00bcd4',
                '#009688',
                '#4caf50',
                '#8bc34a',
                '#cddc39',
                '#ffeb3b',
                '#ffc107',
                '#ff9800',
                '#ff5722',
                '#795548',
                '#607d8b',
              ]}
            />
          </div>
        </div>
      ) : null}

      <Divider style={{ margin: '1em 0' }} />
      <div style={{ display: 'flex' }}>
        <Button
          startIcon={<VIcon icon={faTrash} />}
          variant="outlined"
          onClick={() => setConfirmDelete(true)}
          color="warning"
        >
          {t('delete')}
        </Button>
        <div style={{ flex: '1' }} />
        <Button startIcon={<VIcon icon={faSave} />} variant="outlined" type="submit" color="success">
          {t('save')}
        </Button>
      </div>
    </form>
  );
};
