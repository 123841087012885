import { Typography, Checkbox, ButtonBase } from '@mui/material';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { useCustomerSelection } from 'context/CustomerSelectionContext';
import { useCustomerTreeItemStyles } from './useCustomerTreeItemStyles';
import { GetCustomersQuery } from 'generated/graphql';
import { ArrayElement } from '../../utils/ArrayElement';

export const CustomerTreeItem = memo(
  ({ customer, selection }: { customer: ArrayElement<GetCustomersQuery['customers']>; selection: any }) => {
    const classes = useCustomerTreeItemStyles();
    const { isSelected, toggleSelection } = useCustomerSelection();
    const selected = isSelected !== undefined && isSelected(customer.id); // selection.selectedCustomers.indexOf(customer.id) >= 0;

    return (
      <div className={classes.root}>
        <Checkbox checked={selected} onChange={() => toggleSelection !== undefined && toggleSelection(customer.id)} />
        <ButtonBase
          id={`NavigateToCustomer${customer.id}`}
          component={Link}
          to={`/customer/${customer.id}`}
          style={{ width: '100%' }}
        >
          <div style={{ width: '100%' }}>
            <Typography align="left">{customer.name}</Typography>
            <Typography align="left" variant="caption">
              {customer.address}
            </Typography>
          </div>
        </ButtonBase>
      </div>
    );
  }
);
