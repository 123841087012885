import { Checkbox, FormControlLabel, TextField, Divider, InputAdornment, IconButton, Typography } from '@mui/material';
import { useState, useCallback, Fragment } from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useCustomerFilter } from '../../context/CustomerFilterContext';
import { useUser } from '../../context/UserContext';
import Select from 'react-select';

export const CustomerFilterControl = () => {
  const {
    showAllCustomers,
    setShowAllCustomers,
    showPublicCustomers,
    setShowPublicCustomers,
    showCustomersWithoutMap,
    setShowCustomersWithoutMap,
    customerStateFilters,
    setStateFilterById,
    customerGroupFilters,
    setGroupFilterById,
    customerCategoryFilters,
    setCategoryFilterById,
    customerTagFilters,
    setCustomerTagFilters,
    tagOptions,
    searchText,
    setSearchText,
  } = useCustomerFilter();
  const [localSearchString, setLocalSearchString] = useState<string>(searchText);

  // eslint-disable-next-line
  const delayedSet = useCallback(
    _.debounce((text) => {
      setSearchText && setSearchText(text);
    }, 500),
    [setSearchText]
  );

  const { t } = useTranslation();
  const { hasRole } = useUser();

  return (
    <Fragment>
      <div style={{ flexDirection: 'row' }}>
        {hasRole('manager') && (
          <FormControlLabel
            control={
              <Checkbox
                checked={showAllCustomers || false}
                onChange={() => {
                  setShowAllCustomers && setShowAllCustomers(!showAllCustomers);
                }}
              />
            }
            label={t('filterDialog.showAllCustomers') as string}
          />
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={showCustomersWithoutMap || false}
              onChange={() => setShowCustomersWithoutMap && setShowCustomersWithoutMap(!showCustomersWithoutMap)}
            />
          }
          label={t('Customers Without Map') as string}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showPublicCustomers || false}
              onChange={() => setShowPublicCustomers && setShowPublicCustomers(!showPublicCustomers)}
            />
          }
          label={t('Public Customers') as string}
        />
      </div>
      <Divider />
      <Typography variant="subtitle2">{t('Customer State')}</Typography>
      <div style={{ flexDirection: 'row' }}>
        {customerStateFilters.map((sf) => (
          <FormControlLabel
            key={sf.id}
            control={<Checkbox checked={sf.active} onChange={() => setStateFilterById(sf.id, !sf.active)} />}
            label={sf.state || ''}
          />
        ))}
      </div>
      <Divider />
      <Typography variant="subtitle2">{t('Customer Group')}</Typography>
      <div style={{ flexDirection: 'row' }}>
        {customerGroupFilters.map((gr) => (
          <FormControlLabel
            key={gr.id}
            control={<Checkbox checked={gr.active} onChange={() => setGroupFilterById(gr.id, !gr.active)} />}
            label={gr.name || ''}
          />
        ))}
      </div>
      <Divider />
      <Typography variant="subtitle2">{t('Customer Category')}</Typography>
      <div style={{ flexDirection: 'row' }}>
        {customerCategoryFilters.map((cat) => (
          <FormControlLabel
            key={cat.id}
            control={<Checkbox checked={cat.active} onChange={() => setCategoryFilterById(cat.id, !cat.active)} />}
            label={cat.name || ''}
          />
        ))}
      </div>
      <Typography variant="caption">{t('Tags')}</Typography>
      {tagOptions && (
        <Select
          isClearable
          isMulti={true}
          onChange={(v) => {
            setCustomerTagFilters && setCustomerTagFilters((v || []).map((d: any) => d.value));
          }}
          value={(customerTagFilters || []).map((c) =>
            tagOptions.map((d) => ({ value: d.id, label: d.name })).find((d: any) => d.value === c)
          )}
          defaultValue={[]}
          options={(tagOptions || []).map((d) => ({
            value: d.id,
            label: d.name,
          }))}
          styles={{
            control: (provided, state) => ({
              ...provided,
              background: 'white',
              boxShadow: 'none',
              borderColor: 'gray',
              '&:hover': {
                borderColor: 'gray',
                boxShadow: 'none',
              },
            }),
            option: (provided, state) => ({
              ...provided,
              background: state.isSelected ? 'gray' : 'white',
            }),
            menu: (base) => ({
              ...base,
              zIndex: 100,
            }),
          }}
        />
      )}
      <div style={{ padding: '7px 0' }}>
        <TextField
          value={localSearchString}
          fullWidth
          label={t('Customer Search')}
          onChange={(e) => {
            setLocalSearchString(e.target.value);
            delayedSet(e.target.value);
          }}
          InputProps={{
            endAdornment: localSearchString.length > 0 && (
              <InputAdornment position="end">
                <IconButton
                  id={`ClearSearch`}
                  onClick={(e) => {
                    setLocalSearchString('');
                    delayedSet('');
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </Fragment>
  );
};
