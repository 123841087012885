import React, { useState } from 'react';
import { ImageList, ImageListItem, Dialog, SvgIcon, IconButton, Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBars, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useSwipeable } from 'react-swipeable';
import YesNoDialog from 'components/dialog/YesNoDialog';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { VImage } from 'components/VImage';
import { GetVisitUploadsQuery } from 'generated/graphql';
import { ArrayElement } from 'utils/ArrayElement';
export const useStyles = makeStyles((theme: any) => ({
  root: {
    position: 'absolute',
    top: '0px',
    bottom: '0px',
    right: '0px',
    left: '0px',
    padding: '10px',
    overflow: 'hidden',
  },
  flex: {
    flex: 1,
    display: 'flex',
  },

  flexChild: {
    width: '100%',
    display: 'grid',
    justifyContent: 'center',
  },
  static: {
    marginTop: '10px',
    width: '100%',
  },
  gridList: {
    scrollDirection: 'horizontal',
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  fabBase: {
    zIndex: 9999,
    margin: 0,
    position: 'fixed',
  },
}));

export const ImageViewer = ({
  open,
  uploads,
  onClose,
  onSelectionChanged,
  onDelete,
  selectedId: extId,
}: {
  open: boolean;
  uploads: GetVisitUploadsQuery['uploads'];
  onClose: () => void;
  onSelectionChanged: (a: number) => void;
  onDelete: (id: number) => void;
  selectedId: number;
}) => {
  const classes = useStyles();
  const [conf, setConf] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [selectedId, setSelectedId] = useState(extId);
  const [xOff, setXOff] = useState(0);
  const { t } = useTranslation();
  const n = uploads.filter((d) => d.id > selectedId);
  const onNext = n.length
    ? () => {
        if (!n.length) {
          return;
        }
        const nid = (_ as any).minBy(n, (d: ArrayElement<GetVisitUploadsQuery['uploads']>) => d.id).id;
        setSelectedId(nid);
      }
    : null;

  const p = uploads.filter((d) => d.id < selectedId);
  const onPrevious = p.length
    ? () => {
        if (!p.length) {
          return;
        }
        const pid = (_ as any).maxBy(p, (d: ArrayElement<GetVisitUploadsQuery['uploads']>) => d.id).id;
        setSelectedId(pid);
      }
    : null;

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      onNext && onNext();
      setXOff(0);
    },
    onSwipedRight: () => {
      onPrevious && onPrevious();
      setXOff(0);
    },
    onSwiping: (eventData) => setXOff(eventData.dir === 'Left' ? -eventData.absX : eventData.absX),
  });

  if (!open) {
    return null;
  }

  return (
    <Dialog fullScreen open={true} onKeyDown={(e) => e.key === 'Escape' && onClose()}>
      <YesNoDialog
        isOpen={conf}
        noAction={() => setConf(false)}
        title={t('Delete Upload')}
        text={t('DeleteUploadText')}
        yesAction={() => {
          onDelete && onDelete(selectedId);
          setConf(false);
          setAnchorEl(null);
          // if we have a rpevious picture
          if (onPrevious) {
            onPrevious();
            return;
          }
          // else go next picture
          if (onNext) {
            onNext();
            return;
          }

          //otherwise close because there are no pictures left
          onClose();
        }}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => setConf(true)}>{t('Delete')}</MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            onClose();
          }}
        >
          {' '}
          {t('Close')}
        </MenuItem>
      </Menu>{' '}
      <IconButton
        onClick={onClose}
        className={classes.fabBase}
        style={{
          position: 'absolute',
          top: 10,
          left: 10,
          border: '1px solid grey',
        }}
      >
        <SvgIcon>
          <FontAwesomeIcon icon={faTimes} />
        </SvgIcon>
      </IconButton>
      <IconButton
        onClick={(event) => setAnchorEl(event?.currentTarget)}
        className={classes.fabBase}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          border: '1px solid grey',
        }}
      >
        <SvgIcon>
          <FontAwesomeIcon icon={faBars} />
        </SvgIcon>
      </IconButton>
      {onNext && (
        <IconButton
          className={classes.fabBase}
          style={{
            position: 'absolute',
            top: '50%',
            right: 10,
          }}
          onClick={onNext}
        >
          <SvgIcon>
            <FontAwesomeIcon icon={faChevronRight} />
          </SvgIcon>
        </IconButton>
      )}
      {onPrevious && (
        <IconButton
          className={classes.fabBase}
          style={{
            position: 'absolute',
            top: '50%',
            left: 10,
          }}
          onClick={onPrevious}
        >
          <SvgIcon>
            <FontAwesomeIcon icon={faChevronLeft} />
          </SvgIcon>
        </IconButton>
      )}
      <div className={classes.root}>
        <div className={classes.flex}>
          <div className={classes.flexChild} {...handlers}>
            <VImage
              style={{
                maxHeight: '80vh',
                maxWidth: '100vw',
                margin: 'auto',
                objectFit: 'contain',
                marginLeft: xOff + 'px',
              }}
              src={(uploads.find((d) => d.id === selectedId) || {}).path || ''}
            />
          </div>
        </div>
        <div className={classes.static}>
          <ImageList className={classes.gridList} rowHeight={120} cols={7}>
            {uploads &&
              uploads.map((u, i) => (
                <ImageListItem key={`image-${i}`} onClick={() => setSelectedId(u.id)}>
                  <VImage style={{ maxHeight: '80px', maxWidth: '50px' }} src={u.path + '_thumb'} />
                </ImageListItem>
              ))}
          </ImageList>
        </div>
      </div>
    </Dialog>
  );
};
