import { faPen } from '@fortawesome/free-solid-svg-icons';
import { Box, Divider, Grid, IconButton } from '@mui/material';
import { VDownloadLink } from 'components/VDownloadLink';
import { format, parseISO } from 'date-fns';
import { VIcon } from 'components/VIcon';
import React from 'react';
import { humanFileSize } from './humanFileSize';
import { PreviewComponent } from './PreviewComponent';
import { GetDocumentsQuery } from 'generated/graphql';
import { ArrayElement } from 'utils/ArrayElement';

export const DocumentListItem = ({
  document,
  selectDocument,
}: {
  document: ArrayElement<GetDocumentsQuery['document']>;
  selectDocument: () => void;
}) => {
  const upl = document.uploads.length > 0 ? document.uploads[0] : null;

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <div
        style={{
          border: '1px lightgray solid',
          height: '100%',
          overflow: 'hidden',
          borderRadius: '0.25em',
          padding: '1em',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <Box fontSize={12}>{document.id}</Box>
          <div style={{ marginLeft: '0.2em' }}>{document.name}</div>
          <div style={{ flex: 1 }} />
          <IconButton onClick={selectDocument}>
            <VIcon icon={faPen} />
          </IconButton>
        </div>
        <div style={{ flex: 1 }} />
        <Divider style={{ margin: '0.4em 0' }} />
        {upl && <PreviewComponent upload={upl} />}
        <Divider style={{ margin: '0.4em 0' }} />
        <div style={{ display: 'flex', gap: '20px' }}>
          {upl && <Box fontSize={10}>{upl.type}</Box>}
          {upl && <Box fontSize={10}>{humanFileSize(upl.size || 0)}</Box>}
          {upl && <Box fontSize={10}>{format(parseISO(upl.mtime), 'HH:mm')}</Box>}
          {upl && <Box fontSize={10}>{format(parseISO(upl.mtime), 'dd.MM.yyyy')}</Box>}
        </div>
        <VDownloadLink src={upl?.path || ''} name={document.name} mime={upl?.type || ''} />
      </div>
    </Grid>
  );
};
