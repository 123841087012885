import {
  Checkbox,
  FormControlLabel,
  LinearProgress,
  Pagination,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { drop, includes } from 'lodash';
import { Fragment, useState } from 'react';
import { VIcon } from 'components/VIcon';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import { useMessages } from 'context/MessageContext';
import { WizardNavs } from './WizardNavs';
import { GetCustomerQuery, useGetDocumentsQuery, useGetProductsFromSupplierQuery } from 'generated/graphql';

const defaultMessage = `Sehr geehrte Damen und Herren,
bitte finden Sie im Anhang die vereinbarten Dokumente.
Mit freundlichen Grüßen
Ihr Sales Team`;

export const SendDocumentsWizard = ({
  customer,
  close,
}: {
  customer: GetCustomerQuery['customers_by_pk'];
  close: () => void;
}) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [emails, setEmails] = useState<string[]>([]);
  const [documentIds, setDocumentIds] = useState<number[]>([]);
  const [productIds, setProductIds] = useState<number[]>([]);
  const [search, setSearch] = useState('');
  const [pageNum, setPageNum] = useState(0);
  const [supplierId, setSupplierId] = useState<number | null | undefined>(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState(defaultMessage);
  const [subject, setSubject] = useState('Dokumente');
  const { success, error } = useMessages();

  const { data: docs } = useGetDocumentsQuery();
  const { data: prods, loading: lProds } = useGetProductsFromSupplierQuery({
    variables: { where: { supplier_id: { _eq: supplierId || 0 } } },
  });

  const handleEmailChange = (email: string) => (e: React.ChangeEvent<HTMLInputElement>) =>
    e.target.checked ? setEmails([...emails, email || '']) : setEmails([...emails.filter((d) => d !== email)]);
  const handleDocumentChange = (id: number) => (e: React.ChangeEvent<HTMLInputElement>) =>
    e.target.checked ? setDocumentIds([...documentIds, id]) : setDocumentIds([...documentIds.filter((d) => d !== id)]);
  const handleProductChange = (id: number) => (e: React.ChangeEvent<HTMLInputElement>) =>
    e.target.checked ? setProductIds([...productIds, id]) : setProductIds([...productIds.filter((d) => d !== id)]);

  const _prds = supplierId ? prods?.suppliers.map((d) => d.product) : prods?.products;

  const contactsWithMail = customer?.contacts.filter((d) => d.contact?.email);
  const gotCustomerMail = customer?.email && customer?.email?.trim() !== '';
  const gotAnyMailAdresses = contactsWithMail?.length || gotCustomerMail;

  const hasRecipients = emails.length;
  const hasDocuments = documentIds.length || productIds.length;
  const canSend = hasRecipients && hasDocuments;

  const onPrevious = () => {
    setPageNum(1);
    setSearch('');
    setActiveStep(activeStep - 1);
  };

  const onNext = () => {
    setPageNum(1);
    setSearch('');
    setActiveStep(activeStep + 1);
  };

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      <Step>
        <StepLabel error={activeStep > 0 && emails.length === 0} onClick={() => setActiveStep(0)}>
          {t('email.chooseRecipients')}
        </StepLabel>
        <StepContent>
          {!gotAnyMailAdresses && <div>{t('email.couldNotFindEmails')}</div>}
          {gotCustomerMail && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={includes(emails, customer?.email)}
                  onChange={handleEmailChange(customer?.email || '')}
                />
              }
              label={customer?.email || ''}
            />
          )}

          {contactsWithMail?.map((contact) => (
            <FormControlLabel
              key={contact.contact?.id}
              control={
                <Checkbox
                  checked={includes(emails, contact?.contact?.email)}
                  onChange={handleEmailChange(contact?.contact?.email || '')}
                />
              }
              label={contact?.contact?.email || ''}
            />
          ))}
          <WizardNavs onNext={onNext} onPrevious={onPrevious} activeStep={activeStep} maxStep={5} />
        </StepContent>
      </Step>
      <Step>
        <StepLabel onClick={() => setActiveStep(1)}>{t('email.chooseDocuments')}</StepLabel>
        <StepContent>
          <TextField
            fullWidth
            label={t('Search')}
            value={search}
            onChange={(e) => {
              setPageNum(1);
              setSearch(e.target.value);
            }}
          />
          <Typography variant="h6">{t('email.documents')}</Typography>
          <Pagination
            count={Math.ceil((docs?.document.filter((doc) => new RegExp(search, 'i').test(doc.name))?.length || 1) / 5)}
            onChange={(e, v) => setPageNum(v)}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {drop(
              docs?.document.filter((doc) => new RegExp(search, 'i').test(doc.name)),
              (pageNum - 1) * 5
            )
              .slice(0, 5)
              .map((doc) => (
                <FormControlLabel
                  key={doc.id}
                  control={<Checkbox checked={includes(documentIds, doc.id)} onChange={handleDocumentChange(doc.id)} />}
                  label={doc.name || ''}
                />
              ))}
          </div>
          <WizardNavs onNext={onNext} onPrevious={onPrevious} activeStep={activeStep} maxStep={5} />
        </StepContent>
      </Step>
      <Step>
        <StepLabel onClick={() => setActiveStep(2)}>{t('email.chooseSupplier')}</StepLabel>
        <StepContent>
          <Typography>{t('email.supplierSelectionInfo')}</Typography>
          <RadioGroup name="" value={supplierId} onChange={(e, v) => setSupplierId(Number(v))}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <FormControlLabel
                style={{ marginTop: '0.5em' }}
                value={0}
                control={<Radio />}
                label={<div>{t('email.noSupplierSelected')}</div>}
              />
              {customer?.suppliers.map((sup) => (
                <FormControlLabel
                  style={{ marginTop: '0.5em' }}
                  key={sup.supplier.id}
                  value={sup.supplier.id}
                  control={<Radio />}
                  label={
                    <div>
                      {sup.supplier.name || ''}
                      <br /> {sup.supplier.address}
                    </div>
                  }
                />
              ))}
            </div>
          </RadioGroup>
          <WizardNavs onNext={onNext} onPrevious={onPrevious} activeStep={activeStep} maxStep={5} />
        </StepContent>
      </Step>
      <Step>
        <StepLabel onClick={() => setActiveStep(3)}>{t('email.articlePasses')}</StepLabel>
        <StepContent>
          <TextField
            fullWidth
            label={t('Search')}
            value={search}
            onChange={(e) => {
              setPageNum(1);
              setSearch(e.target.value);
            }}
          />
          {lProds && <LinearProgress />}
          <Typography variant="h6">{t('email.documents')}</Typography>

          <Pagination
            count={Math.ceil((_prds?.filter((prd) => new RegExp(search, 'i').test(prd.name || ''))?.length || 1) / 5)}
            onChange={(e, v) => setPageNum(v)}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {drop(
              _prds?.filter((p) => new RegExp(search, 'i').test(p.name || '')),
              (pageNum - 1) * 5
            )
              .slice(0, 5)
              .filter((p) => new RegExp(search, 'i').test(p.name || ''))
              .map((p) => (
                <FormControlLabel
                  key={p.id}
                  control={<Checkbox checked={includes(productIds, p.id)} onChange={handleProductChange(p.id)} />}
                  label={p.name || ''}
                />
              ))}
          </div>
          <WizardNavs onNext={onNext} onPrevious={onPrevious} activeStep={activeStep} maxStep={5} />
        </StepContent>
      </Step>
      <Step>
        <StepLabel onClick={() => setActiveStep(4)}>{t('email.messageEdit')} </StepLabel>
        <StepContent>
          <Typography>{t('email.message')}</Typography>
          <TextField
            style={{ marginTop: '0.5em' }}
            label={t('email.subject')}
            fullWidth
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          <TextField
            style={{ margin: '0.5em 0' }}
            label={t('email.message')}
            fullWidth
            multiline
            rows={8}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <WizardNavs onNext={onNext} onPrevious={onPrevious} activeStep={activeStep} maxStep={5} />
        </StepContent>
      </Step>
      <Step>
        <StepLabel onClick={() => setActiveStep(5)}>{t('email.summary')}</StepLabel>
        <StepContent>
          {!Boolean(hasRecipients) && <Typography color="error">{t('email.noRecipientsExplanation')}</Typography>}
          {!Boolean(hasDocuments) && <Typography color="error">{t('email.noDocumentsExplanation')}</Typography>}
          {Boolean(canSend) && (
            <Fragment>
              <Typography>{t('email.summaryMessage')}</Typography>

              <Typography>{t('email.recipients')}</Typography>
              <ul>
                {emails.map((e) => (
                  <li key={e}>{e}</li>
                ))}
              </ul>

              <Typography>{t('email.documents')}</Typography>
              <ul>
                {docs?.document
                  .filter((d) => includes(documentIds, d.id))
                  .map((e) => (
                    <li key={e.id}>{e.name}</li>
                  ))}
              </ul>
              <Typography>{t('email.articlePasses')}</Typography>
              <ul>
                {_prds
                  ?.filter((d) => includes(productIds, d.id))
                  .map((e) => (
                    <li key={e.id}>{e.name}</li>
                  ))}
              </ul>

              <Typography>{t('email.supplier')}</Typography>
              <ul>
                {customer?.suppliers
                  .filter((d) => d.supplier_id === supplierId)
                  .map((d) => (
                    <li key={d.supplier_id}>{d.supplier.name}</li>
                  ))}
              </ul>
            </Fragment>
          )}
          <LoadingButton
            variant="contained"
            color="success"
            loading={isSubmitting}
            disabled={!canSend}
            startIcon={<VIcon icon={faEnvelope} />}
            onClick={async () => {
              setIsSubmitting(true);
              try {
                await axios.post(
                  '/api/document-send',
                  { emails, documentIds, productIds, supplierId, message, subject, customerId: customer?.id },
                  { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
                );
                close();
                success(t('Successfully sent'));
              } catch (e: any) {
                error(t('Error while saving {{msg}}', { msg: JSON.stringify(e) }));
              }
              setIsSubmitting(false);
            }}
          >
            {t('Send')}
          </LoadingButton>
          <WizardNavs onNext={onNext} onPrevious={onPrevious} activeStep={activeStep} maxStep={5} />
        </StepContent>
      </Step>
    </Stepper>
  );
};
