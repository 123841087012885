import React, { useState, useEffect } from 'react';
import { LinearProgress } from '@mui/material';
import Axios from 'axios';
import { useStyles } from './VisiterDropzone';

/**
 * Display File upload
 */
export const FileUpload = ({ file: extFile, fileFinished, hide, url }: any) => {
  const classes = useStyles();

  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState<any>(null);
  const [started, setStarted] = useState(false);

  useEffect(() => {
    setFile(extFile);
    const api = async () => {
      if (!file || started) {
        return;
      }
      //console.log('start upload', file);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('name', 'test');
      file.preview = URL.createObjectURL(file);

      try {
        setStarted(true);
        // const { data: upload } =
        await Axios.post(url, formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgress(progress);
          },
        });
        setTimeout(() => {
          fileFinished && fileFinished();
        }, 200);
        setTimeout(async () => {
          //console.log('finish', file);
          hide();
        }, 2000);
      } catch (error) {
        console.log('error while upload', error);
      }
    };
    api();
  }, [file, extFile, fileFinished, hide, started, url]);

  if (!file) {
    return null;
  }

  return (
    <div style={{ width: '100%', display: 'flex', minHeight: '40px' }}>
      {file.type.includes('image') && (
        <img alt={file.name} src={file.preview} style={{ display: 'block', width: '50px' }} />
      )}
      <div style={{ flex: 1 }}>
        <LinearProgress
          variant="determinate"
          value={progress || 0}
          style={{ height: '100%' }}
          classes={{
            colorPrimary: classes.colorPrimary,
            barColorPrimary: classes.barColorPrimary,
          }}
        />
      </div>
    </div>
  );
};
