import { Button, Dialog, DialogContent, DialogTitle, List, ListItem, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TitleDisplay } from './TitleDisplay';
import { Fragment, useState } from 'react';

import { format, parseISO } from 'date-fns';
import { SendSingleDocumentWizard } from './transferOrders/SendSingleDocumentWizard';
import axios from 'axios';
import { SendDocumentsWizard } from './SendDocumentsWizard';
import { useAppSettings } from 'hooks/useAppSettings';
import { VIcon } from 'components/VIcon';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useModalFullscreen } from '../../utils/useModalFullscreen';
import { GetCustomerQuery } from 'generated/graphql';

export const SendEmailControl = ({ customer }: { customer: GetCustomerQuery['customers_by_pk'] }) => {
  const { t } = useTranslation();
  const [showSendEmailDialog, setShowSendEmailDialog] = useState(false);
  const [openSendMailWizard, setOpenSendMailWizard] = useState(false);

  const { emailCustomerSheetReceiver, emailCustomerSheetReceiverName, emailCustomerSheetText } = useAppSettings();

  const s = `${customer?.id} - Customer Sheet - ${customer?.name}`;

  const matches = useModalFullscreen();
  return (
    <Fragment>
      {
        <Dialog
          open={openSendMailWizard}
          fullScreen={matches}
          onClose={() => setOpenSendMailWizard(false)}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>
            <div style={{ display: 'flex' }}>
              {t('order.sendMail')}
              <div style={{ flex: 1 }} />
              <Button
                variant="outlined"
                onClick={() => setOpenSendMailWizard(false)}
                startIcon={<VIcon icon={faTimesCircle} />}
              >
                {t('close')}
              </Button>
            </div>
          </DialogTitle>
          <DialogContent>
            <SendSingleDocumentWizard
              subject={s}
              body={emailCustomerSheetText || ''}
              receiverEMail={emailCustomerSheetReceiver || ''}
              receiverName={emailCustomerSheetReceiverName || ''}
              refetch={() => {}}
              sendMail={async (subject, body, email) => {
                await axios.post(
                  '/api/customer-sheet-send',
                  { subject, body, email, customerId: customer?.id || 0 },
                  { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
                );
              }}
              ressourceURL={`/api/get-customer-sheet/${customer?.id || 0}`}
              close={() => setOpenSendMailWizard(false)}
            />
          </DialogContent>
        </Dialog>
      }
      <Dialog
        open={showSendEmailDialog}
        fullScreen={matches}
        onClose={() => setShowSendEmailDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <div style={{ display: 'flex' }}>
            {t('customer.sendEmailDialogTitle')}
            <div style={{ flex: 1 }} />
            <Button
              variant="outlined"
              onClick={() => setShowSendEmailDialog(false)}
              startIcon={<VIcon icon={faTimesCircle} />}
            >
              {t('close')}
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <SendDocumentsWizard customer={customer} close={() => setShowSendEmailDialog(false)} />
        </DialogContent>
      </Dialog>
      <TitleDisplay title={t('customer.sendEmail')} showAdd={true} onClick={() => setShowSendEmailDialog(true)} />
      <List>
        {customer?.email_sents?.map((s) => (
          <ListItem key={s.id}>
            <ListItemText
              primary={format(parseISO(s.sent_date), 'dd.MM.yy HH:mm ') + s.recipients}
              secondary={s.attachments}
            />
          </ListItem>
        ))}
      </List>
      {emailCustomerSheetReceiver && (
        <Button onClick={() => setOpenSendMailWizard(true)} variant="contained">
          {t('email.sendCustomerSheet')}
        </Button>
      )}
    </Fragment>
  );
};
