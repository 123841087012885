import { Button } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { useFormik } from 'formik';
import { FormikTextField } from 'pages/customer/FormikTextField';
import { VIcon } from 'components/VIcon';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { pick } from 'lodash';
import axios from 'axios';
import YesNoDialog from 'components/dialog/YesNoDialog';
import { useTranslation } from 'react-i18next';
import { GetDocumentsQuery, useUpdateDocumentMutation } from 'generated/graphql';
import { ArrayElement } from 'utils/ArrayElement';

export const DocumentEdit = ({
  document,
  refetch,
}: {
  document: ArrayElement<GetDocumentsQuery['document']>;
  refetch?: () => void;
}) => {
  const [updateDocument] = useUpdateDocumentMutation();
  const { t } = useTranslation();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const f = useFormik({
    initialValues: document,
    onSubmit: async (values) => {
      console.log(values);

      const obj = pick(values, 'name');
      await updateDocument({ variables: { id: document.id, obj } });
    },
  });

  const del = async () => {
    await axios.delete(`/api/delete-document/${document.id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    refetch && refetch();
  };

  return (
    <Fragment>
      <YesNoDialog
        title={t('document.confirmDeleteTitle')}
        text={t('document.confirmDeleteMessage')}
        isOpen={showDeleteDialog}
        noAction={() => setShowDeleteDialog(false)}
        yesAction={() => {
          del();
          setShowDeleteDialog(false);
        }}
        okColor="error"
      />
      <form onSubmit={f.handleSubmit}>
        <FormikTextField formik={f} label={t('name')} name="name" />
        <Button
          variant="outlined"
          startIcon={<VIcon icon={faTrash} />}
          color="error"
          onClick={() => setShowDeleteDialog(true)}
        >
          {t('Delete')}
        </Button>
      </form>
    </Fragment>
  );
};
