import React from 'react';
import {
  Dialog,
  DialogTitle,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { TitleDisplay } from './TitleDisplay';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetTopicsQuery } from 'generated/graphql';

export const Topics = ({ formik, name }: { formik: any; name: string }) => {
  const { t } = useTranslation();
  // const [, meta, helpers] = useField<GetVisit_visits_by_pk_topics[]>(name);
  const [showDialog, setShowDialog] = useState(false);
  const { data, error, loading } = useGetTopicsQuery();
  if (error) {
    return null; // JSON.stringify(error);
  }
  if (loading) {
    return <LinearProgress />;
  }

  const value = formik.values?.topics;
  const setValue = (s: any) => formik.setValues({ ...formik.values, topics: s });
  const topics = value;
  const allTopics = data?.topics;

  if (allTopics === undefined || allTopics === null) {
    return null;
  }

  return (
    <div>
      <TitleDisplay showAdd title={t('Topics')} onClick={() => setShowDialog(true)} />

      <List>
        {topics.map((t: any, i: any) => (
          <ListItem key={`topic-${i}`}>
            <ListItemIcon>
              <SvgIcon>
                <FontAwesomeIcon icon={faCheckSquare} />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText primary={t?.topic?.topic} />
          </ListItem>
        ))}
      </List>

      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>{t('Select Dialog')}</DialogTitle>

        <List>
          {allTopics.map((t, i) => {
            const isInVisit = topics.find((e: any) => e?.topic?.id === t.id) ? true : false;

            return (
              <ListItem
                key={`topic-${i}`}
                onClick={() =>
                  setValue(
                    isInVisit
                      ? topics.filter((e: any) => e?.topic?.id !== t.id)
                      : [...topics, { __typename: 'visits_topics', topic: t }]
                  )
                }
              >
                <ListItemIcon>
                  <SvgIcon>
                    {isInVisit ? <FontAwesomeIcon icon={faCheckSquare} /> : <FontAwesomeIcon icon={faSquare} />}
                  </SvgIcon>
                </ListItemIcon>
                <ListItemText primary={t.topic} />
              </ListItem>
            );
          })}
        </List>
      </Dialog>
    </div>
  );
};
