import React, { useState } from 'react';
import { Typography, LinearProgress, ImageList, ImageListItem } from '@mui/material';
import { ImageViewer } from './ImageViewer';
import { VisiterDropzone } from '../../components/dropzone/VisiterDropzone';
import { VImage } from 'components/VImage';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useGetVisitUploadsQuery } from 'generated/graphql';

export const ImageGalery = ({ visitId, customerId }: { visitId: number; customerId: number | null | undefined }) => {
  const { t } = useTranslation();
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const { data, loading, error, refetch } = useGetVisitUploadsQuery({
    variables: { visitId: visitId },
  });

  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }
  // const uploads = data.uploads.nodes;
  return (
    <div>
      {selectedId && (
        <ImageViewer
          uploads={data?.uploads || []}
          open={Boolean(selectedId)}
          selectedId={selectedId}
          onSelectionChanged={(id: number) => setSelectedId(id)}
          onClose={() => setSelectedId(null)}
          onDelete={async (id) => {
            await axios.delete(`/api/delete-upload/${id}`, {
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            refetch();
          }}
        />
      )}
      <div style={{ height: '30px' }} />
      <Typography variant="h5">{t('Uploads')}</Typography>
      <VisiterDropzone visitId={visitId} customerId={customerId || undefined} fileFinished={refetch} />
      <div style={{ height: '20px' }} />
      <ImageList cols={3}>
        {
          data?.uploads.map((u, i) => (
            <ImageListItem key={u.path} onClick={() => setSelectedId(u.id)}>
              <VImage src={u.path || ''} style={{ width: '100%' }} />
            </ImageListItem>
          )) as any
        }
      </ImageList>
    </div>
  );
};
