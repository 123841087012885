import {
  faChevronCircleDown,
  faChevronDown,
  faChevronUp,
  faPlus,
  faSave,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _, { groupBy, orderBy } from 'lodash';
import { ProductEdit } from './ProductEdit';
import { LinearProgressWithLabel } from './LinearProgressWithLabel';
import { VIcon } from 'components/VIcon';
import { VAvatar } from './VAvatar';
import { useModalFullscreen } from 'utils/useModalFullscreen';
import {
  GetProductsDocument,
  GetProductsQuery,
  useCreateProductMutation,
  useGetProductsQuery, 
  useUpdateProductMutation,
} from 'generated/graphql';
import { ArrayElement } from 'utils/ArrayElement';

enum Direction {
  up,
  down,
}

export const ProductsPage = () => {
  const { data, loading, error, refetch } = useGetProductsQuery({
    variables: { where: {} },
  });
  const fullScreen = useModalFullscreen();

  const [search, setSearch] = useState('');

  const f = (d: ArrayElement<GetProductsQuery['products']> | null) =>
    new RegExp(search, 'i').test((d || {}).name || '');
  const [products, setProducts] = useState(data?.products || []);

  useEffect(() => {
    setProducts(data?.products || []);
  }, [data, setProducts]);

  const [selectedProductId, setSelectedProductId] = useState<number>(0);
  const [createProduct] = useCreateProductMutation({
    refetchQueries: [{ query: GetProductsDocument }],
  });
  const { t } = useTranslation();

  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    console.error(error);
    return null;
  }

  const maxAvailableOnSingleItem = _.maxBy(data?.products, (d) => d.available_amount)?.available_amount || 0;
  const selectedProduct = data?.products?.find((d) => d.id === selectedProductId);

  const grps = orderBy(Object.entries(groupBy(products.filter(f), (d) => d.product_group?.name)), ([a]) => a); //.map(([a, b]) => console.log(a, b));

  return (
    <div>
      {selectedProduct && (
        <Dialog open={Boolean(selectedProductId)} onClose={() => setSelectedProductId(0)} fullScreen={fullScreen}>
          <DialogTitle>
            {t('Product Edit')}
            <Typography variant="overline">{` id: ${selectedProduct.id}`}</Typography>{' '}
          </DialogTitle>
          <DialogContent>
            <ProductEdit
              product={selectedProduct}
              refetch={refetch}
              close={() => setSelectedProductId(0)}
              productStyles={data?.product_style || []}
              productTypes={data?.product_type || []}
              productGroups={data?.product_group || []}
              availableSuppliers={data?.suppliers || []}
            />
          </DialogContent>
        </Dialog>
      )}
      <Button
        variant="outlined"
        startIcon={
          <SvgIcon>
            <FontAwesomeIcon icon={faPlus} />
          </SvgIcon>
        }
        onClick={async () => {
          try {
            const { data } = await createProduct();
            await refetch();
            setSelectedProductId(data?.insert_products_one?.id || 0);
          } catch (error) {
            console.error(error);
          }
        }}
      >
        {t('New Product')}
      </Button>

      <TextField
        style={{ marginTop: '0.5em' }}
        label={t('Search')}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        fullWidth
      />
      {grps.map(([group, products], i) => (
        <ProudctGroupExpander
          key={group}
          group={group}
          products={products}
          setSelectedProductId={(id) => setSelectedProductId(id)}
          maxAvailableOnSingleItem={maxAvailableOnSingleItem}
          refetch={refetch}
        />
      ))}
    </div>
  );
};

const ProudctGroupExpander = ({
  group,
  products,
  setSelectedProductId,
  maxAvailableOnSingleItem,
  refetch,
}: {
  group: string;
  products: GetProductsQuery['products'];
  setSelectedProductId: Dispatch<SetStateAction<number>>;
  maxAvailableOnSingleItem: number;
  refetch: any;
}) => {
  const { t } = useTranslation();
  const [updateProduct] = useUpdateProductMutation();
  const [_products, setProducts] = useState(products);

  const [showSaveOrder, setShowSaveOrder] = useState(false);

  useEffect(() => {
    setProducts(orderBy(products, (d) => d.order_hint));
  }, [setProducts, products]);

  const reorder = (index: number) => (direction: Direction) => {
    setShowSaveOrder(true);
    let newIndex = index + 1;
    if (direction === Direction.up) {
      newIndex = index - 1;
    }
    if (newIndex < 0 || newIndex >= products.length) {
      return;
    }
    const arr = [..._products];
    [arr[index], arr[newIndex]] = [arr[newIndex], arr[index]];
    // console.log(arr);
    setProducts(arr);
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<VIcon icon={faChevronCircleDown} />}>
        <Typography> {`${group} (${products.length})`}</Typography>
        {showSaveOrder && (
          <Button
            variant="outlined"
            startIcon={
              <SvgIcon>
                <FontAwesomeIcon icon={faSave} />
              </SvgIcon>
            }
            onClick={async () => {
              try {
                _products.map(async (p, i) => {
                  await updateProduct({ variables: { id: p.id, obj: { order_hint: i } } });
                });
                await refetch();
                setShowSaveOrder(false);
              } catch (error) {
                console.error(error);
              }
            }}
          >
            {t('Save Order')}
          </Button>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {_products.map((p, i) => (
            <ProductItem
              showUp={i > 0}
              showDown={i < products.length - 1}
              key={p.id}
              product={p}
              selectProduct={setSelectedProductId}
              maxAvailableOnSingleItem={maxAvailableOnSingleItem}
              reorder={reorder(i)}
            />
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

const ProductItem = ({
  product,
  selectProduct,
  maxAvailableOnSingleItem,
  reorder,
  showUp,
  showDown,
}: {
  product: ArrayElement<GetProductsQuery['products']>; // GetProducts_products;
  selectProduct: Dispatch<SetStateAction<number>>;
  maxAvailableOnSingleItem: number;
  reorder?: (direction: Direction) => void;
  showUp?: boolean;
  showDown?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <ListItem
        alignItems="flex-start"
        secondaryAction={
          <div>
            {showUp && (
              <IconButton edge="end" onClick={() => reorder && reorder(Direction.up)}>
                <SvgIcon>
                  <FontAwesomeIcon icon={faChevronUp} />
                </SvgIcon>
              </IconButton>
            )}
            {showDown && (
              <IconButton edge="end" onClick={() => reorder && reorder(Direction.down)}>
                <SvgIcon>
                  <FontAwesomeIcon icon={faChevronDown} />
                </SvgIcon>
              </IconButton>
            )}
            <IconButton edge="end" onClick={() => selectProduct(product.id)}>
              <SvgIcon>
                <FontAwesomeIcon icon={faWrench} />
              </SvgIcon>
            </IconButton>
          </div>
        }
      >
        <ListItemAvatar>
          <VAvatar src={product.avatar_path || ''} />
        </ListItemAvatar>
        <ListItemText
          primary={product.name}
          secondary={
            <React.Fragment>
              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                {`id: ${product.id}`} |{' '}
                {t('Listed on {{n}} customers', { n: product.customers_aggregate.aggregate?.count })}
              </Typography>
              <LinearProgressWithLabel
                label={t('Available')}
                max={maxAvailableOnSingleItem}
                value={product.available_amount || 0}
                component={'span'}
              />
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </React.Fragment>
  );
};
