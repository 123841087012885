import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Slider, Typography } from '@mui/material';
import { ColoringMode, useMarkerColor } from 'context/MapColorContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import chroma from 'chroma-js';
import { useUser } from 'context/UserContext';
export const MarkerColorSettings = () => {
  const { t } = useTranslation();
  const { hasRole } = useUser();
  const { mode, setMode, coloringLevels, setColoringLevels } = useMarkerColor();

  const s = chroma.scale(['green', 'yellow', 'red']).domain([0, ...coloringLevels]);

  return (
    <div>
      <FormControl>
        <FormLabel>Color Mode</FormLabel>
        <RadioGroup row onChange={(e) => setMode && setMode(Number(e.target.value))} value={mode}>
          <FormControlLabel value={ColoringMode.Unicolor} control={<Radio />} label={t('Unicolor') as string} />
          {hasRole('manager') && (
            <FormControlLabel value={ColoringMode.User} control={<Radio />} label={t('User based') as string} />
          )}
          <FormControlLabel
            value={ColoringMode.LastVisit}
            control={<Radio />}
            label={t('Since last visit') as string}
          />
        </RadioGroup>

        {mode === ColoringMode.LastVisit && (
          <div>
            <Typography gutterBottom>{t('Color Ranges')}</Typography>
            <Slider
              max={400}
              track={false}
              defaultValue={coloringLevels || [0, 0]}
              style={{ marginTop: '4em' }}
              onChange={(e, vals) => setColoringLevels && setColoringLevels(vals as any)}
              valueLabelDisplay="on"
              valueLabelFormat={(x, i) =>
                i === 0 ? (
                  <div style={{ paddingBottom: '2em' }}>{` ${t('Yellow')} ${x} days`}</div>
                ) : (
                  `${t('Red')} ${x} days`
                )
              }
            />
            <div style={{ display: 'flex' }}>
              {Array.from(Array(100).keys()).map((d) => (
                <div key={d} style={{ height: '20px', backgroundColor: s(d * 4).hex(), width: '1%' }} />
              ))}
            </div>
          </div>
        )}
      </FormControl>
    </div>
  );
};
