import { faCheckCircle, faPaperPlane, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, CircularProgress, Divider, Grid, TextField, Tooltip, Typography } from '@mui/material';
import Axios from 'axios';
import YesNoDialog from 'components/dialog/YesNoDialog';
import { useFetch } from 'hooks/useFetch';
import { TitleDisplay } from 'pages/customer/TitleDisplay';
import { VIcon } from 'components/VIcon';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const MailSettings = () => {
  const { t } = useTranslation();

  const { data, loading, refetch } = useFetch(`/api/email-status`);
  const [dest, setDest] = useState('');

  const [sending, setSending] = useState(false);
  const [confirm, setConfirm] = useState(false);

  // useEffect(() => {
  //   const work = async () => {
  //     try {
  //       const a = await Axios.get(`/api/email-status`, {
  //         headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  //       });
  //       console.log(a);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   work();
  // }, []);
  const sendMail = async (destination: string) => {
    setSending(true);
    await Axios.post(
      `/api/email-send-test`,
      { destination },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      }
    );
    setSending(false);
  };

  return (
    <div>
      <YesNoDialog
        title={t('settings.sureToSendTestMailHeadline')}
        text={t('settings.sureToSendTestEmailText', { email: dest })}
        isOpen={confirm}
        noAction={() => setConfirm(false)}
        yesAction={() => {
          sendMail(dest);
          setConfirm(false);
        }}
      />
      <TitleDisplay title={t('settings.mailServerSettingsHeadline')} />
      <Divider style={{ margin: '0 0 1em 0 ' }} />
      <Typography>{t('settings.mailServerStatusHeadline')}</Typography>
      <Divider style={{ margin: '0 0 1em 0 ' }} />
      <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
        <Button onClick={() => refetch()} variant="outlined" style={{ marginRight: '1em' }}>
          refetch
        </Button>
        {loading && <CircularProgress size={20} />}
        {!loading && data?.success && <VIcon icon={faCheckCircle} color="green" />}
        {!loading && !data?.success && <VIcon icon={faTimesCircle} color="red" />}
      </div>
      {data?.success && (
        <Fragment>
          <div style={{ height: '2em' }} />

          <Typography>{t('settings.sendTestEmailHeadline')}</Typography>
          <Divider style={{ margin: '0 0 1em 0 ' }} />
          <Grid container>
            <Grid item xs={8}>
              <TextField
                fullWidth
                label={t('settings.sendTestEmailDestination')}
                type="email"
                value={dest}
                onChange={(e) => setDest(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <Tooltip title={t('settings.sendTestEmail') + ''}>
                <span>
                  <Button
                    disabled={!dest || sending}
                    onClick={() => dest && setConfirm(true)}
                    startIcon={<VIcon icon={faPaperPlane} />}
                    style={{ height: '100%' }}
                    fullWidth
                    variant="outlined"
                  />
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Fragment>
      )}
    </div>
  );
};
