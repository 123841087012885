import { Button, Dialog, DialogContent, DialogTitle, LinearProgress, useMediaQuery } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { TitleDisplay } from '../TitleDisplay';
import { Fragment, useState } from 'react';
import { TransferOrderEdit } from './TransferOrdersDialog';
import { TransferOrderDisplay } from './TransferOrderDisplay';
import { VIcon } from 'components/VIcon';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { GetTransferOrdersDocument, useDeleteTransferOrderMutation, useGetTransferOrdersQuery } from 'generated/graphql';

export const TransferOrders = ({ customerId, customerGroupId }: { customerId: number; customerGroupId: number }) => {
  const { t } = useTranslation();

  const [editTransferOrderId, setEditTransferOrderId] = useState<number>(0);
  const { data, loading, error, refetch } = useGetTransferOrdersQuery({
    variables: { customerId },
  });

  const [deleteTransferOrder] = useDeleteTransferOrderMutation();

  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  if (!data || loading) {
    return <LinearProgress />;
  }
  if (error) {
    console.log(JSON.stringify(error));
    return null;
  }
  // FIXME: FUCK.. this is bad :D we really need a concept
  const title = customerGroupId === 2 ? t('Off Trade Orders') : t('Transfer Orders');

  if (data?.transfer_orders === null || data?.transfer_orders === undefined) {
    return null;
  }
  const transferOrders = data?.transfer_orders;
  return (
    <Fragment>
      <Dialog fullScreen={matches} open={Boolean(editTransferOrderId)} onClose={() => setEditTransferOrderId(0)}>
        <DialogTitle>
          <div style={{ display: 'flex' }}>
            {editTransferOrderId > 0
              ? t('{{id}} | Edit Transfer Order', { id: editTransferOrderId })
              : t('Create Transfer Order')}
            <div style={{ flex: 1 }} />
            <Button
              variant="outlined"
              onClick={() => setEditTransferOrderId(0)}
              startIcon={<VIcon icon={faTimesCircle} />}
            >
              {t('cancel')}
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          {editTransferOrderId && (
            <TransferOrderEdit
              editTransferOrderId={editTransferOrderId}
              customerId={customerId}
              suppliers={data.customers_by_pk?.suppliers || []}
              close={() => setEditTransferOrderId(0)}
            />
          )}
        </DialogContent>
      </Dialog>

      <TitleDisplay
        title={title}
        showAdd
        onClick={async () => {
          // -1 to create a new transfer order
          setEditTransferOrderId(-1);
        }}
      />

      {transferOrders.map((transferOrder, i) => (
        <TransferOrderDisplay
          refetch={refetch}
          onEdit={() => {
            setEditTransferOrderId(transferOrder.id);
          }}
          key={i}
          transferOrder={transferOrder}
          // customerId={customerId}
          deleteTransferOrder={async () => {
            await deleteTransferOrder({
              variables: { transferOrderId: transferOrder.id },
              refetchQueries: [{ query: GetTransferOrdersDocument, variables: { customerId } }],
            });
          }}
        />
      ))}
    </Fragment>
  );
};
