import React, { useCallback, useContext, useState } from 'react';
import { Severities } from '../types/Severities';

export type Message = {
  id: number;
  time: Date;
  severity: Severities;
  timeout: number;
  text: string;
  show: boolean;
};

export type ContextProps = {
  messages: Message[];
  create: (severity: number, message: string, timeout?: number) => number;
  error: (message: string, timeout?: number) => number;
  warning: (message: string, timeout?: number) => number;
  success: (message: string, timeout?: number) => number;
  info: (message: string, timeout?: number) => number;
  remove: (id: number) => void;
  hide: (id: number) => void;
  clear: () => void;
};

export const MessageContext = React.createContext<ContextProps>({
  messages: [],
  create: (severity: number, message: string, timeout?: number) => 0,
  error: (message: string, timeout?: number) => 0,
  warning: (message: string, timeout?: number) => 0,
  success: (message: string, timeout?: number) => 0,
  info: (message: string, timeout?: number) => 0,
  remove: (id: number) => {},
  hide: (id: number) => {},
  clear: () => {},
});

export const useMessages = () => {
  return useContext(MessageContext);
};

let id = 6;
export const MessageContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [messages, setMessages] = useState<Message[]>([]);

  const create = useCallback(
    (severity: Severities, text: string, timeout?: number) => {
      const time = new Date();
      const m: Message = {
        id: id++,
        time,
        severity,
        timeout: timeout || 10,
        text,
        show: true,
      };
      setMessages((messages) => [...messages, m]);
      return m.id;
    },
    [setMessages]
  );

  const error = (message: string, timeout?: number): number => create(Severities.error, message, timeout);
  const warning = (message: string, timeout?: number): number => create(Severities.warning, message, timeout);
  const success = (message: string, timeout?: number): number => create(Severities.success, message, timeout);
  const info = (message: string, timeout?: number): number => create(Severities.info, message, timeout);

  const remove = useCallback(
    (id: number) => {
      setMessages((messages) => messages.filter((t) => t.id !== id));
    },
    [setMessages]
  );

  const hide = useCallback(
    (id: number) => {
      setMessages((messages) =>
        messages.map((message) => {
          if (id === message.id) {
            return { ...message, show: false };
          }
          return message;
        })
      );
    },
    [setMessages]
  );

  const clear = useCallback(() => {
    setMessages((messages) =>
      messages.map((message) => {
        return { ...message, show: false };
      })
    );
  }, [setMessages]);

  return (
    <MessageContext.Provider
      value={{
        messages,
        create,
        remove,
        hide,
        error,
        warning,
        success,
        info,
        clear,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};
