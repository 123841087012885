import { LinearProgress, Paper, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { format, parseISO } from 'date-fns';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetTransferOrdersWhereQuery, GetTransferOrdersWhereQuery } from 'generated/graphql';
import { ArrayElement } from 'utils/ArrayElement';

const OrderOveriew = () => {
  const { t } = useTranslation();

  const { data, loading, error } = useGetTransferOrdersWhereQuery({ variables: {} });

  if (error) {
    console.error(error);
  }

  return (
    <Fragment>
      {loading && <LinearProgress />}
      {t('orderOverview.title')}
      {/* {JSON.stringify(data)} */}

      {data?.transfer_orders?.slice(0, 4).map((order) => (
        <OrderOveriewItem order={order} key={order.id} />
      ))}
    </Fragment>
  );
};

export default OrderOveriew;

export const OrderOveriewItem = ({
  order,
}: {
  order: ArrayElement<GetTransferOrdersWhereQuery['transfer_orders']>;
}) => {
  const { t } = useTranslation();

  const invoiceContact = order.customer?.contacts.find((d) => d?.contact?.is_invoice_contact)?.contact;
  const deliveryContact = order.customer?.contacts.find((d) => d?.contact?.is_delivery_contact)?.contact;

  return (
    <Paper style={{ padding: '1em', margin: '1em 0' }}>
      {t('orderOverviewItem.title')}

      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell align="right">{t('orderViewItem.id')}</TableCell>
            <TableCell>{order.id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">{t('orderViewItem.date')}</TableCell>
            <TableCell>{order.date && format(parseISO(order.date), 'dd.MM.yyyy')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">{t('orderViewItem.customer')}</TableCell>
            <TableCell>{order.customer?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">{t('orderViewItem.customerAdress')}</TableCell>
            <TableCell>{`${order.customer?.address}`}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="right">{t('orderViewItem.vat')}</TableCell>
            <TableCell>{'vat'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">{t('orderViewItem.invoiceContactName')}</TableCell>
            <TableCell> {invoiceContact ? `${invoiceContact?.firstname} ${invoiceContact?.name}` : '-'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">{t('orderViewItem.invoiceAddress')}</TableCell>
            <TableCell>{invoiceContact?.address || '-'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">{t('orderViewItem.deliveryContactName')}</TableCell>
            <TableCell> {deliveryContact ? `${deliveryContact?.firstname} ${deliveryContact?.name}` : '-'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">{t('orderViewItem.deliveryAddress')}</TableCell>
            <TableCell>{deliveryContact?.address || '-'}</TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell align="right">{t('orderViewItem.')}</TableCell>
            <TableCell>{order.date}</TableCell>
          </TableRow> */}
          <TableRow>
            <TableCell align="right">{t('orderViewItem.items')}</TableCell>
            <TableCell>
              <ul>
                {order.items.map((item) => (
                  <li key={item.id}>{`${(item.count || 0) + (item.amount_free || 0)} (${item.count}+${
                    item.amount_free
                  }) ${item.product?.name}`}</li>
                ))}
              </ul>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};
