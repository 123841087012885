import {
  Button,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ToggleButton,
  Typography,
} from '@mui/material';
import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from 'components/dropzone/baseStyle';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import { first, identity, includes, max, min, remove } from 'lodash';
import { faCheckCircle, faCheckToSlot, faCloudArrowUp, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { VIcon } from 'components/VIcon';

import { DataMappingsDisplay } from './DataMappingsDisplay';
import { Mapping, Stats, IdMatching, MappingType, Target } from './MappingTypes';
import { checkAccess } from './utils/checkAccess';
import { MappingDisplay } from './MappingDisplay';
import { StatsDisplay } from './StatsDisplay';
import { buildMapFn } from './utils/utils';
import { enrichmentFn } from './enrichmentFn';
import { possbileMappings } from './possbileMappings';
import YesNoDialog from 'components/dialog/YesNoDialog';
import { useMessages } from 'context/MessageContext';
import {
  CreateCustomersMutation,
  Customers_Insert_Input,
  GetImportDataQuery,
  useCreateCustomersMutation,
  useGetImportDataQuery,
} from 'generated/graphql';

const findMatchingIds = (
  mappings: Mapping[] | null,
  newData: any[],
  data: GetImportDataQuery | undefined
): IdMatching[] => {
  // get configured id column
  if (!mappings || !data) {
    return [];
  }
  const m = mappings.find((d) => d.target === 'id');
  if (!m || !m.source) {
    return [];
  }
  // console.log(newData, data);
  const res: IdMatching[] = newData
    .map((nd) => {
      return { newRecord: nd, dbRecord: data.customers.find((od) => od.id === Number(nd[m.source || ''])) };
    })
    .filter((d) => d.dbRecord);

  // if
  return res;
};

const ImportData = () => {
  const { t } = useTranslation();
  const { error, success } = useMessages();

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [createCustomers] = useCreateCustomersMutation();
  const { data, loading: loadingData } = useGetImportDataQuery();

  const [loading, setLoading] = useState(false);
  const [rawData, setRawData] = useState<any>(null);
  const [dataKeys, setDataKeys] = useState<string[]>([]);
  const [checkMappingEnabled, setCheckMappingEnabled] = useState(true);

  const [mappings, _setMappings] = useState<Mapping[] | null>(null);
  const setMappings = (m: Mapping[] | null) => {
    _setMappings(m);
    setCalcTrigger(new Date());
  };
  const [stats, setStats] = useState<Stats | null>(null);
  const [calcTrigger, setCalcTrigger] = useState(new Date());
  const [confirmImport, setConfirmImport] = useState(false);
  // const [mappedData, setMappedData] = useState<ImportCustomer[]>([]);
  const [importStats, setImportStats] = useState<CreateCustomersMutation | null | undefined>(null);

  const tryMapData = (dat: any) => {
    const mappings: Mapping[] = possbileMappings.map((maps) => ({
      type: maps.type,
      target: maps.target,
      source: checkAccess(dat, maps.options),
    }));

    setMappings(mappings);
  };

  const checkDataMapping = () => {
    console.log('checking....');
    //main data accessor
    const mapFn = buildMapFn(mappings || []);
    const similaritySource = rawData.map(mapFn);
    // console.log(similaritySource);

    var duplicates = similaritySource.reduce(function (acc: any, el: any, i: any, arr: any) {
      if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push({ duplicate: i, original: arr.indexOf(el) });
      return acc;
    }, []);

    setStats({
      count: rawData.length,
      duplicates: duplicates,
      idMatching: findMatchingIds(mappings || [], rawData, data),
    });

    // console.log(duplicates);
    if (!data) {
      return;
    }
    const enrichedMapping = mappings?.map((d) => enrichmentFn(d, rawData, data));
    // console.log(enrichedMapping);
    _setMappings(enrichedMapping || []);
  };

  useEffect(() => {
    if (checkMappingEnabled && rawData) {
      checkDataMapping();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData, calcTrigger, data, checkMappingEnabled]);

  const onLoad = async (e: any) => {
    setLoading(true);
    try {
      var bstr = e?.target?.result;
      var workbook = XLSX.read(bstr, { type: 'binary' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const json = XLSX.utils.sheet_to_json(sheet, { defval: null });
      setRawData(json);
      setStats(null);
      setDataKeys(Object.keys(first(json) as any));
      tryMapData(first(json));
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const removeEntriesById = (ids?: any[], indexes?: any[]) => {
    console.log(ids, indexes);

    let dat = [...rawData];
    if (ids) {
      const idField = mappings?.find((d) => d.target === 'id')?.source;
      if (!idField) {
        return;
      }
      dat = remove(dat, (v: any, i) => !includes(ids, v[idField]));
    }
    if (indexes) dat = remove(dat, (v: any, i) => !includes(indexes, i));
    setRawData(dat);
  };

  const alterEntries = (alterFn: (obj: any) => any) => {
    const newData = [...rawData].map(alterFn);
    // console.log('new', newData);
    setRawData(newData);
  };

  const onDrop = (files: any) => {
    files.forEach((file: any) => {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = onLoad;
      reader.readAsBinaryString(file);
    });
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  if (loadingData) {
    return <LinearProgress />;
  }

  const doImport = async () => {
    try {
      const items: Customers_Insert_Input[] = rawData.map((dat: any) => {
        return Object.fromEntries(
          mappings
            ?.map((m) => {
              let iv = dat[m.source || ''];
              if (typeof iv === 'string' || iv instanceof String) {
                iv = iv?.trim();
              }
              if (iv === undefined || iv === null) {
                return null;
              }

              let vals = [iv];
              if (m.target === Target.tags) {
                vals = dat[m.source || '']?.split(',');
              }

              if (m.type === MappingType.Foreign) {
                if (!m.data) {
                  return null;
                }
                let mids = vals.map((c) => m?.data?.find((d) => d.originalText?.trim() === c?.trim())?.mappedData?.id);

                // console.log(m.target, mids, m);
                if (!mids || mids.length === 0) {
                  return null;
                }
                switch (m.target) {
                  case Target.group:
                    return ['customer_group_id', first(mids)];
                  case Target.user:
                    return ['user_id', first(mids)];
                  case Target.supplier:
                    return ['suppliers', { data: [{ supplier_id: first(mids) }] }];
                  case Target.tags:
                    return ['tags', { data: mids.map((d) => ({ tag_id: d })) }];
                }
              }

              return [m.target || '', iv.toString() || ''];
            })
            .filter(identity) as any[]
        );
      });

      const { data } = await createCustomers({ variables: { customers: items } });
      console.log(data);
      setImportStats(data);
      setRawData(null);
      _setMappings(null);
      success('Imported');
    } catch (e) {
      error(JSON.stringify(e));
    } finally {
      setConfirmImport(false);
    }
  };

  // console.log('raw data', rawData);
  return (
    <div style={{ overflow: 'hidden' }}>
      <YesNoDialog
        isOpen={confirmImport}
        title="Import Data"
        text={`Do you want to import ${rawData?.length} customers?`}
        noAction={() => setConfirmImport(false)}
        yesAction={async () => {
          await doImport();
        }}
      />
      <div style={{ margin: '0.5em 0' }}>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <Typography>{t('Drag n` Drop files here, or click to select a File.')}</Typography>
        </div>
      </div>

      {loading && <LinearProgress />}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          {mappings && <MappingDisplay mappings={mappings} allKeys={dataKeys} changeMapping={setMappings} />}
          {mappings && (
            <ToggleButton
              value="checkMapping"
              selected={checkMappingEnabled}
              style={{ marginTop: '1em' }}
              fullWidth
              onClick={() => setCheckMappingEnabled(!checkMappingEnabled)}
            >
              <VIcon icon={faCheckToSlot} />
              <div style={{ paddingLeft: '1em' }}>Check Data Mapping</div>
            </ToggleButton>
          )}
          {Boolean(mappings && rawData) && (
            <Button
              startIcon={<VIcon icon={faCloudArrowUp} />}
              style={{ marginTop: '1em' }}
              onClick={() => setConfirmImport(true)}
              fullWidth
              variant="contained"
            >
              Import
            </Button>
          )}
        </Grid>
        {checkMappingEnabled && mappings && (
          <Grid item xs={12} sm={6} md={8}>
            <StatsDisplay
              stats={stats}
              rawData={rawData}
              mappings={mappings || []}
              removeEntries={removeEntriesById}
              alterEntries={alterEntries}
            />
            <DataMappingsDisplay mappings={mappings || []} entries={rawData} changeEntries={setRawData} />
          </Grid>
        )}
      </Grid>
      {rawData && (
        <Fragment>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Line</TableCell>
                  {mappings?.map((d) => (
                    <TableCell key={d.target}>{d.target}</TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {rawData?.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((dat: any, i: number) => (
                  <TableRow key={i}>
                    <TableCell>{i + page * rowsPerPage + 1}</TableCell>
                    {mappings?.map((m, j) => {
                      // treating all values as lists for simplicity

                      let iv = dat[m.source || ''];
                      if (typeof iv === 'string' || iv instanceof String) {
                        iv = iv?.trim();
                      }

                      let vals = [iv];
                      if (m.target === Target.tags) {
                        vals = dat[m.source || '']?.split(',');
                      }

                      const s = {
                        display: 'flex',
                        border: '1px solid lightgray',
                        borderRadius: '0.5em',
                        padding: '0.5em',
                      };

                      return (
                        <TableCell key={j}>
                          {vals.map((val, jj) => {
                            if (m.type === MappingType.Foreign && m.data) {
                              const dm = m.data.find((d) => d.originalText?.trim() === val?.trim());

                              if (dm?.mappedData) {
                                return (
                                  <div key={jj} style={s}>
                                    <VIcon color="#7ebc59" icon={faCheckCircle} />
                                    {JSON.stringify(dm?.mappedData)}
                                  </div>
                                );
                              }
                              return (
                                <div key={jj} style={s}>
                                  <VIcon color="#d32f2f" icon={faTimesCircle} />
                                  {val === null || val === undefined ? `{NULL}` : val}
                                </div>
                              );
                            }
                            return <div key={jj}>{val}</div>;
                          })}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rawData?.length || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Fragment>
      )}

      {importStats && (
        <Table>
          <TableRow>
            <TableCell colSpan={2}>Import Successful!!!</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>affected rows</TableCell>
            <TableCell>{importStats.insert_customers?.affected_rows}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>imported customers</TableCell>
            <TableCell>{importStats.insert_customers?.returning.length}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>min index</TableCell>
            <TableCell>{min(importStats.insert_customers?.returning.map((d) => d.id))}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>max index</TableCell>
            <TableCell>{max(importStats.insert_customers?.returning.map((d) => d.id))}</TableCell>
          </TableRow>
        </Table>
      )}
    </div>
  );
};

export default ImportData;
