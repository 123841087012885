import { MappingType, Target } from './MappingTypes';

export const possbileMappings = [
  { type: MappingType.Simple, target: 'id', options: ['id'] },
  { type: MappingType.Simple, target: 'name', options: ['name'] },
  { type: MappingType.Simple, target: 'email', options: ['email', 'e-mail', 'mail'] },
  { type: MappingType.Simple, target: 'telephone', options: ['telephone', 'tel', 'telefon'] },
  { type: MappingType.Simple, target: 'road', options: ['road', 'straße'] },
  { type: MappingType.Simple, target: 'number', options: ['number', 'nummer', 'hausnummer'] },
  { type: MappingType.Simple, target: 'postcode', options: ['postcode', 'plz'] },
  { type: MappingType.Simple, target: 'city', options: ['city', 'stadt'] },
  { type: MappingType.Foreign, target: Target.group, options: ['group', 'gruppe'] },
  { type: MappingType.Foreign, target: Target.user, options: ['login', 'user', 'adm', 'mitarbeiter'] },
  { type: MappingType.Foreign, target: Target.tags, options: ['tags', 'tag'] },
  { type: MappingType.Foreign, target: Target.supplier, options: ['supplier', 'zulieferer'] },
];
