import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import format from 'date-fns/format';
import { useUser } from 'context/UserContext';
import parseISO from 'date-fns/parseISO';
import { Contact } from './Contact';
import {
  Contacts_Bool_Exp,
  UpdateContactMutation,
  useCreateContactMutation,
  useGetContactsQuery,
} from 'generated/graphql';

export const AddContactDialog = ({
  open,
  onClose,
  addContact,
  contactsWhere,
  hideNewContactButton,
}: {
  open: boolean;
  onClose: () => void;
  addContact: (contact: UpdateContactMutation['update_contacts_by_pk']) => void;
  contactsWhere?: Contacts_Bool_Exp;
  hideNewContactButton?: boolean;
}) => {
  const { id: userId } = useUser();
  const { t } = useTranslation();

  const { data, loading, error } = useGetContactsQuery({ variables: { where: contactsWhere || undefined } });
  const [createContact] = useCreateContactMutation();

  const [search, setSearch] = useState<string>('');

  const [newContactId, setNewContactId] = useState<number>(0);

  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    console.error(error);
    return null; //JSON.stringify(error);
  }
  const contacts = data?.contacts;
  return (
    <Fragment>
      <Dialog onClose={onClose} open={true}>
        <DialogTitle>
          {t('Choose Contact To Add')}
          <TextField label={t('Search')} fullWidth onChange={(e: any) => setSearch(e.target.value)} />
        </DialogTitle>
        <DialogContent>
          <List>
            {contacts &&
              contacts
                .filter((contact) => {
                  if (!search || search === '') {
                    return true;
                  }
                  return (
                    (contact.name || '').toUpperCase().includes(search.toUpperCase()) ||
                    (contact.firstname || '').toUpperCase().includes(search.toUpperCase())
                  );
                })
                .map((contact) => (
                  <ListItem key={contact.id} role={undefined} dense button onClick={(e) => addContact(contact)}>
                    <ListItemIcon>
                      <SvgIcon>
                        <FontAwesomeIcon icon={faUser} />
                      </SvgIcon>
                    </ListItemIcon>
                    <ListItemText
                      style={{ overflow: 'hidden' }}
                      primary={`${contact.firstname} ${contact.name}`}
                      secondary={`${(contact.position || {}).position || ''} ${
                        (contact.birthday && format(parseISO(contact.birthday), 'dd.MM.yyyy')) || ''
                      }`}
                    />
                  </ListItem>
                ))}
          </List>
        </DialogContent>
        {!hideNewContactButton && (
          <DialogTitle>
            <Button
              onClick={async () => {
                const { data } = await createContact({ variables: { obj: { user_id: userId } } });
                if (data?.insert_contacts_one?.id !== undefined) {
                  console.log(data?.insert_contacts_one?.id);
                  setNewContactId(data?.insert_contacts_one?.id);
                }
              }}
              fullWidth
              color="primary"
              startIcon={
                <SvgIcon>
                  <FontAwesomeIcon icon={faPlus} />
                </SvgIcon>
              }
            >
              <Typography style={{ verticalAlign: 'middle' }}>{t('Add New')}</Typography>
            </Button>
          </DialogTitle>
        )}
      </Dialog>

      <Dialog fullScreen={true} open={Boolean(newContactId)} onClose={() => setNewContactId(0)}>
        <DialogContent>
          <Contact
            contactId={newContactId}
            onCancel={() => setNewContactId(0)}
            onSave={(c) => {
              addContact(c);
              setNewContactId(0);
            }}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
