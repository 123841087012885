import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, SvgIcon } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import Axios from 'axios';
import { VAvatar } from '../products/VAvatar';

export const AppImage = () => {
  const [file, setFile] = useState<any>(null);
  const [preview, setPreview] = useState<any>(null);
  const [uploadStarted, setUploadStarted] = useState(false);
  const inpRef = useRef<any>(null);

  const avatarChangeHanlder = (event: any) => {
    setFile(event.target.files[0]);
    if (event.target.files[0]) {
      setPreview(URL.createObjectURL(event.target.files[0]));
    }
  };

  const startUpload = useCallback(async () => {
    if (!file || uploadStarted) {
      return;
    }
    setUploadStarted(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', 'test');
    try {
      setUploadStarted(true);
      await Axios.post(`/api/upload-branding`, formData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        // onUploadProgress: (progressEvent) => {
        //   // let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        //   // setProgress(progress);
        // },
      });
      cancelUpload();
    } catch (error) {
      console.log('error while upload', error);
    }
  }, [file, uploadStarted, setUploadStarted]);

  const cancelUpload = () => {
    setFile(null);
    setPreview(null);
    setUploadStarted(false);
  };
  return (
    <React.Fragment>
      <div style={{ display: 'flex' }}>
        <VAvatar
          variant="square"
          sx={{ width: 100, height: 100 }}
          onClick={() => inpRef.current.click()}
          src={preview || undefined}
        />
        {/* preview || product.avatar_path || */}
        <input
          type="file"
          ref={inpRef}
          accept="image/png, image/jpeg"
          style={{ visibility: 'hidden', width: '0px', height: '0px' }}
          name="file"
          onChange={avatarChangeHanlder}
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {file && !uploadStarted && (
            <React.Fragment>
              <IconButton onClick={startUpload} style={{ margin: 'auto' }}>
                <SvgIcon>
                  <FontAwesomeIcon icon={faCheck} />
                </SvgIcon>
              </IconButton>
              <IconButton onClick={cancelUpload} style={{ margin: 'auto' }}>
                <SvgIcon>
                  <FontAwesomeIcon icon={faTimes} />
                </SvgIcon>
              </IconButton>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
