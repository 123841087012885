import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CachedAxios from 'utils/CachedAxios';

export const VImage = ({ src, style }: { src: string; style?: any }) => {
  const [_src, setSrc] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const dit = async () => {
      if (!src) {
        return;
      }
      setLoading(true);
      try {
        const { data } = await CachedAxios.get(src || '', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          responseType: 'blob', // Important
        });
        setSrc(URL.createObjectURL(new Blob([data])));
      } catch (eee) {}
      setLoading(false);
    };
    dit();
  }, [setSrc, src]);
  if (loading) {
    return <CircularProgress />;
  }
  return <img src={_src} alt="" style={style} />;
};
