import { GetCustomersQuery, useGetCustomersQuery } from 'generated/graphql';
import { ArrayElement } from 'utils/ArrayElement';
import { useCustomerFilter } from '../context/CustomerFilterContext';

export const useCustomers = (): {
  data: GetCustomersQuery | undefined;
  loading: boolean;
  error: any;
  refetch: () => void;
} => {
  const { getFilter, searchText } = useCustomerFilter();

  const { data, loading, error, refetch } = useGetCustomersQuery({
    variables: { customerFilter: getFilter !== undefined ? getFilter() : null },
  });

  if (data === undefined || loading || error) {
    return { data, loading, error, refetch };
  }

  if (searchText !== undefined && searchText.length > 0 && data !== undefined && data.customers !== null) {
    const f = (d: ArrayElement<GetCustomersQuery['customers']> | null) =>
      new RegExp(searchText, 'i').test((d || {}).address || '') ||
      new RegExp(searchText, 'i').test((d || {}).name || '');

    // const a = nodes;

    const newData: GetCustomersQuery | undefined = {
      customers: data.customers?.filter(f),
    };

    return { data: newData, loading, error, refetch };
  }

  return { data, loading, error, refetch };
};
