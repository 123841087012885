import React from 'react';
import axios from 'axios';
import { Button } from '@mui/material';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { VIcon } from 'components/VIcon';
import { useTranslation } from 'react-i18next';

export const VDownloadLink = ({
  src,
  name,
  style,
  mime,
  label,
}: {
  src: string;
  name: string;
  mime: string;
  style?: any;
  label?: string;
}) => {
  const { t } = useTranslation();

  const download = async () => {
    if (!src) {
      return;
    }
    const { data } = await axios.get(src || '', {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      responseType: 'blob', // Important
    });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(new Blob([data]));
    link.download = assureFileExtension(name, mime);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button
      id={`DownloadLink-${name}`}
      variant="outlined"
      startIcon={<VIcon icon={faFileDownload} />}
      onClick={download}
      // href={_src}
      // download={assureFileExtension(name, mime)}
    >
      {label ? label : t('Download')}
    </Button>
  );
};

export const assureFileExtension = (name: string, mime: string): string => {
  let ext = mime.split('/').pop();
  const found = name.split('.').pop();
  if (found === ext || (found === 'jpg' && ext === 'jpeg')) {
    return name;
  }
  return name + '.' + ext;
};
