import { Checkbox, FormControlLabel, Grid, LinearProgress, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { TitleDisplay } from './TitleDisplay';
import { useMessages } from 'context/MessageContext';
import { difference } from 'utils/difference';
import { faEnvelope, faPhone, faSave } from '@fortawesome/free-solid-svg-icons';
import _, { debounce, first } from 'lodash';
import { DependingCustomers } from './DependingCustomers';
import { AddressControl } from './AddressControl';
// pick a date util library
import { ContactList } from './ContactList';
import { ProductList } from './ProductList';
import { Promotions } from './promotions/Promotions';
import { TransferOrders } from './transferOrders/TransferOrders';
import { CustomerVisits } from './CustomerVisits';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { FormikSelect } from './FormikSelect';
import { FormikMultiSelect } from './FormikMultiSelect';
import { FormikTextField } from './FormikTextField';
import { FormikCheckbox } from './FormikCheckbox';
import { VisiterFab } from 'components/AddFab';
import { SendEmailControl } from './SendEmailControl';
import { MapProvider } from 'react-map-gl';
import { usePrompt } from './useBlocker';
import { format } from 'date-fns';
import {
  GetCustomerQuery,
  useGetCustomerQuery,
  useUpdateCustomerMutation,
  GetCustomerDocument,
  Customers_Set_Input,
} from 'generated/graphql';

export const CustomerDataProvider = ({ id }: { id: Number }) => {
  const {
    data,
    loading,
    error: errorLoading,
  } = useGetCustomerQuery({
    variables: { customerId: Number(id) },
  });

  if (errorLoading) {
    console.error(errorLoading);
  }
  if (loading || data?.customers_by_pk === null || data?.customers_by_pk === undefined) {
    return <LinearProgress />;
  }

  return (
    <CustomerEdit
      customer={data?.customers_by_pk}
      customerCategories={data?.customer_categories}
      customerGroups={data?.customer_groups}
      customerStates={data?.customer_states}
      publicCustomers={data?.publicCustomers}
      tags={data.tags}
    />
  );
};

export type CustomerFormType = GetCustomerQuery['customers_by_pk'] & {
  tag_ids: number[];
  supplier_ids?: number[];
  supplier_id?: number;
  supplier_id_2?: number;
};

const CustomerEdit = ({
  customer,
  customerGroups,
  customerStates,
  customerCategories,
  publicCustomers,
  tags,
}: {
  customer: NonNullable<GetCustomerQuery['customers_by_pk']>;
  customerGroups: GetCustomerQuery['customer_groups'];
  customerStates: GetCustomerQuery['customer_states'];
  customerCategories: GetCustomerQuery['customer_categories'];
  publicCustomers: GetCustomerQuery['publicCustomers'];
  tags: GetCustomerQuery['tags'];
}) => {
  const { t } = useTranslation();
  const { info, success, clear } = useMessages();
  const [updateCustomer] = useUpdateCustomerMutation({
    refetchQueries: [{ query: GetCustomerDocument, variables: { customerId: customer.id } }],
  });
  const [showMessage, setShowMessage] = useState(false);
  const [autoSaveActive, setAutoSaveActive] = useState(true);
  const formik = useFormik<CustomerFormType>({
    initialValues: {
      ...customer,
      supplier_ids: customer.suppliers.map((d) => d.supplier_id),
      supplier_id: first(customer.suppliers || [])?.supplier_id,
      supplier_id_2: first(_.drop(customer.suppliers || [], 1))?.supplier_id,
      tag_ids: customer.tags.map((d) => d.tag?.id || 0),
    },
    onSubmit: async (values: CustomerFormType, { setSubmitting }: any) => {
      setSubmitting(true);
      clear();
      try {
        const {
          __typename,
          products,
          visits,
          contacts,
          user,
          tags,
          tag_ids,
          suppliers,
          supplier_id,
          supplier_id_2,
          supplier_ids,
          ...rest
        } = values;
        const {
          __typename: original__typename,
          products: originalProducts,
          visits: originalVisits,
          contacts: originalContacts,
          user: originalUser,
          tags: originalTags,
          ...originalRest
        } = customer;

        const result: Customers_Set_Input = difference(rest, originalRest);

        // tag handling
        const originalTagIds = originalTags.map((d) => d.tag?.id || 0);
        const newTags: number[] = difference(tag_ids, originalTagIds).filter((d: any) => d);
        const deletedTags: number[] = difference(originalTagIds, tag_ids).filter((d: any) => d);

        // contact handling
        const originalContactIds = originalContacts.map((d) => d.contact?.id);
        const currentContacts = contacts.map((d) => d.contact?.id);
        const newContacts: number[] = difference(currentContacts, originalContactIds).filter((d: any) => d);
        const deletedContacts: number[] = difference(originalContactIds, currentContacts).filter((d: any) => d);

        // product handling
        const originalProductIds = originalProducts.map((d) => d?.product?.id);
        const currentProducts = products.map((d) => d?.product?.id);
        const newProducts: number[] = difference(currentProducts, originalProductIds).filter((d: any) => d);
        const deletedProducts: number[] = difference(originalProductIds, currentProducts).filter((d: any) => d);

        // suppliers - special treatment
        const originalSupplierIds = suppliers.map((d) => d.supplier_id);
        const newSupplierIds = supplier_ids;
        const _deleteSuppliers: number[] = difference(originalSupplierIds, newSupplierIds).filter((d: any) => d);
        const _newSuppliers: number[] = difference(newSupplierIds, originalSupplierIds).filter((d: any) => d);

        if (
          _.isEmpty(result) &&
          deletedTags.length === 0 &&
          newTags.length === 0 &&
          deletedContacts.length === 0 &&
          newContacts.length === 0 &&
          deletedProducts.length === 0 &&
          newProducts.length === 0 &&
          _newSuppliers.length === 0 &&
          _deleteSuppliers.length === 0
        ) {
          showMessage && info(t('NoChangesDetected'));
          return;
        }
        await updateCustomer({
          variables: {
            id: values.id,
            patch: result,
            deletedTagIds: deletedTags,
            newTags: newTags.map((d) => ({ customer_id: values.id, tag_id: d })),
            deleteContactIds: deletedContacts,
            newContacts: newContacts.map((d) => ({ customer_id: values.id, contact_id: d })),
            deleteProductIds: deletedProducts,
            newProducts: newProducts.map((d) => ({ customer_id: values.id, product_id: d })),
            newSuppliers: (newSupplierIds || []).map((d) => ({ customer_id: values.id, supplier_id: d })),
          },
        });
        showMessage && success(t('Saved'));
      } catch (e) {
        console.log(e);
      }
      setShowMessage(false);
    },
    enableReinitialize: true,
  });

  usePrompt(t('customer.unsavedChangesWarning'), formik.dirty);
  const debounceMs = 10000;
  const [lastSaved, setLastSaved] = useState(new Date());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(
    debounce(() => {
      formik.submitForm().then(() => setLastSaved(new Date()));
    }, debounceMs),
    [debounceMs, formik.submitForm, debounce]
  );

  useEffect(() => {
    if (formik.dirty && autoSaveActive) {
      debouncedSubmit();
    }
  }, [debouncedSubmit, formik.dirty, autoSaveActive]);

  return (
    <form onSubmit={formik.handleSubmit} style={{ paddingBottom: '5em' }}>
      {formik.isSubmitting && <LinearProgress />}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">{t('Edit Customer')}</Typography>
          <Typography variant="subtitle2">
            {t('Id')}
            {': '}
            {customer.id}
          </Typography>
          <Typography variant="subtitle2">
            {t('Owner')}
            {': '}
            {(customer.user || {}).login || 'PUBLIC'}
          </Typography>
          <Typography variant="subtitle2">
            {t('customer.lastSaved')}
            {': '}
            {format(lastSaved, 'HH:mm')}
          </Typography>
          <FormControlLabel
            style={{ marginTop: '-0.5em' }}
            control={
              <Checkbox size="small" checked={autoSaveActive} onChange={() => setAutoSaveActive(!autoSaveActive)} />
            }
            label={<Typography variant="subtitle2">{t('customer.autoSaveActive')}</Typography>}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TitleDisplay title={t('Base Data')} />
          <FormikTextField formik={formik} name="name" label={t('Name')} />
          <FormikTextField
            formik={formik}
            name="email"
            label="email"
            icon={faEnvelope}
            href={`mailto:${formik.values.email}`}
          />
          <FormikTextField
            formik={formik}
            label={t('Telephone')}
            name="telephone"
            icon={faPhone}
            href={`tel:${formik.values.telephone}`}
          />
          <FormikTextField formik={formik} label={t('customerEdit.vat')} name="vat" />
          <FormikSelect
            formik={formik}
            label={t('Customer Group')}
            name="customer_group_id"
            options={customerGroups || []}
          />
          <FormikSelect
            formik={formik}
            label={t('Customer Status')}
            name="customer_state_id"
            options={customerStates.map((d) => ({ id: d.id, name: d.state })) || []}
          />
          <FormikSelect
            formik={formik}
            label={t('Customer Category')}
            name="customer_category_id"
            options={customerCategories || []}
          />
          <FormikMultiSelect
            formik={formik}
            label={t('Tags')}
            name="tag_ids"
            options={tags.map((d) => ({ ...d, value: d.id, label: d.name || '' })) || []}
            confirmText={t('tag.unlinkWarningText')}
            confirmTitle={t('tag.unlinkWarningTitle')}
            // createOption={async (name: string) => {
            //   const { data } = await createTag({
            //     variables: { name: name },
            //   });
            //   return { tag: data?.insert_tags_one };
            // }}
          />
          <FormikCheckbox formik={formik} label={t('Direct Customer')} name="direct_customer" />
          {!formik.values.direct_customer && (
            <Fragment>
              <FormikMultiSelect
                formik={formik}
                label={t('Suppliers')}
                name="supplier_ids"
                options={publicCustomers || []}
                filterOptions={(candidate, input) => {
                  if (!input) {
                    return true;
                  }

                  return (
                    candidate.data?.name?.match(new RegExp(input, 'i')) ||
                    candidate.data?.address?.match(new RegExp(input, 'i'))
                  );
                }}
                display={(o) => (
                  <div>
                    {o.name}
                    <Typography variant="subtitle2">{o.address}</Typography>
                  </div>
                )}
                confirmTitle={t('customer.deleteSupplier')}
                confirmText={t('customer.deleteSupplierWarning')}
              />
            </Fragment>
          )}
          <DependingCustomers customerId={customer.id} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TitleDisplay title={t('Address')} />
          <MapProvider>
            <AddressControl formik={formik} customer={customer} />
          </MapProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ContactList
            contactAccess="contacts"
            formik={formik}
            addContact={(c) =>
              formik.setValues({
                ...formik.values,
                contacts: _.orderBy(
                  [
                    { __typename: 'contacts_customers', contact: c },
                    ...formik.values.contacts.filter((d: any) => d?.contact?.id !== c.id),
                  ],
                  (d) => d?.contact?.firstname
                ),
              })
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ProductList formik={formik} customer={customer} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Promotions customerId={customer.id} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TransferOrders customerId={customer.id} customerGroupId={customer.customer_group_id || 0} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CustomerVisits customerId={customer.id} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SendEmailControl customer={customer} />
        </Grid>
      </Grid>
      <VisiterFab
        onClick={() => setShowMessage(true)}
        type="submit"
        position={false ? 'absolute' : 'fixed'}
        icon={faSave}
        {...{ id: 'Save' }}
      />
    </form>
  );
};
