import React, { useCallback, useContext, useState } from 'react';

type CustomerSelectionContextType = {
  selectedCustomerIds: number[];
  toggleSelection: (id: number) => void;
  isSelected: (id: number) => boolean;
  count: () => number;
  clear: () => void;
};

const CustomerSelectionContext = React.createContext<CustomerSelectionContextType>({
  selectedCustomerIds: [],
  toggleSelection: (id: number) => {},
  isSelected: (id: number) => false,
  count: () => 0,
  clear: () => {},
});

export const useCustomerSelection = () => {
  return useContext(CustomerSelectionContext);
};

export const CustomerSelectionContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedCustomerIds, setSelectedCustomerIds] = useState<number[]>([]);

  const isSelected = useCallback(
    (id: number): boolean => {
      return selectedCustomerIds.indexOf(id) > -1;
    },
    [selectedCustomerIds]
  );

  const toggleSelection = useCallback(
    (id: number): void => {
      if (selectedCustomerIds.indexOf(id) > -1) {
        // already inside - remove
        setSelectedCustomerIds(selectedCustomerIds.filter((d) => d !== id));
      } else {
        // not inside yet- insert
        setSelectedCustomerIds([...selectedCustomerIds, id]);
      }
    },
    [setSelectedCustomerIds, selectedCustomerIds]
  );

  const count = () => {
    return selectedCustomerIds.length;
  };

  const clear = useCallback(() => {
    setSelectedCustomerIds([]);
  }, [setSelectedCustomerIds]);

  return (
    <CustomerSelectionContext.Provider value={{ selectedCustomerIds, isSelected, toggleSelection, count, clear }}>
      {children}
    </CustomerSelectionContext.Provider>
  );
};
