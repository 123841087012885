import { useEffect, useState } from 'react';
import Axios from 'axios';

export const useFetch = (endpoint: string): any => {
  const [data, setData] = useState(null);
  const [refetchTime, setRefetchTime] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const refetch = () => setRefetchTime(new Date());

  useEffect(() => {
    const fetchApi = async () => {
      setLoading(true);
      const response = await Axios.get(endpoint, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      console.log(response)
      setLoading(false);
      setData(response.data);
    };

    fetchApi();
  }, [endpoint, refetchTime, setLoading]);

  return { data, loading, refetch };
};
