import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SvgIcon } from '@mui/material';
import React from 'react';
export const TreeItemLabel = ({ icon, label }: { icon: IconProp; label: string }) => (
  <div
    style={{
      display: 'inline-flex',
      alignItems: ' center',
      width: '100%',
      minHeight: '48px',
    }}
  >
    {icon && (
      <SvgIcon color="action" style={{ margin: '0px 7px' }}>
        <FontAwesomeIcon icon={icon} />
      </SvgIcon>
    )}
    {label}
  </div>
);
