import { AddressWithPos } from "types/AddressWithPos";
import { getAddressFromPosition } from "./getAddressFromPosition";

export const getAdressFromDevice = (callback: (addObj: AddressWithPos) => void): void => {
  navigator.geolocation.getCurrentPosition(async (pos) => {
    const { latitude, longitude } = pos.coords;

    const res = await getAddressFromPosition({
      latitude,
      longitude,
    });

    callback(res);
  });
};
