import React, { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import { Layer, Source, GeolocateControl } from 'react-map-gl';
import { CircularProgress } from '@mui/material';
import { STYLE } from './style';
import { createLayerStyle } from './layerStyle';
import * as turf from '@turf/turf';
import { CustomerPopup } from './CustomerPopup';
import { MapProps } from './MapProps';
import maplibregl from 'maplibre-gl';

import 'maplibre-gl/dist/maplibre-gl.css';
// const MapGL = React.lazy(() => import('react-map-gl'));
import MapGL from 'react-map-gl';

export const MapControl = ({
  viewport,
  markers,
  onClick,
  userIds,
  customers,
  isLoading,
  getMarkerColor,
  openCustomer,
}: MapProps) => {
  const _mapRef = useRef<any>(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState<number | undefined>(undefined);

  /**
   * Add pin marker for the layers
   */
  useEffect(() => {
    if (!_mapRef.current) {
      return;
    }
    const map = _mapRef.current.getMap();
    map.loadImage('/assets/map-pin.png', (error: any, image: any) => {
      // console.log('image', image);
      if (error) {
        throw error;
      }
      if (!map.hasImage('map-pin')) map.addImage('map-pin', image, { sdf: true });
    });
  });

  /**
   * click event
   */
  const click = useCallback(
    (ev: import('mapbox-gl').MapLayerMouseEvent) => {
      const map = _mapRef.current.getMap();
      const zoom = map.getZoom();
      // console.log('zoom', map.getZoom());
      const clickPoint: GeoJSON.Point = { type: 'Point', coordinates: [ev.lngLat.lng, ev.lngLat.lat] };
      if (markers === undefined) {
        return;
      }
      const f = turf.nearestPoint([ev.lngLat.lng, ev.lngLat.lat], markers);
      // console.log('Clicked near customer: ', f?.properties?.customerId);
      const d = turf.distance(clickPoint, f, { units: 'meters' });

      const mpp = (z: number) => 20000 / Math.pow(2, z);

      // console.log('distance in px', d / mpp(zoom));
      if (d / mpp(zoom) < 100) {
        setSelectedCustomerId(f?.properties?.customerId);
      } else {
        setSelectedCustomerId(undefined);
      }
    },
    [setSelectedCustomerId, markers, _mapRef]
  );

  // console.log(geojson);
  return (
    <Fragment>
      <MapGL
        id="Map"
        ref={_mapRef}
        initialViewState={viewport}
        mapLib={maplibregl}
        mapStyle={STYLE as any}
        // width="100%"
        // height="100%"
        style={{ width: '100%', height: '100%' }}
        maxZoom={18}
        onClick={onClick || click}
      >
        {isLoading && <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%' }} />}
        <Source id="customers" type="geojson" data={markers}>
          <Layer {...createLayerStyle(getMarkerColor)} />
        </Source>

        {selectedCustomerId && (
          <CustomerPopup
            customer={customers.find((c: any) => c.id === selectedCustomerId)}
            close={() => setSelectedCustomerId(undefined)}
            openCustomer={openCustomer}
          />
        )}

        <GeolocateControl trackUserLocation={true} position="top-left" />
      </MapGL>
    </Fragment>
  );
};
