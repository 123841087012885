import { faChevronCircleDown, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Box } from '@mui/system';
import { VIcon } from 'components/VIcon';
import { groupBy } from 'lodash';
import { Fragment } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip as ReTooltip, ResponsiveContainer, Cell } from 'recharts';
import { Mapping, Stats } from './MappingTypes';
import { buildMapFn } from './utils/utils';

export const StatsDisplay = ({
  stats,
  rawData,
  mappings,
  removeEntries,
  alterEntries,
}: {
  stats: Stats | null;
  rawData: any;
  mappings: Mapping[];
  removeEntries: (ids?: any[], indexes?: Number[]) => void;
  alterEntries: (fn: (obj: any) => any) => void;
}) => {
  if (!stats) {
    return null;
  }

  const d = [
    { count: stats.count, name: 'total count' },
    { count: stats.duplicates?.length, name: 'duplicates (excel)' },
    { count: stats.idMatching?.length, name: 'database matches' },
  ];

  const mapFn = buildMapFn(mappings || []);

  const idField = mappings.find((d) => d.target === 'id')?.source || '';
  const nameField = mappings.find((d) => d.target === 'name')?.source || '';
  const numberField = mappings.find((d) => d.target === 'number')?.source || '';
  const roadField = mappings.find((d) => d.target === 'road')?.source || '';
  const postcodeField = mappings.find((d) => d.target === 'postcode')?.source || '';
  const cityField = mappings.find((d) => d.target === 'city')?.source || '';

  return (
    <div
      style={{
        width: '100%',
        padding: '1em',
        marginBottom: '1em',
        border: '1px solid lightgray',
        borderRadius: '0.2em',
      }}
    >
      <div style={{ fontSize: '1.8em' }}>{`Statistic`}</div>

      <ResponsiveContainer width="100%" height={200}>
        <BarChart data={d} layout="vertical">
          <ReTooltip />
          <Bar dataKey="count" fill="#7ebc59">
            {d.map((entry, index) => {
              if (entry.name === 'duplicates (excel)') {
                return <Cell key={`cell-${index}`} fill={'red'} />;
              }
              if (entry.name === 'database matches') {
                return <Cell key={`cell-${index}`} fill={'#368cbf'} />;
              }
              return <Cell key={`cell-${index}`} fill={'#7ebc59'} />;
            })}
          </Bar>
          <XAxis type="number" />
          <YAxis type="category" dataKey="name" width={200} />
        </BarChart>
      </ResponsiveContainer>
      {idField && (
        <Fragment>
          <Accordion>
            <AccordionSummary expandIcon={<VIcon icon={faChevronCircleDown} />}>
              <Box fontSize={18} fontWeight="bold">
                Id Matches {`(${stats?.idMatching?.length})`}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>New Records</TableCell>
                    <TableCell>Database Records</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stats.idMatching?.map((m, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <div> {`${m.newRecord[idField]} | ${m.newRecord[nameField]}`}</div>
                        <div>
                          {`${m.newRecord[roadField]} ${m.newRecord[numberField]}, ${m.newRecord[postcodeField]} ${m.newRecord[cityField]}`}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div> {`${m.dbRecord.id} | ${m.dbRecord.name}`}</div>
                        <div>
                          {`${m.dbRecord['road']} ${m.dbRecord['number']}, ${m.dbRecord['postcode']} ${m.dbRecord['city']}`}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
          <ButtonGroup variant="outlined" fullWidth>
            <Button onClick={() => removeEntries(stats.idMatching?.map((d) => d.newRecord[idField]) || [])}>
              Remove matching entries
            </Button>
            <Button
              onClick={() =>
                removeEntries(
                  rawData
                    .filter((c: any) => !stats.idMatching?.map((d) => d.newRecord[idField]).includes(c[idField]))
                    .map((d: any) => d[idField]) || []
                )
              }
            >
              Remove new
            </Button>
            <Button onClick={() => alterEntries((d) => ({ ...d, [idField]: undefined }))}>
              Remove ids from entries
            </Button>
          </ButtonGroup>
        </Fragment>
      )}
      <div style={{ height: '2em' }} />
      {Boolean(stats?.duplicates?.length) && (
        <Fragment>
          <Accordion>
            <AccordionSummary expandIcon={<VIcon icon={faChevronCircleDown} />}>
              <Box
                style={{ marginTop: 'auto', marginBottom: 'auto', marginRight: '1em' }}
                fontSize={18}
                fontWeight="bold"
              >
                Duplicates within EXCEL {`(${stats?.duplicates?.length})`}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                {Object.entries(groupBy(stats.duplicates, (d) => d.original)).map(([k, v]) => {
                  return (
                    <li key={k}>
                      <b>{k}</b>:{mapFn(rawData[k]).replaceAll('|', ' ')}
                      <ul>
                        {v.map((d, i) => (
                          <li key={i}>
                            <b>{d.duplicate}</b>:{mapFn(rawData[d.duplicate]).replaceAll('|', ' ')}
                          </li>
                        ))}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </AccordionDetails>
          </Accordion>

          <Button
            fullWidth
            startIcon={<VIcon icon={faTimesCircle} />}
            variant="outlined"
            color="error"
            onClick={() => removeEntries(undefined, stats?.duplicates?.map((d) => d.duplicate) || [])}
          >
            Remove All Duplicates
          </Button>
        </Fragment>
      )}
    </div>
  );
};
