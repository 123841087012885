import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  LinearProgress,
  SvgIcon,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerDownloadButton } from '../../components/customerTree/CustomerDownloadButton';
import { CustomerFilterControl } from '../../components/customerFilter/CustomerFilterControl';
import { useCustomers } from '../../hooks/useCustomers';
import { groupBy, entries } from 'lodash';
import { TreeItemLabel } from '../../components/customerTree/TreeItemLabel';
import { useStyles } from '../../hooks/useStyles';
import {
  faAddressCard,
  faChevronDown,
  faMinusSquare,
  faPlus,
  faPlusSquare,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import { CustomerTreeItem } from '../../components/customerTree/CustomerTreeItem';
import { useUser } from '../../context/UserContext';
import { CustomerSelectionComponent } from '../../components/customerSelection/CustomerSelectionComponent';
import React from 'react';
import { VIcon } from 'components/VIcon';

import { useNavigate } from 'react-router-dom';
import YesNoDialog from 'components/dialog/YesNoDialog';
import { useMessages } from 'context/MessageContext';
import { useCreateCustomerMutation } from 'generated/graphql';

const CustomerTree = ({ sidebar }: { sidebar?: boolean }) => {
  const { id: user_id } = useUser();
  const [tree, setTree] = useState<string[]>([]);
  const { data, loading, error, refetch } = useCustomers();
  const { error: errMsg } = useMessages();
  const classes = useStyles();
  const { t } = useTranslation();
  const { hasRole } = useUser();
  const [createCustomer] = useCreateCustomerMutation();

  // useMutation<CreateCustomer, CreateCustomerVariables>(CreateCustomerMutation);
  const h = useNavigate();
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const matches = useMediaQuery('(max-width:500px)');

  if (error) console.error(error);

  const customers: any = data?.customers;
  return (
    <Fragment>
      <YesNoDialog
        isOpen={showCreateDialog}
        title={t('Create Customer')}
        text={t('CreateCustomerText')}
        yesAction={async () => {
          setShowCreateDialog(false);
          if (!user_id) {
            return;
          }
          try {
            const { data } = await createCustomer({ variables: { user_id } });
            refetch();
            h('/customer/' + data?.insert_customers_one?.id);
          } catch (error) {
            errMsg(t('Could not create customer {{msg}}', { msg: JSON.stringify(error) }));
          }
        }}
        noAction={() => setShowCreateDialog(false)}
        okColor="success"
      />
      <Typography variant="h4">{t('Customers')}</Typography>
      <Accordion style={{ marginBottom: '0px' }}>
        <AccordionSummary
          expandIcon={
            <SvgIcon>
              <FontAwesomeIcon icon={faChevronDown} />
            </SvgIcon>
          }
        >
          <Typography variant="h5">{t('Customer Filter')}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>
          <CustomerFilterControl />
        </AccordionDetails>
        {/* {JSON.stringify(getFilter(), null, '\t')} */}
      </Accordion>

      <CustomerSelectionComponent />
      {loading && <LinearProgress />}
      {!loading && customers && (
        <div>
          <div style={{ display: 'flex', flexWrap: 'wrap', padding: '0.5em 0' }}>
            <Button
              fullWidth={matches || sidebar}
              style={{
                marginRight: matches || sidebar ? undefined : '1em',
                marginBottom: matches || sidebar ? '0.5em' : undefined,
              }}
              variant="contained"
              startIcon={<VIcon icon={faPlus} />}
              onClick={() => setShowCreateDialog(true)}
            >
              {t('Add Customer')}
            </Button>
            {hasRole && hasRole('manager') && (
              <CustomerDownloadButton fullWidth={matches || sidebar} customers={customers} />
            )}
          </div>

          <TreeView
            defaultExpandIcon={
              <SvgIcon color="action">
                <FontAwesomeIcon icon={faPlusSquare} />
              </SvgIcon>
            }
            defaultCollapseIcon={
              <SvgIcon color="action">
                <FontAwesomeIcon icon={faMinusSquare} />
              </SvgIcon>
            }
            className={classes.root}
            style={{ flexDirection: 'column' }}
            expanded={tree}
            onNodeToggle={(e, nodes) => {
              setTree(nodes);
            }}
          >
            {entries(groupBy(customers || [], (d) => d?.user?.login || 'PUBLIC'))?.map(([login, customers]) => (
              <TreeItem
                key={login}
                nodeId={login}
                style={login === 'PUBLIC' ? { background: '#00000010' } : {}}
                label={<TreeItemLabel icon={faUser} label={`${login} (${(customers || []).length})`} />}
              >
                {entries(groupBy(customers || [], (d) => (d.postcode || '').substring(0, 2) + 'xxx') || {}).map(
                  ([postcode, customers]) => (
                    <TreeItem
                      key={login + postcode}
                      nodeId={login + postcode}
                      label={<TreeItemLabel icon={faAddressCard} label={`${postcode} (${(customers || []).length})`} />}
                    >
                      {(customers || []).map((customer) => (
                        <CustomerTreeItem
                          key={login + postcode + customer.id}
                          customer={customer}
                          selection={true} //selectedCustomers}
                        />
                      ))}
                    </TreeItem>
                  )
                )}
              </TreeItem>
            ))}
          </TreeView>
        </div>
      )}
    </Fragment>
  );
};

export default CustomerTree;
