import localforage from 'localforage';
import { setup } from 'axios-cache-adapter';

export const configureAxios = () => {
  const forageStore = localforage.createInstance({
    driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
    name: 'my-cache',
  });

  return setup({
    // Options passed to `axios.create()` method
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    },

    // `axios-cache-adapter` options
    cache: {
      readHeaders: false,
      maxAge: 15 * 60 * 1000,
      exclude: {
        query: false,
      },
      store: forageStore,
    },
  });
};


export default configureAxios();