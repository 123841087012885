import { useCustomers } from 'hooks/useCustomers';
import { useStyles } from 'hooks/useStyles';
import React, { useMemo, useRef, useState } from 'react';
import 'maplibre-gl/dist/maplibre-gl.css';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faFilter, faPalette } from '@fortawesome/free-solid-svg-icons';
import { CustomerFilterControl } from 'components/customerFilter/CustomerFilterControl';
import { differenceInCalendarDays, parseISO } from 'date-fns';
import { MarkerColorSettings } from './MarkerColorSettings';
import { useMarkerColor } from 'context/MapColorContext';
import { CustomerDataProvider } from 'pages/customer/Customer';
import { MapControl } from './MapControl';

const MapPage = () => {
  const { data, loading } = useCustomers();
  const [selectedCustomerId, setSelectedCustomerId] = useState(0);
  // const customers: GetCustomers_customers[] = data?.customers || [];
  const c = useStyles();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [openCustomerFilter, setOpenCustomerFilter] = useState<boolean>(false);
  const [openMarkerColor, setOpenMarkerColor] = useState<boolean>(false);

  const customers = data?.customers || [];

  const _mapContainer = useRef<any>(null);
  const { getMarkerColor } = useMarkerColor();

  const [viewport] = useState({
    latitude: 50.77664,
    longitude: 6.08342,
    zoom: 8,
  });

  /**
   * Build customers
   */
  const markers: GeoJSON.FeatureCollection<GeoJSON.Point> = useMemo(() => {
    const customers = data?.customers || [];
    return {
      type: 'FeatureCollection',
      features: customers
        .filter((d) => d.longitude !== null && d.latitude !== null)
        .map((c, i) => {
          const res: GeoJSON.Feature<GeoJSON.Point> = {
            type: 'Feature',
            geometry: { type: 'Point', coordinates: [c.longitude || 0, c.latitude || 0] },
            properties: {
              customerId: c.id,
              userId: c.user_id || 0,
              daysFromLastVisit: c.lastVisit.aggregate?.max?.date
                ? differenceInCalendarDays(new Date(), parseISO(c.lastVisit.aggregate?.max?.date))
                : null,
            },
          };
          return res;
        }),
    };
  }, [data]);

  return (
    <div ref={_mapContainer} className={c.map}>
      <Dialog open={Boolean(selectedCustomerId)} onClose={() => setSelectedCustomerId(0)}>
        <DialogContent>
          <CustomerDataProvider id={selectedCustomerId} />
        </DialogContent>
      </Dialog>
      {/* The style elements are set to for the react-select to work properly */}
      <Dialog
        open={openCustomerFilter}
        onClose={() => setOpenCustomerFilter(false)}
        PaperProps={{ style: { overflowY: 'visible' } }}
      >
        <DialogTitle> {t('Customer Filter')}</DialogTitle>
        <DialogContent style={{ overflowY: 'scroll' }}>
          <CustomerFilterControl />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openMarkerColor}
        onClose={() => setOpenMarkerColor(false)}
        PaperProps={{ style: { overflowY: 'visible' } }}
      >
        <DialogTitle> {t('Marker Color')}</DialogTitle>
        <DialogContent style={{ overflowY: 'visible' }}>
          <MarkerColorSettings />
        </DialogContent>
      </Dialog>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={(e) => {
            setAnchorEl(null);
            setOpenCustomerFilter(true);
          }}
        >
          <ListItemIcon>
            <SvgIcon>
              <FontAwesomeIcon icon={faFilter} />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText>{t('Customer Filter')}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            setAnchorEl(null);
            setOpenMarkerColor(true);
          }}
        >
          <ListItemIcon>
            <SvgIcon>
              <FontAwesomeIcon icon={faPalette} />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText>{t('Marker Color')}</ListItemText>
        </MenuItem>
      </Menu>
      <MapControl
        viewport={viewport}
        // setViewport={setViewport}
        markers={markers}
        customers={customers}
        isLoading={loading}
        getMarkerColor={getMarkerColor}
        openCustomer={(id) => setSelectedCustomerId(id)}
      />
      <div className={c.mapControlPanel}>
        <IconButton
          style={{ backgroundColor: 'white', border: '1px solid rgba(0,0,0,0.6)' }}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <FontAwesomeIcon icon={faBars} />
        </IconButton>
      </div>
    </div>
  );
};

export default MapPage;
