import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import axios from 'axios';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const VPDF = ({ src, style, width }: { src: string; style?: any; width?: number }) => {
  const [_src, setSrc] = useState<any>(undefined);

  useEffect(() => {
    const dit = async () => {
      if (!src) {
        return;
      }
      const { data } = await axios.get(src || '', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        responseType: 'blob', // Important
      });
      setSrc(URL.createObjectURL(new Blob([data]))); //URL.createObjectURL(blob);
    };
    dit();
  }, [setSrc, src]);
  return (
    <Document file={_src}>
      <Page pageNumber={1} width={width || 200} />
    </Document>
  );
};
