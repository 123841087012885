import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import chroma from 'chroma-js';
import { GetTagsPageQuery } from 'generated/graphql';
import { ArrayElement } from 'utils/ArrayElement';

const TagItem = ({ tag, select }: { tag: ArrayElement<GetTagsPageQuery['tags']>; select?: () => void }) => {
  const { t } = useTranslation();

  const color = chroma(tag.color || 'white').luminance() > 0.5 ? 'black' : 'white';

  return (
    <Fragment>
      <div
        style={{
          border: '1px solid lightgray',
          padding: '1em',
          borderRadius: '0.25em',
          backgroundColor: tag.color || 'white',
        }}
        onClick={() => select && select()}
      >
        <p
          style={{
            margin: '0',
            fontSize: '1em',
            color,
          }}
        >
          {tag.name}
        </p>
        <p style={{ margin: '0', fontSize: '0.75em', color }}>
          {t('tags.usedOn') + ' ' + tag.customers_tags_aggregate.aggregate?.count}
        </p>
      </div>
    </Fragment>
  );
};

export default TagItem;
