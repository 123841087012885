import { useState } from 'react';
import { Button, LinearProgress, TextField } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import { useMessages } from '../context/MessageContext';
import { useStyles } from '../hooks/useStyles';
import React from 'react';

export const Login = () => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const user = useUser();
  const { remove, success, error } = useMessages();
  const [messageId, setMessageId] = useState<number>(0);
  const c = useStyles();

  if (user.id) {
    return <Navigate to="/" />;
  }

  return (
    <div style={{ margin: 'auto', maxWidth: '300px' }}>
      <form
        onSubmit={async (e) => {
          if (remove !== undefined) {
            remove(messageId);
          }
          e.preventDefault();
          const res = user.authenticate !== undefined && (await user.authenticate(login, password));
          if (!res) {
            if (error !== undefined) {
              setMessageId(error('Login oder Passwort falsch...'));
            }
          } else {
            if (success !== undefined) {
              success('Successfuly logged in...');
            }
          }
        }}
      >
        <TextField
          style={{ marginTop: '10px' }}
          label="Login"
          value={login}
          onChange={(e) => setLogin(e.target.value)}
          className={c.loginElemement}
        />
        <TextField
          style={{ marginTop: '10px' }}
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={c.loginElemement}
        />
        <Button
          style={{ marginTop: '10px' }}
          className={c.loginElemement}
          variant="contained"
          color="primary"
          type="submit"
        >
          Login...
        </Button>
      </form>
      {user.loading && <LinearProgress className={c.loginElemement} />}
    </div>
  );
};
