import { Grid, IconButton, LinearProgress, SvgIcon, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { faCrosshairs, faSearch, faUndo } from '@fortawesome/free-solid-svg-icons';
import { MapControl } from 'pages/mapPage/MapControl';
import { Fragment, useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAdressFromDevice } from '../../utils/getAdressFromDevice';
import { getCoordinatesFromAddress } from '../../utils/getCoordinatesFromAddress';
import { getAddressFromPosition } from '../../utils/getAddressFromPosition';
import { LatLng } from '../../types/LatLng';
import { AddressObject } from '../../types/AddressObject';
import { SearchAddressDialog } from './SearchAddressDialog';
import { FormikTextField } from './FormikTextField';
import YesNoDialog from 'components/dialog/YesNoDialog';
import { AddressWithPos } from 'types/AddressWithPos';
import { NavigateTo } from './NavigateTo';
import { useMap } from 'react-map-gl';
import { GetCustomerQuery } from 'generated/graphql';

export const AddressControl = ({
  formik,
  customer,
}: {
  formik: any;
  customer: NonNullable<GetCustomerQuery['customers_by_pk']>;
}) => {
  // const { values, setValues } = useFormikContext<GetCustomer_customers_by_pk>();

  const { Map } = useMap();
  const [newAddress, setNewAddress] = useState<AddressWithPos | null>(null);
  const values = formik.values;
  const [loading, setLoading] = useState(false);

  const [searchAddressOpen, setSearchAddressOpen] = useState(false);
  const { t } = useTranslation();

  const [viewport, setViewport] = useState({
    latitude: values.latitude || 50.77664,
    longitude: values.longitude || 6.08342,
    zoom: 12,
  });

  const marker: GeoJSON.FeatureCollection<GeoJSON.Point> = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: { type: 'Point', coordinates: [values.longitude || 0, values.latitude || 0] },
        properties: { color: 'blue' },
      },
    ],
  };

  const mapClick = useCallback(
    async (ev: import('mapbox-gl').MapLayerMouseEvent) => {
      setLoading(true);
      const arg = {
        longitude: ev.lngLat.lng,
        latitude: ev.lngLat.lat,
      };
      const res = await getAddressFromPosition(arg);

      setLoading(false);
      setNewAddress(res);
    },
    [setLoading, setNewAddress]
  );
  return (
    <div>
      <YesNoDialog
        isOpen={Boolean(newAddress)}
        title={t('Address Change')}
        text={
          <Fragment>
            {t(`Do you want to change the address to`)}:
            <br />
            <br />
            <Typography component={'span'} color={'black'}>
              {`${newAddress?.road} ${newAddress?.number}, ${newAddress?.postcode} ${newAddress?.city}?`}
            </Typography>
          </Fragment>
        }
        yesAction={() => {
          if (newAddress && newAddress.latitude != null && newAddress.longitude != null) {
            setViewport({ ...viewport, latitude: newAddress.latitude, longitude: newAddress.longitude });
            Map?.flyTo({ center: [newAddress?.longitude || 0, newAddress?.latitude || 0] });
          }
          formik.setValues({ ...values, ...newAddress });
          setNewAddress(null);
        }}
        noAction={() => setNewAddress(null)}
      />
      <SearchAddressDialog
        isOpen={searchAddressOpen}
        close={() => setSearchAddressOpen(false)}
        onSelectAddress={(add) => {
          setSearchAddressOpen(false);
          console.log('add', add);
          if (add.longitude !== null && add.latitude !== null) {
            Map?.flyTo({ center: [add.longitude || 0, add.latitude || 0] });
            setViewport({ ...viewport, latitude: add.latitude, longitude: add.longitude });
          }
          formik.setValues({ ...values, ...add });
        }}
      />
      <div style={{ height: '300px' }}>
        <MapControl markers={marker} viewport={viewport} onClick={mapClick} />
        {loading && <LinearProgress />}
      </div>
      <Grid container>
        <Grid item xs={9}>
          <FormikTextField formik={formik} label={t('Road')} name="road" />
        </Grid>
        <Grid item xs={3}>
          <FormikTextField formik={formik} label={t('Number')} name="number" />
        </Grid>
        <Grid item xs={4}>
          <FormikTextField formik={formik} label={t('Postcode')} name="postcode" />
        </Grid>
        <Grid item xs={8}>
          <FormikTextField formik={formik} label={t('City')} name="city" />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={2}>
          <Tooltip title={t('Refresh') + ''}>
            <IconButton
              onClick={async () => {
                const ll = await getCoordinatesFromAddress(values);
                // console.log(ll);
                if (ll !== undefined) {
                  const { latitude, longitude } = ll;
                  Map?.flyTo({ center: [longitude || 0, latitude || 0] });
                  if (latitude !== null && longitude !== null) {
                    setViewport({ ...viewport, latitude, longitude });
                    formik.setValues({ ...values, ...ll });
                  }
                }
              }}
            >
              <SvgIcon>
                <FontAwesomeIcon icon={faUndo} />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={2}>
          <Tooltip title={t('Search') + ''}>
            <IconButton onClick={() => setSearchAddressOpen(true)}>
              <SvgIcon>
                <FontAwesomeIcon icon={faSearch} />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={2}>
          <Tooltip title={t('Take current position') + ''}>
            <IconButton
              onClick={() =>
                getAdressFromDevice((res: LatLng & AddressObject) => {
                  setNewAddress(res);
                })
              }
            >
              <SvgIcon>
                <FontAwesomeIcon icon={faCrosshairs} />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={2}>
          <NavigateTo latitude={customer.latitude} longitude={customer.longitude} />
        </Grid>
      </Grid>
    </div>
  );
};
