import { useUser } from 'context/UserContext';

/**
 * shows its children if the user Id matches with the provided one
 * @param param0
 */
export const ShowIfMine = ({ objectUserId, children }: { objectUserId: number | null | undefined; children: any }) => {
  const { id: userId } = useUser();
  if (Number(objectUserId) === Number(userId)) {
    return children;
  }
  return null;
};
