import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { VIcon } from 'components/VIcon';
import Select from 'react-select';
import { Mapping, MappingType } from './MappingTypes';

export const MappingDisplay = ({
  mappings,
  allKeys,
  changeMapping,
}: {
  mappings: Mapping[];
  allKeys: string[];
  changeMapping: any;
}) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Target</TableCell>
          <TableCell> </TableCell>
          <TableCell>Source</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell colSpan={3}>Simple</TableCell>
        </TableRow>
        {mappings
          .filter((d) => d.type === MappingType.Simple)
          .map((m) => {
            return (
              <TableRow key={m.target}>
                <TableCell>{m.target}</TableCell>
                <TableCell>
                  <VIcon size="xs" icon={faArrowLeft} />
                </TableCell>
                <TableCell>
                  <div>
                    <Select
                      isClearable
                      value={{ value: m.source, label: m.source }}
                      options={allKeys.map((d) => ({ value: d, label: d }))}
                      onChange={(e) =>
                        changeMapping(
                          mappings.map((d) => {
                            if (m.target === d.target) {
                              return { ...d, source: e?.value };
                            }
                            return d;
                          })
                        )
                      }
                    />
                  </div>
                </TableCell>
              </TableRow>
            );
          })}

        <TableRow>
          <TableCell colSpan={3}>Foreign</TableCell>
        </TableRow>
        {mappings
          .filter((d) => d.type === MappingType.Foreign)
          .map((m) => {
            return (
              <TableRow key={m.target}>
                <TableCell>{m.target}</TableCell>
                <TableCell>
                  <VIcon size="xs" icon={faArrowLeft} />
                </TableCell>
                <TableCell>
                  <div>
                    <Select
                      isClearable
                      value={{ value: m.source, label: m.source }}
                      options={allKeys.map((d) => ({ value: d, label: d }))}
                      onChange={(e) =>
                        changeMapping(
                          mappings.map((d) => {
                            if (m.target === d.target) {
                              return { ...d, source: e?.value };
                            }
                            return d;
                          })
                        )
                      }
                    />
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};
