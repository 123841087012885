import { Dialog, DialogContent, DialogTitle, Grid, LinearProgress } from '@mui/material';
import { TitleDisplay } from 'pages/customer/TitleDisplay';
import { VisiterDropzone } from 'components/dropzone/VisiterDropzone';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentListItem } from './DocumentListItem';
import { DocumentEdit } from './DocumentEdit';
import { useGetDocumentsQuery } from 'generated/graphql';

const DocumentsPage = () => {
  const { t } = useTranslation();
  const [selectedDocumentId, setSelectedDocumentId] = useState(0);
  const { data, loading, refetch } = useGetDocumentsQuery();
  const selectedDocument = data?.document.find((d) => d.id === selectedDocumentId);

  return (
    <Fragment>
      {selectedDocument && (
        <Dialog maxWidth="md" fullWidth open={Boolean(selectedDocumentId)} onClose={() => setSelectedDocumentId(0)}>
          <DialogTitle>{t('Edit Document')}</DialogTitle>
          <DialogContent>
            <DocumentEdit document={selectedDocument} refetch={refetch} />
          </DialogContent>
        </Dialog>
      )}
      {loading && <LinearProgress />}
      <TitleDisplay title={t('Documents')} />
      <VisiterDropzone createDocument={true} fileFinished={refetch} />
      <Grid container spacing={1}>
        {data?.document.map((doc, i) => (
          <DocumentListItem key={doc.id} document={doc} selectDocument={() => setSelectedDocumentId(doc.id)} />
        ))}
      </Grid>
    </Fragment>
  );
};

export default DocumentsPage;
