import { Dialog, DialogTitle, DialogContent, Typography, Button } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikTextField } from '../customer/FormikTextField';
import * as Yup from 'yup';
import Axios from 'axios';
import { useMessages } from 'context/MessageContext';

export const ChangePasswordDialog = ({ userId, onClose }: { userId: number; onClose: () => void }) => {
  const { t } = useTranslation();
  const { success, error } = useMessages();
  const PasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, t('passwords must contain more than 8 characters'))
      .test('password', t('please change'), (a) => a !== 'password')
      .required(t('passwords is required')),
    confirm: Yup.string().oneOf([Yup.ref('password'), null], t('passwords did not match')),
  });

  const f = useFormik({
    initialValues: { password: 'password', confirm: 'password' },
    validationSchema: PasswordSchema,
    onSubmit: async (vals) => {
      try {
        await Axios.post(
          '/api/change-password',
          {
            userId,
            ...vals,
          },
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
        success(t('Password successfully changed'));
        onClose();
      } catch (e) {
        error(t('Error while changing password {{msg}}', { msg: JSON.stringify(e) }));
      }
    },
  });
  return (
    <Dialog open={Boolean(userId)}>
      <DialogTitle>{t('Change Password')} </DialogTitle>
      <DialogContent>
        <Typography variant="overline">{`id: ${userId}`}</Typography>
        <form onSubmit={f.handleSubmit}>
          <FormikTextField formik={f} name="password" label={t('password')} extra={{ type: 'password' }} />
          <FormikTextField formik={f} name="confirm" label={t('password confirm')} extra={{ type: 'password' }} />
          <div style={{ display: 'flex' }}>
            <Button style={{ marginTop: '10px' }} variant="contained" type="reset" onClick={onClose}>
              {t('Cancel')}
            </Button>
            <div style={{ flex: '1' }} />
            <Button style={{ marginTop: '10px' }} variant="contained" type="submit">
              {t('Save')}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
