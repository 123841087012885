import { useNavigate } from 'react-router-dom';
import { useUser } from 'context/UserContext';
import { useCallback, useEffect } from 'react';

export const ProtectedRoute = ({
  redirect,
  children,
  allowedRoles,
}: {
  redirect: string;
  children: any;
  allowedRoles?: string[];
}) => {
  const { loading } = useUser();
  const u = useUser();
  const h = useNavigate();

  const protectCheck = useCallback(() => {
    // needs to be replaced
    // if (allowedRoles) {
    //   return allowedRoles.indexOf(u.role || '') > -1;
    // }
    return u.id;
  }, [u]);

  useEffect(() => {
    if (!loading && !protectCheck()) {
      h(redirect);
    }
  }, [protectCheck, h, redirect, loading]);

  if (!protectCheck()) {
    return null;
  }

  return children;
};
