import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const WizardNavs = ({
  activeStep,
  maxStep,
  onNext,
  onPrevious,
}: {
  activeStep: number;
  maxStep: number;
  onNext: () => void;
  onPrevious: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '0.5em' }}>
      <div style={{ flex: 1 }} />
      {activeStep > 0 && (
        <Button variant="outlined" onClick={onPrevious} sx={{ mr: 1 }}>
          {t('previous')}
        </Button>
      )}
      {activeStep < maxStep && (
        <Button variant="contained" onClick={onNext} sx={{ mr: 1 }}>
          {t('next')}
        </Button>
      )}
    </div>
  );
};
