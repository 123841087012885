import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { SvgIcon, Button } from '@mui/material';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import ShowOnPermission from 'controls/ShowOnPermission';
// import moment from 'moment';
import _ from 'lodash';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { GetCustomersQuery } from 'generated/graphql';
export const CustomerDownloadButton = ({
  customers,
  fullWidth,
}: {
  customers: GetCustomersQuery['customers'];
  fullWidth?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    // <ShowOnPermission permission="AccessAllCustomers">
    <Button
    id={`DownloadExcel`}
      fullWidth={fullWidth}
      variant="outlined"
      style={{ color: 'rgba(0,0,0,0.54)' }}
      startIcon={
        <SvgIcon>
          <FontAwesomeIcon icon={faFileDownload} />
        </SvgIcon>
      }
      onClick={async () => {
        const XLSX = await import('xlsx');
        const props = [
          { name: 'id', prop: 'id' },
          { name: 'name', prop: 'name' },
          { name: 'email', prop: 'email' },
          { name: 'telephone', prop: 'telephone' },
          { name: 'road', prop: 'road' },
          { name: 'number', prop: 'number' },
          { name: 'postcode', prop: 'postcode' },
          { name: 'city', prop: 'city' },
          { name: 'group', prop: 'group.name' },
          { name: 'login', prop: 'user.login' },
          { name: 'latitude', prop: 'latitude' },
          { name: 'longitude', prop: 'longitude' },
          { name: 'state', prop: 'state.state' },
        ];
        // console.log(properties);
        const suffix = format(new Date(), 'yyyy-MM-dd_HH_mm');
        var filename = `customers_${suffix}.xlsx`;

        var data = [
          [...props.map((d) => d.name), 'tags'],
          ...customers.map((customer) => [
            ...props.map((prop) => _.get(customer, prop.prop)),
            customer.tags.map((d) => (d !== null ? d.tag?.name : '')).join(', '),
          ]),
        ];
        var ws_name = 'Customers';
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, ws, ws_name);
        XLSX.writeFile(wb, filename);
      }}
    >
      {t('Download')}
    </Button>
    // </ShowOnPermission>
  );
};
