import { SvgIcon, Typography, Divider, Button, ButtonGroup, Tooltip } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPeopleCarry, faTimes } from '@fortawesome/free-solid-svg-icons';
import YesNoDialog from 'components/dialog/YesNoDialog';
import { useTranslation } from 'react-i18next';
import { useCustomerSelection } from 'context/CustomerSelectionContext';
import { useMessages } from 'context/MessageContext';
import { useCustomerFilter } from 'context/CustomerFilterContext';
import { MoveCustomerToUserDialog } from 'components/dialog/MoveCustomerToUserDialog';
import { GetCustomersDocument, useDeleteCustomersMutation } from 'generated/graphql';

export const CustomerSelectionComponent = () => {
  const [confirmMoveUser, setConfirmMoveUser] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const { t } = useTranslation();

  const { count, clear, selectedCustomerIds } = useCustomerSelection();
  const { error, success } = useMessages();

  const { getFilter } = useCustomerFilter();

  const [deleteCustomers] = useDeleteCustomersMutation({
    refetchQueries: [{ query: GetCustomersDocument, variables: { customerFilter: getFilter() } }],
  });

  if (count !== undefined && count() === 0) {
    return null;
  }

  return (
    <Fragment>
      <YesNoDialog
        title={t('Delete Customers')}
        isOpen={confirmDelete}
        noAction={() => setConfirmDelete(false)}
        yesAction={() => {
          try {
            deleteCustomers({ variables: { ids: selectedCustomerIds } });
            setConfirmDelete(false);
            clear();
            success(t('Successfuly deleted customers.'));
          } catch (e) {
            error(t('Could not delete customers - {{msg}}', { msg: JSON.stringify(e) }));
          }
        }}
        text={t('You are about to delete {{number}} Customers - Are you sure?', {
          number: count !== undefined && count(),
        })}
      />
      {confirmMoveUser && (
        <MoveCustomerToUserDialog open={confirmMoveUser} closeAction={() => setConfirmMoveUser(false)} />
      )}

      <Divider />
      <Typography>
        {t('Selection')} : {count !== undefined && count()}
      </Typography>

      <ButtonGroup fullWidth>
        <Tooltip title={t('Clear Selection') + ''}>
          <Button
            id={`ClearCustomerSelection`}
            style={{ color: 'rgba(0, 0, 0, 0.54)', overflow: 'hidden' }}
            onClick={() => clear !== undefined && clear()}
            startIcon={
              <SvgIcon>
                <FontAwesomeIcon icon={faTimes} />
              </SvgIcon>
            }
          >
            {/* <Box display={{ xs: 'none', sm: 'block' }}>{t('Clear Selection')}</Box> */}
          </Button>
        </Tooltip>
        <Tooltip title={t('Delete') + ''}>
          <Button
            id={`DeleteCustomers`}
            style={{ color: 'rgba(0, 0, 0, 0.54)', overflow: 'hidden' }}
            onClick={() => setConfirmDelete(true)}
            startIcon={
              <SvgIcon>
                <FontAwesomeIcon icon={faTrash} />
              </SvgIcon>
            }
          >
            {/* <Box display={{ xs: 'none', sm: 'block' }}>{t('Delete')}</Box> */}
          </Button>
        </Tooltip>
        <Tooltip title={t('New Owner') + ''}>
          <Button
            id={`MoveCustomer`}
            style={{ color: 'rgba(0, 0, 0, 0.54)', overflow: 'hidden' }}
            onClick={() => setConfirmMoveUser(true)}
            startIcon={
              <SvgIcon>
                <FontAwesomeIcon icon={faPeopleCarry} />
              </SvgIcon>
            }
          >
            {/* <Box display={{ xs: 'none', sm: 'block' }}>{t('New Owner')}</Box> */}
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Fragment>
  );
};
