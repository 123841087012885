import { Button, LinearProgress, Typography, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { useUser } from 'context/UserContext';

import { UpdateContactMutation, useGetContactQuery, useUpdateContactMutation } from 'generated/graphql';
import { pick } from 'lodash';
import { FormikTextField } from './FormikTextField';
import { FormikSelect } from './FormikSelect';
import { FormikDatePicker } from './transferOrders/FormikDatePicker';
import { FormikCheckbox } from './FormikCheckbox';
import { useMessages } from 'context/MessageContext';

export const Contact = ({
  contactId,
  onSave,
  onCancel,
}: {
  contactId: number;
  onSave: (contact: UpdateContactMutation['update_contacts_by_pk']) => void;
  onCancel: () => void;
}) => {
  const { id: userId } = useUser();
  const { error, success } = useMessages();
  // const canCreate = usePermission('EditPositions');
  const { t } = useTranslation();

  const {
    loading,
    error: queryError,
    data,
  } = useGetContactQuery({
    variables: { id: contactId },
  });

  // const [createPosition] = useMutation<CreatePosition, CreatePositionVariables>(CreatePositionMutation, {
  //   refetchQueries: ['GetContact'],
  // });

  const [updateContact] = useUpdateContactMutation();
  // const [createPosition] = useMutation(CREATE_POSITION, {
  //   refetchQueries: ['GetContact'],
  // });

  const editContact = data?.contacts_by_pk;
  const f = useFormik({
    initialValues: {
      ...pick(
        editContact,
        'id',
        'firstname',
        'name',
        'telephone',
        'birthday',
        'email',
        'position_id',
        'is_delivery_contact',
        'is_invoice_contact',
        'number',
        'road',
        'postcode',
        'city',
        'country_code'
      ),
      country_code: 'de',
    },
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        console.log('va', values);
        const { ...plainContact } = values;
        console.log(plainContact);
        let r: UpdateContactMutation | null | undefined = null;
        if (!values.id && userId) {
        } else {
          const { data } = await updateContact({
            variables: { id: plainContact.id || 0, contact: { ...plainContact, user_id: userId } },
          });
          r = data;
        }

        const contact = r?.update_contacts_by_pk;

        setSubmitting(false);
        if (contact) {
          onSave(contact);
        }
        success(t('saved'));
      } catch (e) {
        error(t('Error while saving {{msg}}', { msg: JSON.stringify(e) }));
      }
    },
  });

  if (loading === undefined) {
    return <LinearProgress />;
  }
  if (queryError) {
    console.error(queryError);
    return null;
  }

  const positions = data?.positions;

  if (!editContact) {
    return null;
  }

  return (
    <div style={{ position: 'relative' }}>
      <form onSubmit={f.handleSubmit}>
        <Typography variant="h4">{t('Edit Contact')}</Typography>
        <FormikTextField formik={f} label={t('Firstname')} name="firstname" />
        <FormikTextField formik={f} label={t('Name')} name="name" />
        <FormikTextField
          formik={f}
          label={t('Telephone')}
          name="telephone"
          icon={faPhone}
          href={`tel:${f.values.telephone}`}
        />
        <FormikTextField
          formik={f}
          label={t('contact.email')}
          name="email"
          icon={faEnvelope}
          href={`mailto:${f.values.email}`}
        />

        {positions && (
          <FormikSelect
            formik={f}
            label={t('Position')}
            //canCreate={canCreate}
            name="position_id"
            options={positions || []}
            display={(d) => d.position}
            // createOption={async (name: string) => {
            //   const { data } = await createPosition({
            //     variables: { position: name },
            //   });
            //   const { id } = data?.insert_positions_one || {};
            //   return id;
            // }}
          />
        )}

        {/* 
        <FormikBirthdayPicker name="birthday" isSubmitting={isSubmitting} />
*/}

        <FormikDatePicker formik={f} name="birthday" label={t('birthday')} />

        <FormikCheckbox formik={f} name="is_delivery_contact" label={t('contact.delivery_contact')} />
        <FormikCheckbox formik={f} name="is_invoice_contact" label={t('contact.invoice_contact')} />

        <Grid container>
          <Grid item xs={9}>
            <FormikTextField formik={f} label={t('Road')} name="road" />
          </Grid>
          <Grid item xs={3}>
            <FormikTextField formik={f} label={t('Number')} name="number" />
          </Grid>
          <Grid item xs={4}>
            <FormikTextField formik={f} label={t('Postcode')} name="postcode" />
          </Grid>
          <Grid item xs={8}>
            <FormikTextField formik={f} label={t('City')} name="city" />
          </Grid>
          <Grid item xs={8}>
            <FormikTextField formik={f} label={t('country_code')} name="country_code" extra={{ disabled: true }} />
          </Grid>
        </Grid>

        <div
          style={{
            marginTop: '17px',
            display: 'flex',
            paddingBottom: '11px',
          }}
        >
          <Button variant="outlined" onClick={onCancel}>
            {t('Cancel')}
          </Button>
          <div style={{ flex: 1 }} />
          <Button variant="outlined" type="submit" color="primary">
            {t('Save')}
          </Button>
        </div>
      </form>
    </div>
  );
};
