import { LinearProgress } from '@mui/material';
import { useGetTagsPageQuery } from 'generated/graphql';
import React from 'react';
import TagsDisplay from './TagsDisplay';

const TagsPage = () => {
  const { data, loading } = useGetTagsPageQuery();

  if (loading) {
    return <LinearProgress />;
  }

  return <TagsDisplay tags={data?.tags || []} />;
};

export default TagsPage;
