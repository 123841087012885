import { faLock } from '@fortawesome/free-solid-svg-icons';
import { Button, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { useUser } from 'context/UserContext';
import { ChangePasswordDialog } from 'pages/settings/ChangePasswordDialog';
import { VIcon } from 'components/VIcon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { pick } from 'lodash';
import { FormikTextField } from './customer/FormikTextField';
import axios from 'axios';
import { useMessages } from 'context/MessageContext';
import { useGetUserQuery, useUpdateUserMutation } from 'generated/graphql';

export const Profile = () => {
  const { id, role, login } = useUser();
  const { t } = useTranslation();
  const [userId, setUserId] = useState(0);
  const { data, refetch } = useGetUserQuery({
    variables: { id: id || 0 },
  });
  const { error, success } = useMessages();
  const [updateUser] = useUpdateUserMutation();

  const u = data?.users_by_pk;
  const f = useFormik({
    initialValues: pick(u || {}, ['email', 'telephone', 'firstname', 'lastname']),

    onSubmit: async (vals: any) => {
      // console.log('vals', vals);

      const { email, ...rest } = vals;
      try {
        if (email && email.trim() !== '' && u?.email !== vals.email) {
          await axios.post(
            '/api/change-mail',
            { userId: id, newMail: vals.email },
            {
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            }
          );
          await refetch();
        }
        await updateUser({ variables: { id: id || 0, obj: rest } });

        success(t('saved'));
      } catch (e) {
        error(t('Error while saving {{msg}}'), { msg: JSON.stringify(e) } as any);
      }
    },
    enableReinitialize: true,
  });

  // console.log('data', data);

  return (
    <form onSubmit={f.handleSubmit}>
      <ChangePasswordDialog userId={userId} onClose={() => setUserId(0)} />
      Profile
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>{id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>login</TableCell>
              <TableCell>{login}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('firstname')}</TableCell>
              <TableCell>
                <FormikTextField formik={f} name="firstname" label={t('firstname')} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('lastname')}</TableCell>
              <TableCell>
                <FormikTextField formik={f} name="lastname" label={t('lastname')} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Role</TableCell>
              <TableCell>{role}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('profile.eMail')}</TableCell>
              <TableCell>
                <FormikTextField formik={f} name="email" label={t('profile.eMail')} />
                {u?.email_new && (
                  <div style={{ margin: '1em 0' }}>
                    <Typography>{t('userEdit.newEmailToBeConfirmed', { email: u.email_new })}</Typography>
                  </div>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('telephone')}</TableCell>
              <TableCell>
                <FormikTextField formik={f} name="telephone" label={t('telephone')} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Password</TableCell>
              <TableCell>
                <Button variant="outlined" startIcon={<VIcon icon={faLock} />} onClick={() => setUserId(id || 0)}>
                  {t('Change Password')}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Button type="submit" variant="contained">
        {t('Save')}
      </Button>
    </form>
  );
};
